import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import {
  deleteTvsVod,
  getTvsVod,
  getTvsVodCategory,
  getTvsVodDetail, postTvsVod, putTvsVod,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";
import {STATIC_FILE_URL} from "../../config";

const CMS = () => {
  const [vodRegisterModalShow, setVodRegisterModalShow] = useState(false);
  const [vodEditModalShow, setVodEditModalShow] = useState(false);

  const [registerFormData, setRegisterFormData] = useState({
    category: "",
    resolution: "",
    file: "",
    thumbnail: "",
    title: "",
    content: "",
  });

  const [editFormData, setEditFormData] = useState({
    category: {
      id: "",
      name: "",
    },
    resolution: "",
    video: "",
    thumbnail: "",
    title: "",
    description: "",
  });

  const [searchParam, setSearchParam] = useState({
    category: "",
    search: "",
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {key: "id", title: "번호"},
    {key: "category.name", title: "카테고리"},
    {key: "resolution", title: "해상도"},
    {key: "title", title: "콘텐츠 명"},
    {key: "thumbnail", title: "썸네일 유무"},
    {key: "edit", title: "수정하기"},
  ];

  const [vodCategoryList, setVodCategoryList] = useState([]);
  const getCategoryList = () => {
    getTvsVodCategory()
        .then((res) => {
          console.log("getTvsVodCategory res : ", res.data);
          setVodCategoryList(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const getVODList = () => {
    getTvsVod(searchParam.category, searchParam.search)
        .then((res) => {
          console.log("getTvsVod res : ", res.data);
          setTableData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    getCategoryList();
    getVODList();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(searchParam);
    getVODList();
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setRegisterFormData({
        ...registerFormData,
        thumbnail: URL.createObjectURL(file),
        file: file,
      });

      setEditFormData({
        ...editFormData,
        thumbnail: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  const registerVod = () => {
    const postFormData = new FormData();
    postFormData.append("title", registerFormData.title);
    postFormData.append("description", registerFormData.content);
    postFormData.append("resolution", registerFormData.resolution);
    // postFormData.append("video", registerFormData.file);
    postFormData.append("video", "");
    postFormData.append("thumbnail", registerFormData.file);
    postFormData.append("category", registerFormData.category);

    postTvsVod(postFormData)
        .then((res) => {
          console.log("postTvsVod res : ", res.data);
          setVodRegisterModalShow(false);
          setRegisterFormData({
            category: "",
            resolution: "",
            file: "",
            thumbnail: "",
            title: "",
            content: "",
          });
          getVODList();
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const renderVodRegister = () => {
    return (
        <>
          <Row className={"row-gap-3"}>
            <Col xs={12}>
              <Form>
                <Row>
                  <Col xs={12} className="d-flex flex-column gap-3">
                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        카테고리
                      </span>
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                            aria-label="Default select example"
                            className={"h-100"}
                            value={registerFormData.category}
                            onChange={(e) =>
                                setRegisterFormData({
                                  ...registerFormData,
                                  category: e.target.value,
                                })
                            }
                        >
                          <option value={""}>영상 카테고리</option>
                          {vodCategoryList.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        해상도
                      </span>
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                            aria-label="Default select example"
                            className={"h-100"}
                            value={registerFormData.resolution}
                            onChange={(e) =>
                                setRegisterFormData({
                                  ...registerFormData,
                                  resolution: e.target.value,
                                })
                            }
                        >
                          <option value={""}>해상도</option>
                          <option value={"480p"}>480p</option>
                          <option value={"720p"}>720p</option>
                          <option value={"1080p"}>1080p</option>
                          <option value={"4K"}>4K</option>
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        파일 업로드
                      </span>
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                            aria-label="Default select example"
                            className={"h-100"}
                            value={registerFormData.file}
                            onChange={(e) =>
                                setRegisterFormData({
                                  ...registerFormData,
                                  file: e.target.value,
                                })
                            }
                        >
                          <option value={""}>VOD 파일 업로드</option>
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        썸네일 업로드
                      </span>
                      </Col>
                      <Col xs={5}>
                        <Form.Control
                            type={"file"}
                            accept={"image/*"}
                            className={"h-100"}
                            // value={registerFormData.thumbnail}
                            onChange={handleThumbnailChange}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 p-2 rounded-3 text-center fs-5"
                          }
                      >
                        &nbsp;
                      </span>
                      </Col>
                      <Col xs={2}>
                        {registerFormData.thumbnail && (
                            <img
                                src={registerFormData.thumbnail}
                                alt={"thumbnail"}
                                className={"img-fluid w-100 mt-3"}
                            />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        타이틀
                      </span>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                            type={"text"}
                            placeholder={"타이틀 입력"}
                            className={"h-100"}
                            value={registerFormData.title}
                            onChange={(e) =>
                                setRegisterFormData({
                                  ...registerFormData,
                                  title: e.target.value,
                                })
                            }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        내용
                      </span>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                            type={"text"}
                            placeholder={"내용 입력"}
                            className={"h-100"}
                            value={registerFormData.content}
                            onChange={(e) =>
                                setRegisterFormData({
                                  ...registerFormData,
                                  content: e.target.value,
                                })
                            }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col
                xs={12}
                className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                  type={"button"}
                  variant={"success"}
                  className={"rounded-5 px-5 fw-bold fs-5 text-white btn-send"}
                  // onClick={() => alert("미리보기")}
                  onClick={registerVod}
              >
                저장
              </Button>
              <Button
                  type={"button"}
                  variant={"secondary"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() =>
                      setRegisterFormData({
                        category: "",
                        resolution: "",
                        file: "",
                        thumbnail: "",
                        title: "",
                        content: "",
                      })
                  }
              >
                초기화
              </Button>
            </Col>
          </Row>
        </>
    );
  };

  const deleteVod = () => {
    console.log("deleteVod id : ", editFormData.id);
    deleteTvsVod(editFormData.id)
        .then((res) => {
          console.log("deleteTvsVod res : ", res.data);
          setVodEditModalShow(false);
          getVODList();
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const editVod = () => {
    const putFormData = new FormData();
    putFormData.append("title", editFormData.title);
    putFormData.append("description", editFormData.description);
    putFormData.append("resolution", editFormData.resolution);
    putFormData.append("video", editFormData.video);
    // putFormData.append("thumbnail", editFormData.thumbnail);
    putFormData.append("category", editFormData.category.id); // Ensure category is set to its ID

    putTvsVod(editFormData.id, putFormData)
        .then((res) => {
          console.log("putTvsVod res : ", res.data);
          setVodEditModalShow(false);
          setEditFormData({
            category: {id: "", name: ""},
            resolution: "",
            video: "",
            thumbnail: "",
            title: "",
            description: "",
          });
          getVODList();
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const renderVodEdit = () => {
    return (
        <>
          <Row className={"row-gap-3"}>
            <Col xs={12}>
              <Form>
                <Row>
                  <Col xs={12} className={"d-flex flex-column gap-3"}>
                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        카테고리
                      </span>
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                            aria-label="Default select example"
                            className={"h-100"}
                            value={editFormData.category.id}
                            onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  category: e.target.value,
                                })
                            }
                        >
                          <option value={""}>영상 카테고리</option>
                          {vodCategoryList.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        해상도
                      </span>
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                            aria-label="Default select example"
                            className={"h-100"}
                            value={editFormData.resolution}
                            onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  resolution: e.target.value,
                                })
                            }
                        >
                          <option value={""}>해상도</option>
                          <option value={"480p"}>480p</option>
                          <option value={"720p"}>720p</option>
                          <option value={"1080p"}>1080p</option>
                          <option value={"4K"}>4K</option>
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        파일 업로드
                      </span>
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                            aria-label="Default select example"
                            className={"h-100"}
                            value={editFormData.file}
                            onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  file: e.target.value,
                                })
                            }
                        >
                          <option value={""}>VOD 파일 업로드</option>
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        썸네일 업로드
                      </span>
                      </Col>
                      <Col xs={5}>
                        <Form.Control
                            type={"file"}
                            accept={"image/*"}
                            name="thumbnail"
                            className={"h-100"}
                            onChange={handleThumbnailChange}
                            // value={editFormData.thumbnail}
                            // onChange={(e) => setEditFormData({...editFormData, thumbnail: e.target.value})}
                        />
                      </Col>
                    </Row>

                    {editFormData.thumbnail && (
                        <Row>
                          <Col xs={2} className={"my-auto align-middle"}>
                        <span
                            className={
                              "d-block w-100 p-2 rounded-3 text-center fs-5"
                            }
                        >
                          &nbsp;
                        </span>
                          </Col>
                          <Col xs={2}>
                            {editFormData.thumbnail && (
                                <img
                                    src={STATIC_FILE_URL + editFormData.thumbnail}
                                    alt={"thumbnail"}
                                    className={"img-fluid w-100 mt-3"}
                                />
                            )}
                          </Col>
                        </Row>
                    )}

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        타이틀
                      </span>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                            type={"text"}
                            placeholder={"타이틀 입력"}
                            className={"h-100"}
                            value={editFormData.title}
                            onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  title: e.target.value,
                                })
                            }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                      <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                      >
                        내용
                      </span>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                            type={"text"}
                            placeholder={"내용 입력"}
                            className={"h-100"}
                            value={editFormData.description}
                            onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  description: e.target.value,
                                })
                            }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col
                xs={12}
                className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                  type={"button"}
                  variant={"success"}
                  className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                  // onClick={() => alert("저장")}
                  onClick={editVod}
              >
                저장
              </Button>
              <Button
                  type={"button"}
                  variant={"danger"}
                  className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                  onClick={deleteVod}
              >
                삭제
              </Button>
            </Col>
          </Row>
        </>
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getVODList();
  };

  const handleDetail = (detail) => {
    console.log("handleDetail id : ", detail);
    setVodEditModalShow(true);

    getTvsVodDetail(detail.id)
        .then((res) => {
          console.log("getTvsVodDetail res : ", res.data);
          setEditFormData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  return (
      <>
        <Row className={"row-gap-3"}>
          <Col
              xs={12}
              className={"border p-3 rounded-3"}
              style={{backgroundColor: "#1D222A"}}
          >
            <Form onSubmit={handleSearch}>
              <Row>
                <Col xs={4}>
                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span className="fs-4 fw-bold">| VOD 영상 관리</span>
                    </Col>
                    <Col xs={4}>
                      <Button
                          type={"button"}
                          className={"w-100 h-100 fs-5 fw-bold"}
                          onClick={() => {
                            setVodRegisterModalShow(true);
                          }}
                      >
                        VOD 업로드
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={"mt-3 justify-content-start"}>
                <Col xs={4}>
                  <Row className="row-gap-2">
                    <Col xs={4} className={"align-middle h-100"}>
                      <Form.Select
                          aria-label="Default select example "
                          className={"w-100 p-2 fs-5 primary-select"}
                          value={searchParam.category}
                          onChange={(e) =>
                              setSearchParam({
                                ...searchParam,
                                category: e.target.value,
                              })
                          }
                      >
                        <option value={""}>카테고리 선택</option>
                        {vodCategoryList.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={6} className={"align-middle"}>
                      <Form.Control
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className={"w-100 p-2 fs-5"}
                          value={searchParam.search}
                          onChange={(e) =>
                              setSearchParam({
                                ...searchParam,
                                search: e.target.value,
                              })
                          }
                      />
                    </Col>
                    <Col xs={2} className={"align-middle h-100"}>
                      <Button
                          type={"button"}
                          className={"w-100 p-2 fs-5 fw-bold"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSearch(e);
                          }}
                      >
                        검색
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col
              xs={12}
              className={"border p-3 rounded-3"}
              style={{backgroundColor: "#1D222A", height: "calc(100vh - 22rem)"}}
          >
            <Row className={"row-gap-3"}>
              <Col xs={6} className={"my-auto align-middle"}>
                <span className="fs-4 fw-bold">| VOD 영상 리스트</span>
              </Col>
              <Col xs={12}>
                {tableData ? (
                    <CustomTable
                        columns={columns}
                        tableData={tableData}
                        handleDetail={handleDetail}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                ) : (
                    <Col className="w-100 text-center my-5">
                      <span>검색 결과가 없습니다.</span>
                    </Col>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <CustomModal
            show={vodEditModalShow}
            onHide={() => {
              setVodEditModalShow(false);
              setEditFormData({
                category: "",
                resolution: "",
                file: "",
                thumbnail: "",
                title: "",
                content: "",
              });
            }}
        >
          {renderVodEdit()}
        </CustomModal>

        <CustomModal
            show={vodRegisterModalShow}
            onHide={() => {
              setVodRegisterModalShow(false);
              setRegisterFormData({
                category: "",
                resolution: "",
                file: "",
                thumbnail: "",
                title: "",
                content: "",
              });
            }}
        >
          {renderVodRegister()}
        </CustomModal>
      </>
  );
};

export default CMS;
