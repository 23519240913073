import React, { useState } from "react";
import Frame from "../components/common/Frame";
import BloodPressure from "../components/healthcare/BloodPressure";
import Temperature from "../components/healthcare/Temperature";
import BMI from "../components/healthcare/BMI";
import ECG from "../components/healthcare/ECG";
import HealthCareStatus from "../components/healthcare/HealthCareStatus";
import Usage from "../components/healthcare/Usage";

const Healthcare = () => {
  const subMenu = [
    { title: "혈압", content: <BloodPressure /> },
    { title: "체온", content: <Temperature /> },
    { title: "체중(BMI)", content: <BMI /> },
    { title: "심전도", content: <ECG /> },
    {
      title: "헬스케어 기기 동작현황",
      content: <HealthCareStatus />,
    },
    { title: "이용량", content: <Usage /> },
  ];

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  return (
    <Frame
      title={"헬스케어 시스템 운영 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Healthcare;
