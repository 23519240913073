import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const IssueScenario = () => {
  return (
    <Row className="p-3 border rounded" style={{ backgroundColor: "#1D222A" }}>
      <Col className="w-100 mb-1">
        <span className="fs-4 fw-bold w-100">| 이슈 사항 리스트</span>
      </Col>
      <Row>
        {/* 왼쪽 - 리스트 */}
        <Col
          xs={6}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 17.5rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
            (li, idx) => (
              <Row className="mb-3" key={idx}>
                <Col
                  xs={1}
                  className="border rounded text-center me-3 py-3 fs-5"
                >
                  {li}
                </Col>
                <Col className="border rounded text-center py-3 fs-5">
                  이슈 사항 내용
                </Col>
              </Row>
            )
          )}
        </Col>

        {/* 오른쪽 - 선택 */}
        <Col xs={6} className="d-flex flex-column justity-content-between ps-5">
          <Col>
            <Col xs={4} className={"p-3 border rounded text-center fs-5 mb-3"}>
              <label>카테고리 선택</label>
            </Col>
            <Col xs={8} className={"my-auto align-middle"}>
              <Form.Select className="primary-select" size="lg">
                <option defaultChecked value={""}>
                  카테고리
                </option>
              </Form.Select>
            </Col>
          </Col>

          <Col>
            <Col xs={4} className={"p-3 border rounded text-center fs-5 mb-3"}>
              <label>위험도</label>
            </Col>
            <Col xs={8} className={"my-auto align-middle"}>
              <Form.Select className="primary-select" size="lg">
                <option defaultChecked value={""}>
                  위험도 선택
                </option>
              </Form.Select>
            </Col>
          </Col>

          <Col>
            <Col xs={4} className={"p-3 border rounded text-center fs-5 mb-3"}>
              <label>처리방법</label>
            </Col>
            <Col xs={8} className={"my-auto align-middle"}>
              <Form.Select className="primary-select" size="lg">
                <option defaultChecked value={""}>
                  처리방법 선택
                </option>
              </Form.Select>
            </Col>
          </Col>

          <Col xs={12} className="text-end">
            <Button
              size="lg"
              className="btn-send me-3"
              onClick={() => alert("저장 버튼")}
            >
              저장
            </Button>
            <Button
              size="lg"
              variant="secondary"
              onClick={() => alert("초기화 버튼")}
            >
              초기화
            </Button>
          </Col>
        </Col>
      </Row>
    </Row>
  );
};

export default IssueScenario;
