import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import BasicBarChart from "../chart/BasicBarChart";
import BasicColumnChart from "../chart/BasicColumnChart";
import {getDistrict, getSeniorCenter, getStatisticsTvsCenter} from "../../api/apiClient";

const SystemUsageStatus = () => {
  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
        .then((res) => {
          console.log("getDistrictList", res.data);
          setDistrictList(res.data);
        })
        .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
        .then((res) => {
          // console.log("center", res.data);
          setCenterList(res.data);
        })
        .catch((err) => console.error(err));
  };

  const getStatisticsData = () => {
    getStatisticsTvsCenter(searchParam.local, searchParam.center)
        .then((res) => {
          console.log("getStatisticsData", res.data);

          const dates = Object.keys(res.data.tvs_usage.live_streaming);
          const latestDate = dates.sort().pop(); // Get the latest date

          setTvsMainData({
            title: "양방향 화상 - 메인 기능",
            xAxisCategories: ["실시간 방송", "VOD", "IPTV"],
            series: [
              {
                type: "bar",
                name: "Data",
                data: [
                  {y: res.data.tvs_usage.live_streaming[latestDate], color: "#2ECF8F"},
                  {y: res.data.tvs_usage.vod[latestDate], color: "#FB72F4"},
                  {y: res.data.tvs_usage.iptv[latestDate], color: "#FF8C18"},
                ],
              },
            ],
            yAxisTitle: "Values",
          });

          setTvsEtcData({
            title: "양방향 화상 - 기타 기능 이용현황",
            xAxisCategories: [
              "날씨 정보",
              "버스 정보",
              "외부 메시지",
              "경로당간 영상통화",
            ],
            series: [
              {
                type: "column",
                name: "Scores",
                data: [
                  {y: res.data.tvs_usage.weather[latestDate], color: "#2ECF8F"},
                  {y: res.data.tvs_usage.bus[latestDate], color: "#FB72F4"},
                  {y: res.data.tvs_usage.message[latestDate], color: "#FF8C18"},
                  {y: res.data.tvs_usage.video_call[latestDate], color: "#9E54F3"},
                ],
              },
            ],
          });

          setHealthData({
            title: "헬스케어 이용 현황",
            xAxisCategories: ["혈압측정", "체온측정", "체중 (BMI) 측정", "심전도 측정"],
            series: [
              {
                type: "column",
                name: "Scores",
                data: [
                  {y: res.data.health_care_usage.blood_pressure, color: "#2ECF8F"},
                  {y: res.data.health_care_usage.body_temperature, color: "#FB72F4"},
                  {y: res.data.health_care_usage.weight, color: "#FF8C18"},
                  {y: res.data.health_care_usage.electrocardiogram, color: "#9E54F3"},
                ],
              },
            ],
          });

          setLifeData({
            title: "생활케어 이용 현황",
            xAxisCategories: ["워킹머신", "스마트테이블"],
            series: [
              {
                type: "column",
                name: "Scores",
                data: [
                  {y: res.data.life_care_usage.walking_machine, color: "#2ECF8F"},
                  {y: res.data.life_care_usage.smart_table, color: "#FB72F4"},
                ],
              },
            ],
          })
        })
        .catch((err) => console.error(err));

  };
  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getStatisticsData();
  }, []);

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const [tvsMainData, setTvsMainData] = useState({});
  const [tvsEtcData, setTvsEtcData] = useState({});
  const [healthData, setHealthData] = useState({});
  const [lifeData, setLifeData] = useState({});

/*  const barChartData = {
    title: "양방향 화상 - 메인 기능",
    xAxisCategories: ["실시간 방송", "VOD", "IPTV"],
    series: [
      {
        type: "bar",
        name: "Data",
        data: [
          {y: 63, color: "#2ECF8F"}, // 생활케어 이용
          {y: 48, color: "#FB72F4"}, // 헬스케어 측정
          {y: 22, color: "#FF8C18"}, // 양방향 회의
        ],
      },
    ],
    yAxisTitle: "Values",
  };

  const columnChartData1 = {
    title: "양방향 화상 - 기타 기능 이용현황",
    xAxisCategories: [
      "날씨 정보",
      "버스 정보",
      "외부 메시지",
      "경로당간 영상통화",
    ],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          {y: 70, color: "#2ECF8F"},
          {y: 85, color: "#FB72F4"},
          {y: 90, color: "#FF8C18"},
          {y: 80, color: "#9E54F3"},
        ],
      },
    ],
    yAxisTitle: "Scores",
  };

  const columnChartData2 = {
    title: "헬스케어 이용 현황",
    xAxisCategories: ["혈압측정", "체온측정", "체중 (BMI) 측정", "심전도 측정"],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          {y: 70, color: "#2ECF8F"},
          {y: 85, color: "#FB72F4"},
          {y: 90, color: "#FF8C18"},
          {y: 80, color: "#9E54F3"},
        ],
      },
    ],
    yAxisTitle: "Scores",
  };

  const columnChartData3 = {
    title: "생활케어 이용 현황",
    xAxisCategories: ["워킹머신", "스마트테이블"],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          {y: 70, color: "#2ECF8F"},
          {y: 85, color: "#FB72F4"},
        ],
      },
    ],
    yAxisTitle: "Scores",
  };*/

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("search");

    setSearchParam({
      local: "",
      center: "",
    });
  };

  return (
      <Form onSubmit={handleSubmit}>
        <Row className={"row-gap-3 m-0"}>
          {/* 경로당 검색 */}
          <Col
              xs={12}
              className="p-3 border rounded"
              style={{backgroundColor: "#1D222A"}}
          >
            <Row className="justify-content-between">
              <Col xs={8}>
                <Row className={""}>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 모니터링 검색조건</span>
                  </Col>
                  <Col xs={2}>
                    <Form.Select
                        aria-label="Default select example"
                        value={searchParam.local}
                        onChange={(e) => setSearchParam({...searchParam, local: e.target.value})}
                    >
                      <option value={""}>구</option>
                      {districtList &&
                          districtList.map((district) => (
                              <option key={district.id} value={district.id}>
                                {district.name}
                              </option>
                          ))}
                    </Form.Select>
                  </Col>
                  <Col xs={2}>
                    <Form.Select
                        aria-label="Default select example"
                        value={searchParam.center}
                        onChange={handleCenterChange}
                    >
                      <option value={""}>경로당</option>
                      {centerList &&
                          centerList.map((center) => (
                              <option key={center.id} value={center.id}>
                                {center.name}
                              </option>
                          ))}
                    </Form.Select>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                        type="text"
                        placeholder="검색어를 입력하세요."
                        className={"w-100"}
                        disabled={searchParam.center !== ""}
                        value={searchParam.keyword}
                        onChange={handleKeywordChange}
                    />
                  </Col>
                  <Col xs={2} className={"text-end"}>
                    <Button
                        type={"button"}
                        className={"px-5"}
                        onClick={() => {
                          getStatisticsData();
                        }}
                    >
                      검색
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>

          {/* 차트 */}
          <Col
              xs={12}
              style={{
                height: "calc(100vh - 21rem)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
          >
            <Row className="row-gap-4">
              <Col xs={6} className="ps-0">
                {/* 양방향 화상 - 메인 기능 */}
                <BasicBarChart {...tvsMainData} />
              </Col>
              <Col xs={6} className="pe-0">
                {/* 양방향 화상 - 기타 기능 이용현황 */}
                <BasicColumnChart {...tvsEtcData} />
              </Col>
              <Col xs={6} className="ps-0">
                {/* 헬스케어 이용 현황 */}
                <BasicColumnChart {...healthData} />
              </Col>
              <Col xs={6} className="pe-0">
                {/* 생활케어 이용 현황 */}
                <BasicColumnChart {...lifeData} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
  );
};

export default SystemUsageStatus;
