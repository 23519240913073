import React from 'react';
import {Button, Col, Row} from "react-bootstrap";

const BusStopList = ({busStops, onSelectBusStop}) => {

  console.log("BusStopList", busStops);

  return (
      <Row className={"gap-3"}>
        <Col xs={12}>
          <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>정류장 리스트</span>
        </Col>
        <Col xs={12} style={{maxHeight: "360px", overflowY: "auto"}}>
          <Row className={"gap-3"}>
            {/* 배열일 경우 */}
            {/*{Array.isArray(busStops) && busStops.map((busStop, index) => (
                <Col xs={12} key={index}>
                  <Button
                      type={"button"}
                      className={"w-100"}
                      onClick={() => onSelectBusStop(busStop.id, busStop.name, busStop.station_number)}
                  >
                    ({busStop.station_number}) {busStop.name}
                  </Button>
                </Col>
            ))}*/}

            {Object.keys(busStops).length > 0 ? (
                <Col xs={12}>
                  <Button
                      type={"button"}
                      className={"w-100"}
                      onClick={() => onSelectBusStop(busStops.id, busStops.name, busStops.station_number)}
                  >
                    ({busStops.station_number}) {busStops.name}
                  </Button>
                </Col>
            ) : (
                <Col xs={12}>
                  <span className={"d-block w-100 p-2 rounded-3 text-center fs-5"}>
                    {/* 정류장 정보가 없습니다. */}
                    검색어가 올바르지 않습니다.
                  </span>
                </Col>
            )}
          </Row>
        </Col>
      </Row>
  );
};

export default BusStopList;