import React, {useState} from 'react';
import {Tree, TreeNode} from 'react-organizational-chart';

const Organization = () => {
  // 초기 트리 데이터
  const [treeData, setTreeData] = useState({
    id: '1',
    name: '인천시',
    children: [
      {id: '2', name: '소속 구'},
      {id: '3', name: '소속 구', children: [{id: '4', name: '경로당명'}]},
      {id: '5', name: '소속 구'},
      {id: '6', name: '소속 구', children: [{id: '7', name: '경로당명'}, {id: '8', name: '경로당명'}]},
    ],
  });

  // 노드 추가 함수
  const addNode = (parentId) => {
    const newNode = {
      id: Date.now().toString(),
      name: '새 노드',
    };

    const traverseAndAdd = (node) => {
      if (node.id === parentId) {
        node.children = node.children ? [...node.children, newNode] : [newNode];
      } else if (node.children) {
        node.children.forEach(traverseAndAdd);
      }
    };

    const newTree = {...treeData};
    traverseAndAdd(newTree);
    setTreeData(newTree);
  };

  // 노드 삭제 함수
  const deleteNode = (targetId) => {
    const traverseAndDelete = (node) => {
      if (node.children) {
        node.children = node.children.filter((child) => child.id !== targetId);
        node.children.forEach(traverseAndDelete);
      }
    };

    const newTree = {...treeData};
    traverseAndDelete(newTree);
    setTreeData(newTree);
  };

  // 노드 수정 함수
  const editNode = (targetId) => {
    const newName = window.prompt('새로운 노드 이름을 입력하세요:');
    if (!newName) return;

    const traverseAndEdit = (node) => {
      if (node.id === targetId) {
        node.name = newName;
      } else if (node.children) {
        node.children.forEach(traverseAndEdit);
      }
    };

    const newTree = {...treeData};
    traverseAndEdit(newTree);
    setTreeData(newTree);
  };

  // 트리 노드 렌더링 함수
  const renderTreeNodes = (node) => {
    return (
        <TreeNode
            label={
              <div
                  style={{
                    border: '1px solid #48b6f4',
                    borderRadius: '8px',
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor: '#48b6f4',
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                  }}
              >
                {node.name}
                {/* 버튼 영역 */}
                <div style={{marginTop: '8px', display: 'flex', justifyContent: 'center', gap: '4px'}}>
                  <button
                      onClick={() => addNode(node.id)}
                      style={{
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '4px 8px',
                        cursor: 'pointer',
                      }}
                  >
                    추가
                  </button>
                  <button
                      onClick={() => editNode(node.id)}
                      style={{
                        backgroundColor: '#ff9800',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '4px 8px',
                        cursor: 'pointer',
                      }}
                  >
                    수정
                  </button>
                  <button
                      onClick={() => deleteNode(node.id)}
                      style={{
                        backgroundColor: '#f44336',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '4px 8px',
                        cursor: 'pointer',
                      }}
                  >
                    삭제
                  </button>
                </div>
              </div>
            }
        >
          {node.children && node.children.map((child) => renderTreeNodes(child))}
        </TreeNode>
    );
  };

  return (
      <div style={{width: '100%', height: '100%', overflowX: 'auto', padding: '20px'}}>
        <Tree
            lineWidth={'2px'}
            lineColor={'#ccc'}
            lineBorderRadius={'10px'}
            label={
              <div
                  style={{
                    border: '2px solid #48b6f4',
                    borderRadius: '8px',
                    padding: '8px',
                    backgroundColor: '#48b6f4',
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
              >
                {treeData.name}
              </div>
            }
        >
          {treeData.children && treeData.children.map((child) => renderTreeNodes(child))}
        </Tree>
      </div>
  );
};

export default Organization;
