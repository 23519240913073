// charts/BasicColumnChart.jsx
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col } from "react-bootstrap";

const BasicColumnChart = ({
  title,
  xAxisCategories,
  series,
  yAxisTitle,
  height,
}) => {
  const options = {
    chart: {
      type: "column",
      backgroundColor: "transparent", // 배경 투명
      height: height || "37%",
    },
    title: {
      text: title,
      style: { color: "#FFFFFF" }, // 제목 텍스트 색상
    },
    xAxis: {
      categories: xAxisCategories,
      labels: { style: { color: "#FFFFFF" } }, // X축 텍스트 색상
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: { color: "#FFFFFF" }, // Y축 제목 색상
      },
      labels: { style: { color: "#FFFFFF" } }, // Y축 레이블 색상
    },
    credits: {
      enabled: false, // Highcharts 로고 제거
    },
    legend: {
      enabled: false, // 범례 제거
      itemStyle: { color: "#FFFFFF" }, // 범례 텍스트 색상
    },
    plotOptions: {
      series: {
        borderWidth: 0, // 막대 테두리 제거
      },
    },
    series,
  };

  return (
    <Col className="p-3 border rounded" style={{ backgroundColor: "#1D222A" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Col>
  );
};

export default BasicColumnChart;
