import React, {useEffect, useState} from 'react';
import {Viewer} from '@react-pdf-viewer/core';
import {pdfjs} from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {Worker} from '@react-pdf-viewer/core';

// Set the workerSrc property
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({file}) => {
  useEffect(() => {
    console.log('file', file);
  }, [file]);

  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);

  const onLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const onLoadError = (error) => {
    setError(error);
    console.error('Error loading PDF: ', error);
  };

  return (
      <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '600px',
          }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl={file} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError}/>
          {error && <p>Error loading PDF: {error.message}</p>}
        </Worker>
      </div>
  );
};

export default PdfViewer;