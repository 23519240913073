import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";

const IssueOccurrenceStatus = () => {
  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
  });

  const columnChartData1 = {
    title: "이슈 사항 발생 통계",
    xAxisCategories: ["장비 alive", "관리자 로그"],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          { y: 70, color: "#2ECF8F" },
          { y: 90, color: "#FB72F4" },
        ],
      },
    ],
    yAxisTitle: "Scores",
    height: "80%",
  };

  const columnChartData2 = {
    title: "이슈 사항 처리 통계",
    xAxisCategories: ["장비 alive", "관리자 로그"],
    series: [
      {
        type: "column",
        name: "Scores",
        data: [
          { y: 90, color: "#2ECF8F" },
          { y: 70, color: "#FB72F4" },
        ],
      },
    ],
    yAxisTitle: "Scores",
    height: "80%",
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("search");

    setSearchParam({
      local: "",
      center: "",
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className={"row-gap-3 m-0"}>
        {/* 경로당 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={12} className="mb-2">
            <span className="fs-4 fw-bold">| 이슈 사항 검색</span>
          </Col>
          <Row className="justify-content-between">
            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5"
                    value={searchParam.local}
                    onChange={(e) => {
                      setSearchParam({ ...searchParam, local: e.target.value });
                    }}
                  >
                    <option value={""}>구</option>
                    <option value={""}>구1</option>
                    <option value={""}>구2</option>
                  </Form.Select>
                </Col>
                {/* <Col xs={3}>
                         <Form.Select>
                           <option value={""}>경로당</option>
                           <option value={""}>경로당1</option>
                           <option value={""}>경로당2</option>
                         </Form.Select>
                       </Col> */}
                <Col xs={2} className="border rounded text-center p-2 fs-5">
                  경로당 명
                </Col>
                <Col xs={5}>
                  <Form.Control
                    type="text"
                    placeholder="경로당명 입력"
                    className="h-100 fs-5"
                    value={searchParam.center}
                    onChange={(e) => {
                      setSearchParam({
                        ...searchParam,
                        center: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={1} className="">
              <Button
                type="submit"
                className="w-100 h-100 fs-5 fw-bold"
                // onClick={() => alert("검색 버튼")}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 차트 */}
        <Col
          xs={12}
          style={{
            height: "calc(100vh - 21rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={6} className="ps-0">
              <BasicColumnChart {...columnChartData1} />
            </Col>
            <Col xs={6} className="pe-0">
              <BasicColumnChart {...columnChartData2} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default IssueOccurrenceStatus;
