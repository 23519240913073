import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import {
  deleteAccountsCenter,
  getAccountsAuthority,
  getAccountsCenter,
  getCenterDetail,
  getDistricts,
  postAccountsCenter,
  putAccountsCenter,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";

const ManageCenter = () => {
  const [videoDetailModalShow, setVideoDetailModalShow] = useState(false);
  const [isDetailView, setIsDetailView] = useState(false); // 추가/수정
  const [healthDetailModalShow, setHealthDetailModalShow] = useState(false);

  const [searchParam, setSearchParam] = useState({
    district: "",
    search: "",
  });

  const [detailFormData, setDetailFormData] = useState({
    name: "",
    district: "",
    center_id: "",
    address: "",
  });

  const [districtList, setDistrictList] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "순번" },
    { key: "district.name", title: "소속 구" },
    { key: "name", title: "경로당 명" },
    { key: "center_id", title: "경로당 ID" },
    { key: "address", title: "위치" },
    { key: "edit", title: "수정하기" },
  ];

  const getDistrictList = () => {
    getDistricts()
      .then((res) => {
        console.log("getDistricts res : ", res.data);
        setDistrictList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAccountsCenterList = () => {
    getAccountsCenter(currentPage, searchParam.district, searchParam.search)
      .then((res) => {
        console.log("getAccountsCenter res : ", res.data);
        setTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
  }, []);

  const handleAdd = () => {
    setDetailFormData({
      name: "",
      district: "",
      center_id: "",
      address: "",
    });
    setVideoDetailModalShow(true);
    setIsDetailView(false); // 추가 상태로 변경
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getAccountsCenterList();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAccountsCenterList();
  };

  const handleDetail = (detail) => {
    getCenterDetail(detail?.id)
      .then((res) => {
        console.log("detail", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.error(err));

    setVideoDetailModalShow(true);
    setIsDetailView(true);
  };

  // 저장 버튼
  const handleSave = () => {
    if (!window.confirm("생성 하시겠습니까?")) return;

    const updatedData = {
      name: detailFormData.name,
      district: detailFormData.district,
      center_id: detailFormData.center_id,
      address: detailFormData.address,
    };

    console.log("updatedData", updatedData);

    postAccountsCenter(updatedData)
      .then((res) => {
        console.log("post data", res.data);
        getAccountsCenterList(); // 데이터 갱신
        setVideoDetailModalShow(false);
      })
      .catch((err) => console.error(err));
  };

  // 수정 버튼
  const handleEdit = () => {
    if (!window.confirm("수정 하시겠습니까?")) return;

    const updatedData = {
      name: detailFormData.name,
      district: detailFormData.district.id,
      center_id: detailFormData.center_id,
      address: detailFormData.address,
    };

    console.log("수정 요청 데이터:", updatedData);

    putAccountsCenter(detailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료:", res.data);
        setVideoDetailModalShow(false);
        getAccountsCenterList(); // 데이터 갱신
      })
      .catch((error) => {
        console.error("수정 실패:", error);
      });
  };

  // 삭제 버튼
  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteAccountsCenter(detailFormData.id, "center")
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        setVideoDetailModalShow(false); // 모달 닫기
        getAccountsCenterList(); // 데이터 갱신
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderVideoDetail = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row className={"row-gap-3"}>
              <Col xs={12} className={"my-auto align-middle"}>
                <span className="fs-4 fw-bold">
                  {!isDetailView ? "| 경로당 ID 생성" : "| 경로당 ID 수정"}
                </span>
              </Col>

              {/* 경로당 아이디 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    경로당 명
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type={"text"}
                    placeholder={"아이디는 자동으로 생성됩니다."}
                    className={"w-100 h-100"}
                    value={detailFormData.center_id}
                    onChange={(e) =>
                      setDetailFormData({
                        ...detailFormData,
                        center_id: e.target.value,
                      })
                    }
                    disabled
                  />
                </Col>
              </Row>

              {/* 경로당 검색 필터 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    경로당 검색 필터
                  </span>
                </Col>
                <Col xs={6}>
                  <Row className="h-100">
                    <Col className="">
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                        value={
                          !isDetailView
                            ? detailFormData.district
                            : detailFormData.district?.id
                        }
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            district: e.target.value,
                          })
                        }
                      >
                        <option value={""}>전체</option>
                        {districtList.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={detailFormData?.name}
                        onChange={(e) =>
                          setDetailFormData({
                            ...detailFormData,
                            name: e.target.value,
                          })
                        }
                      >
                        <option value={""}>경로당</option>
                        {tableData?.results?.map((center) => (
                          <option key={center.id} value={center.name}>
                            {center.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* 경로당 위치 */}
              <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    위치 설정
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type={"text"}
                    placeholder={"경로당 위치"}
                    className={"w-100 h-100"}
                    value={detailFormData.address}
                    onChange={(e) =>
                      setDetailFormData({
                        ...detailFormData,
                        address: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>

              {/* <Row>
                <Col xs={3} className={"my-auto align-middle"}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    성별 선택
                  </span>
                </Col>

                <Col>
                  <Form.Check type={"radio"} label={"남성"} />
                  <Form.Check type={"radio"} label={"여성"} />
                </Col>
              </Row> */}
            </Row>
          </Col>

          {!isDetailView ? (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                variant={"primary"}
                onClick={handleSave}
              >
                생성
              </Button>
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5"}
                variant={"secondary"}
                onClick={() =>
                  setDetailFormData({
                    name: "",
                    district: "",
                    center_id: "",
                    address: "",
                  })
                }
              >
                초기화
              </Button>
            </Col>
          ) : (
            <Col
              xs={12}
              className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                type={"button"}
                className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                onClick={handleEdit}
              >
                저장
              </Button>
              <Button
                type={"button"}
                variant={"danger"}
                className={"rounded-5 px-5 fw-bold fs-5"}
                onClick={handleDelete}
              >
                삭제
              </Button>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const renderHealthDetail = () => {
    return <>헬스케어 모달</>;
  };

  return (
    <>
      <Row className={"row-gap-3"}>
        {/* 검색 */}
        <Form onSubmit={handleSearch} className="p-0">
          <Col
            xs={12}
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Col xs={12} className="mb-2">
              <span className="fs-4 fw-bold">| 경로당 계정 검색</span>
            </Col>
            <Row className="justify-content-between">
              <Col xs={4}>
                <Row className="row-gap-2">
                  <Col xs={3}>
                    <Form.Select
                      className="h-100 fs-5"
                      value={searchParam.district}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          district: e.target.value,
                        })
                      }
                    >
                      <option value={""}>전체</option>
                      {districtList.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  {/* <Col xs={3}>
                  <Form.Select>
                    <option value={""}>경로당</option>
                    <option value={""}>경로당1</option>
                    <option value={""}>경로당2</option>
                  </Form.Select>
                </Col> */}
                  <Col xs={2} className="border rounded text-center p-2 fs-5">
                    경로당 명
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      placeholder="경로당명 입력"
                      className="h-100 fs-5"
                      value={searchParam.search}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          search: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={1} className="">
                <Button
                  type="submit"
                  className="w-100 h-100 fs-5 fw-bold"
                  // onClick={() => alert("검색 버튼")}
                >
                  검색
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>

        <Row
          className="gap-3 m-0 p-0"
          style={{
            height: "calc(100vh - 22rem)",
          }}
        >
          {/* 양방향 */}
          <Col
            className={"border p-2 rounded-3"}
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"row-gap-3"}>
              <Col xs={6}>
                <span className="fs-4 fw-bold">| 양방향 화상 계정 정보</span>
              </Col>
              <Col xs={6} className={"text-end"}>
                <Button
                  type={"button"}
                  variant={"primary"}
                  className="px-5 h-100 fs-5 fw-bold"
                  onClick={handleAdd}
                >
                  생성
                </Button>
              </Col>
              <Col xs={12}>
                {tableData ? (
                  <CustomTable
                    columns={columns}
                    tableData={tableData}
                    handleDetail={handleDetail}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
                )}
              </Col>
            </Row>
          </Col>

          {/* 헬스&생활 */}
          <Col
            className={"border p-2 rounded-3"}
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row className={"row-gap-3"}>
              <Col xs={6}>
                <span className="fs-4 fw-bold">| 헬스&생활케어 계정 정보</span>
              </Col>
              <Col xs={6} className={"text-end"}>
                <Button
                  type={"button"}
                  variant={"primary"}
                  className="px-5 h-100 fs-5 fw-bold"
                  onClick={() => setHealthDetailModalShow(true)}
                >
                  생성
                </Button>
              </Col>
              <Col xs={12}></Col>
            </Row>
          </Col>
        </Row>
      </Row>

      <CustomModal
        show={videoDetailModalShow}
        onHide={() => setVideoDetailModalShow(false)}
        onConfirm={() => alert("저장 버튼이 없음")}
      >
        {renderVideoDetail()}
      </CustomModal>

      <CustomModal
        show={healthDetailModalShow}
        onHide={() => setHealthDetailModalShow(false)}
        onConfirm={() => alert("저장 버튼이 없음")}
      >
        {renderHealthDetail()}
      </CustomModal>
    </>
  );
};

export default ManageCenter;
