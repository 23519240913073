import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col } from "react-bootstrap";

const PieChart = ({
  title,
  series,
  centerText1,
  centerText2,
  innerSize,
  className,
  backgroundImage,
}) => {
  const options = {
    chart: {
      type: "pie",
      backgroundColor: "transparent", // 배경 투명
      height: "95%",
      events: {
        render() {
          const chart = this;

          // 첫 번째 텍스트 추가
          if (!chart.customCenterText1) {
            chart.customCenterText1 = chart.renderer
              .text(
                centerText1,
                chart.plotWidth / 2 + chart.plotLeft,
                chart.plotHeight / 2 + chart.plotTop - 10
              ) // 텍스트 위치 조정
              .attr({
                align: "center",
                zIndex: 5,
              })
              .css({
                color: "#FFFFFF",
                fontSize: "16px",
                textAlign: "center",
              })
              .add();
          } else {
            chart.customCenterText1.attr({
              text: centerText1,
            });
          }

          // 두 번째 텍스트 추가
          if (!chart.customCenterText2) {
            chart.customCenterText2 = chart.renderer
              .text(
                centerText2,
                chart.plotWidth / 2 + chart.plotLeft,
                chart.plotHeight / 2 + chart.plotTop + 10
              ) // 텍스트 위치 조정
              .attr({
                align: "center",
                zIndex: 5,
              })
              .css({
                color: "#FFFFFF",
                fontSize: "14px",
                textAlign: "center",
              })
              .add();
          } else {
            chart.customCenterText2.attr({
              text: centerText2,
            });
          }
        },
      },
    },
    title: {
      text: title,
      style: { color: "#FFFFFF" }, // 제목 텍스트 색상
    },
    plotOptions: {
      pie: {
        innerSize: innerSize || "70%", // Donut chart (50%)
        dataLabels: {
          enabled: true,
          style: { color: "#FFFFFF" }, // 데이터 레이블 텍스트 색상
        },
      },
      series: {
        borderWidth: 0, // 막대 테두리 제거
      },
    },
    credits: {
      enabled: false, // Highcharts 로고 제거
    },
    legend: {
      itemStyle: { color: "#FFFFFF" }, // 범례 텍스트 색상
    },
    series,
  };

  return (
    <Col
      className={`p-3 rounded ${className}`}
      style={{
        backgroundColor: "#1D222A",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Col>
  );
};

export default PieChart;
