import React, {useState} from 'react';
import '@fullcalendar/common/main.css';
import './CustomDatePicker.scss';
import Scheduler from "./sub/Scheduler";
import ScheduleSetting from "./sub/ScheduleSetting";

const ProgramSchedule = () => {

  const [selectedContent, setSelectedContent] = useState("Scheduler");
  const [selectedSchedule, setSelectedSchedule] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  });

  const renderContent = () => {
    if (selectedContent === "Scheduler") {
      return (
          <Scheduler
              selectedSchedule={selectedSchedule}
              setSelectedSchedule={setSelectedSchedule}
              setSelectedContent={setSelectedContent}
          />
      );
    } else if (selectedContent === "ScheduleSetting") {
      return (
          <ScheduleSetting
              selectedSchedule={selectedSchedule}
              setSelectedSchedule={setSelectedSchedule}
              setSelectedContent={setSelectedContent}
          />
      );
    }
  }

  return (
      <>
        {renderContent()}
      </>
  );
};

export default ProgramSchedule;