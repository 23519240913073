import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import {getAccountsLog, getAccountsLogCategory, getAuthorities} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";

const ManageLogs = () => {

  const [searchParam, setSearchParam] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
    year2: new Date().getFullYear(),
    month2: new Date().getMonth() + 1,
    day2: new Date().getDate() + 1,
    authority: "",
    category: "",
    search: "",
  });

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [accountsAuthorityList, setAccountsAuthorityList] = useState([]); // 템플릿 카테고리 api
  const [accountsCategory, setAccountsCategory] = useState([]); // 템플릿 카테고리 api

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {key: "created_at", title: "발생일시"},
    {key: "category.name", title: "카테고리"},
    {key: "content", title: "내용"},
    {key: "user_authority", title: "직책"},
    {key: "user_username", title: "관리자 ID"},
    {key: "user_name", title: "이름"},
    {key: "user_phone", title: "연락처"},
  ];

  const getAuthorityList = () => {
    getAuthorities()
        .then((res) => {
          console.log(res);
          setAccountsAuthorityList(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getCategoryList = () => {
    getAccountsLogCategory()
        .then((res) => {
          console.log(res);
          setAccountsCategory(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getLogList = () => {
    const start_date = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const end_date = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getAccountsLog(
        currentPage,
        searchParam.search,
        start_date,
        end_date,
        searchParam.category,
        searchParam.authority
    )
        .then((res) => {
          console.log(res);
          setTableData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  useEffect(() => {
    getAuthorityList();
    getCategoryList();
    getLogList();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(searchParam);
    getLogList();
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getLogList();
  };

  return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12} className={"border p-2 rounded-3"} style={{backgroundColor: "#1D222A"}}>
            <Form onSubmit={handleSearch}>
              <Row className={"row-gap-3"}>
                <Col xs={12}>
                  <span className="fs-4 fw-bold">| 관리자 검색</span>
                </Col>
                <Col xs={8}>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center"}>검색일자</span>
                    </Col>
                    <Col xs={10} className="d-flex gap-2">
                      <Col>
                        <Form.Select
                            aria-label="Default select example"
                            value={searchParam.year}
                            onChange={(e) => setSearchParam({...searchParam, year: e.target.value})}
                        >
                          <option value={""}>년 리스트</option>
                          <option value={2024}>2024년</option>
                          <option value={2025}>2025년</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                            aria-label="Default select example"
                            value={searchParam.month}
                            onChange={(e) => setSearchParam({...searchParam, month: e.target.value})}
                        >
                          {Array.from({length: 12}, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}월
                              </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                            aria-label="Default select example"
                            value={searchParam.day}
                            onChange={(e) => setSearchParam({...searchParam, day: e.target.value})}
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                              <option key={day} value={day}>
                                {day}일
                              </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col
                          className="border rounded text-center p-2 my-auto align-middle"
                          style={{maxWidth: "2rem"}}
                      >
                        ~
                      </Col>
                      <Col>
                        <Form.Select
                            aria-label="Default select example"
                            value={searchParam.year2}
                            onChange={(e) => setSearchParam({...searchParam, year2: e.target.value})}
                        >
                          <option value={""}>년 리스트</option>
                          <option value={2024}>2024년</option>
                          <option value={2025}>2025년</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                            aria-label="Default select example"
                            value={searchParam.month2}
                            onChange={(e) => setSearchParam({...searchParam, month2: e.target.value})}
                        >
                          <option value={""}>월 리스트</option>
                          {Array.from({length: 12}, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}월
                              </option>
                          ))}
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                            aria-label="Default select example"
                            value={searchParam.day2}
                            onChange={(e) => setSearchParam({...searchParam, day2: e.target.value})}
                        >
                          <option value={""}>일 리스트</option>
                          {daysInMonth.map((day) => (
                              <option key={day} value={day}>
                                {day}일
                              </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                </Col>
                <Col xs={8}>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center"}>직책</span>
                    </Col>
                    <Col>
                      <Form.Select
                          aria-label="Default select example"
                          value={searchParam.authority}
                          onChange={(e) => setSearchParam({...searchParam, authority: e.target.value})}
                      >
                        <option value={""}>직책 리스트</option>
                        {accountsAuthorityList.map((authority) => (
                            <option key={authority.id} value={authority.id}>
                              {authority.name}
                            </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center"}>카테고리</span>
                    </Col>
                    <Col>
                      <Form.Select
                          aria-label="Default select example"
                          value={searchParam.category}
                          onChange={(e) => setSearchParam({...searchParam, category: e.target.value})}
                      >
                        <option value={""}>전체</option>
                        {accountsCategory.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center"}>검색어</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} className={"text-end"}>
                  <Row>
                    <Col>
                      <Form.Control
                          type="text"
                          placeholder="검색어를 입력해주세요."
                          value={searchParam.search}
                          onChange={(e) => setSearchParam({...searchParam, search: e.target.value})}
                      />
                    </Col>
                    <Col>
                      <Button
                          type="submit"
                          variant="primary"
                          className={"px-5"}
                          onClick={(e) => {
                            // console.log(searchParam);
                            handleSearch(e);
                          }}
                      >
                        검색
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col xs={12} className={"border p-2 rounded-3"} style={{backgroundColor: "#1D222A"}}>
            <Row className={"row-gap-3"}>
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 관리자 로그 관리</span>
              </Col>
              <Col xs={12}>
                {tableData ? (
                    <CustomTable
                        columns={columns}
                        tableData={tableData}
                        // handleDetail={handleDetail}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                ) : (
                    <Col className="w-100 text-center my-5">
                      <span>검색 결과가 없습니다.</span>
                    </Col>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

      </>
  );
};

export default ManageLogs;
