import React, {useEffect, useState} from "react";
import Frame from "../components/common/Frame";
import ProgramSchedule from "../components/VideoConference/ProgramSchedule";
import VideoSystemManagement from "../components/VideoConference/VideoSystemManagement";
import CMS from "../components/VideoConference/CMS";
import ConvenientFunctionManagement from "../components/VideoConference/ConvenientFunctionManagement";
import MessageMemberManagement from "../components/VideoConference/MessageMemberManagement";
import DeviceOperationStatus from "../components/VideoConference/DeviceOperationStatus";
import Usage from "../components/VideoConference/Usage";
import {useNavigate} from "react-router-dom";
import {getTokenVerify} from "../api/apiClient";

const VideoConference = () => {

  /* 토큰 검증 */
  const navigate = useNavigate();

  useEffect(() => {
    getTokenVerify()
        .then((res) => {
          console.log("Token verify response : ", res);
        })
        .catch((error) => {
          console.error("Token verify error : ", error);
          navigate("/login");
        });
  }, []);

  const subMenu = [
    {
      title: "편성표 관리", content:
          <ProgramSchedule/>
    },
    {
      title: "화상 시스템 운영 관리",
      content: <VideoSystemManagement/>,
    },
    {title: "CMS", content: <CMS/>},
    {title: "편의기능 관리", content: <ConvenientFunctionManagement/>},
    {
      title: "외부 메시지 회원 관리",
      content: <MessageMemberManagement/>,
    },
    {
      title: "셋톱박스 기기 동작 현황",
      content: <DeviceOperationStatus/>,
    },
    {title: "이용량", content: <Usage/>},
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };


  return (
      <Frame
          title={"양방향 화상회의 시스템 운영 관리"}
          showTabMenu={true}
          subMenu={subMenu}
          selectedTab={selectedContent}
          onTabSelect={setSelectedContent}
      >
        {renderContent()}
      </Frame>
  );
};

export default VideoConference;
