import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import LineChart from "../chart/LineChart";

const IssueStatistics = () => {
  const lineChartData = {
    title: "",
    xAxisCategories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    series: [
      {
        type: "line",
        name: "이벤트 주간 발생 현황",
        data: [3, 5, 7, 2, 10, 6, 4],
      },
    ],
    yAxisTitle: "Counts",
  };

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="mb-2">
          <Col xs={7} className="d-flex gap-2">
            <Col xs={3}>
              <span className="fs-4 fw-bold">| 기간 검색 필터</span>
            </Col>
            <Col>
              <Form.Select
                className="h-100"
                aria-label="Default select example"
              >
                <option value={""}>년 리스트</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select
                className="h-100"
                aria-label="Default select example"
              >
                <option value={""}>월 리스트</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select
                className="h-100"
                aria-label="Default select example"
              >
                <option value={""}>일 리스트</option>
              </Form.Select>
            </Col>
            <Col
              className="border rounded text-center p-2"
              style={{ maxWidth: "2rem" }}
            >
              ~
            </Col>
            <Col>
              <Form.Select
                className="h-100"
                aria-label="Default select example"
              >
                <option value={""}>년 리스트</option>
              </Form.Select>
            </Col>

            <Col>
              <Form.Select
                className="h-100"
                aria-label="Default select example"
              >
                <option value={""}>월 리스트</option>
              </Form.Select>
            </Col>

            <Col>
              <Form.Select
                className="h-100"
                aria-label="Default select example"
              >
                <option value={""}>일 리스트</option>
              </Form.Select>
            </Col>
          </Col>
        </Row>

        <Row className="justify-content-between">
          <Col xs={7} className="d-flex gap-2">
            <Col xs={3}>
              <span className="fs-4 fw-bold">| 경로당 검색 필터</span>
            </Col>
            <Col xs={2} className="pe-0">
              <Form.Select className="h-100">
                <option value={""}>구</option>
                <option value={""}>구1</option>
                <option value={""}>구2</option>
              </Form.Select>
            </Col>
            <Col xs={2}>
              <Form.Select className="h-100">
                <option value={""}>경로당</option>
                <option value={""}>경로당1</option>
                <option value={""}>경로당2</option>
              </Form.Select>
            </Col>
            <Col xs={1} className="border rounded text-center p-2">
              경로당명
            </Col>
            <Col xs={3}>
              <Form.Control
                className="h-100"
                type="text"
                placeholder="경로당명 입력"
              />
            </Col>
          </Col>

          <Col xs={1} className="">
            <Button className="w-100" onClick={() => alert("검색 버튼")}>
              검색
            </Button>
          </Col>
        </Row>
      </Col>

      {/* 내용 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 23rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row>
          <Col className="w-100 mb-1">
            <span className="fs-4 fw-bold w-100">
              | 이슈 사항 카테고리 별 발생 건 수
            </span>
          </Col>

          {/* 장비 차트 */}
          <Row className="justify-content-between m-0">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              장비 alive
            </Col>
            <Col xs={10}>
              <LineChart {...lineChartData} />
            </Col>
          </Row>

          {/* 관리자 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              관리자 로그
            </Col>
            <Col xs={10}>
              <LineChart {...lineChartData} />
            </Col>
          </Row>

          {/* 위험도 상 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 상
            </Col>
            <Col xs={10}>
              <LineChart {...lineChartData} />
            </Col>
          </Row>

          {/* 위험도 중 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 중
            </Col>
            <Col xs={10}>
              <LineChart {...lineChartData} />
            </Col>
          </Row>

          {/* 위험도 하 차트 */}
          <Row className="justify-content-between m-0 mt-3">
            <Col
              xs={2}
              className="p-3 border rounded fs-4 d-flex justify-content-center align-items-center"
            >
              위험도 하
            </Col>
            <Col xs={10}>
              <LineChart {...lineChartData} />
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default IssueStatistics;
