import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const SmartTableContent = () => {
  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="mb-2">
          <Col xs={2}>
            <span className="fs-4 fw-bold">| 콘텐츠 검색</span>
          </Col>
          <Col xs={2} className="">
            <Form.Select>
              <option value={""}>콘텐츠 선택</option>
              <option value={""}>1</option>
              <option value={""}>2</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="justify-content-between">
          <Col xs={7} className="d-flex gap-2">
            <Col className="border rounded text-center p-2">기간 설정</Col>
            <Col>
              <Form.Select aria-label="Default select example">
                <option value={""}>년 리스트</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select aria-label="Default select example">
                <option value={""}>월 리스트</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select aria-label="Default select example">
                <option value={""}>일 리스트</option>
              </Form.Select>
            </Col>
            <Col
              className="border rounded text-center p-2"
              style={{ maxWidth: "2rem" }}
            >
              ~
            </Col>
            <Col>
              <Form.Select aria-label="Default select example">
                <option value={""}>년 리스트</option>
              </Form.Select>
            </Col>

            <Col>
              <Form.Select aria-label="Default select example">
                <option value={""}>월 리스트</option>
              </Form.Select>
            </Col>

            <Col>
              <Form.Select aria-label="Default select example">
                <option value={""}>일 리스트</option>
              </Form.Select>
            </Col>
          </Col>

          <Col xs={4}>
            <Row className="row-gap-2">
              <Col xs={3}>
                <Form.Select>
                  <option value={""}>구</option>
                  <option value={""}>구1</option>
                  <option value={""}>구2</option>
                </Form.Select>
              </Col>
              <Col xs={3}>
                <Form.Select>
                  <option value={""}>경로당</option>
                  <option value={""}>경로당1</option>
                  <option value={""}>경로당2</option>
                </Form.Select>
              </Col>
              <Col xs={2} className="border rounded text-center p-2">
                경로당 명
              </Col>
              <Col xs={4}>
                <Form.Control type="text" placeholder="경로당명 입력" />
              </Col>
            </Row>
          </Col>

          <Col xs={1} className="">
            <Button className="w-100" onClick={() => alert("검색 버튼")}>
              검색
            </Button>
          </Col>
        </Row>
      </Col>

      {/* 내용 */}
      <Col
        xs={12}
        className=""
        style={{
          height: "calc(100vh - 22rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row className="d-flex gap-4">
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Col>
              <span className="fs-4 fw-bold">| 종합 순위</span>
            </Col>
            <Col>
              <span className="">순위 데이터</span>
            </Col>
          </Col>
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Col>
              <span className="fs-4 fw-bold">| 점수 순위</span>
            </Col>
            <Col>
              <span className="">순위 데이터</span>
            </Col>
          </Col>
        </Row>

        <Row className="d-flex gap-4 mt-4">
          <Col
            className="p-3 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Col>
              <span className="fs-4 fw-bold">| 이용횟수 순위</span>
            </Col>
            <Col>
              <span className="">순위 데이터</span>
            </Col>
          </Col>
          {/* 빈공간 */}
          <Col></Col>
        </Row>

        <Row
          className="p-3 border rounded mt-4"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={12}>
            <span className="fs-4 fw-bold">| 전체 랭킹</span>
          </Col>
          <Col xs={12}>
            <span className="">전체 랭킹 페이지네이션</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SmartTableContent;
