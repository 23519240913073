import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PreviewWeatherImg from "../../assets/test/weather-preview.jpg";
import CustomModal from "../common/CustomModal";
import BusStopList from "./sub/BusStopList";
import {
  getBusStop,
  getCenterDetail,
  getCenterList,
  getCenters,
  getDistricts,
  getSeniorCenter,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";

const ConvenientFunctionManagement = () => {
  const [centerEditModalShow, setCenterEditModalShow] = React.useState(false);

  const [weatherFormData, setWeatherFormData] = useState({
    onOff: "on",
  });

  const [busFormData, setBusFormData] = useState({
    onOff: "on",
  });

  const handleBusSave = () => {
    const result = window.confirm("저장 하시겠습니까?");
    if (result) {
      // 확인 버튼 클릭 시 실행할 코드
      alert("확인을 클릭했습니다!");
    }
  };

  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
  });

  const [districtsList, setDistrictsList] = useState([]);
  const [seniorCenterList, setSeniorCenterList] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "district.name", title: "구" },
    { key: "name", title: "경로당명" },
    { key: "address", title: "경로당주소" },
    { key: "bus_station_1.name", title: "정류장 1" },
    { key: "bus_station_2.name", title: "정류장 2" },
    { key: "edit", title: "수정하기" },
  ];

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const getDistrictsList = () => {
    getDistricts()
      .then((res) => {
        setDistrictsList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSeniorCenterList = () => {
    getCenterList(currentPage, searchParam.local, searchParam.keyword)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictsList();
    getSeniorCenterList();
  }, []);

  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setSeniorCenterList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const handleSearch = (e) => {
    e.preventDefault();
    getSeniorCenterList();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getSeniorCenterList();
  };

  const getCenterDetailData = (id) => {
    getCenterDetail(id)
      .then((res) => {
        console.log(res.data);
        setBusDetailFormData({
          id: res.data.id,
          local: res.data.district.name,
          center: res.data.name,
          address: res.data.address,
          station1: {
            id: res.data.bus_station_1.id,
            name: res.data.bus_station_1.name,
            station_number: res.data.bus_station_1.station_number,
          },
          station2: {
            id: res.data.bus_station_2.id,
            name: res.data.bus_station_2.name,
            station_number: res.data.bus_station_2.station_number,
          },
        });
        setSearchInput1(res.data.bus_station_1.station_number);
        setSearchInput2(res.data.bus_station_2.station_number);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDetail = (data) => {
    console.log(data);
    setCenterEditModalShow(true);
    getCenterDetailData(data.id);
    /*setBusDetailFormData({
      id: data.id,
      local: data.district.name,
      center: data.name,
      address: data.address,
      station1: {
        id: data.bus_station_1.id,
        name: data.bus_station_1.name,
      },
      station2: {
        id: data.bus_station_2.id,
        name: data.bus_station_2.name,
      },
    });*/
  };

  const [busDetailFormData, setBusDetailFormData] = useState({
    id: "",
    local: "",
    center: "",
    address: "",
    station1: {
      id: "",
      name: "",
      station_number: "",
    },
    station2: {
      id: "",
      name: "",
      station_number: "",
    },
  });

  const [busStops, setBusStops] = useState({});

  const [searchInput1, setSearchInput1] = useState("");
  const [openBusStopList1, setOpenBusStopList1] = useState(false);
  const [searchInput2, setSearchInput2] = useState("");
  const [openBusStopList2, setOpenBusStopList2] = useState(false);

  const handleSelectBusStop1 = (id, name, station_number) => {
    // setSelectedBusStop1(busStop);
    setBusDetailFormData({
      ...busDetailFormData,
      station1: {
        id: id,
        name: name,
        station_number: station_number,
      },
    });
    setOpenBusStopList1(false);
  };

  const handleSelectBusStop2 = (id, name, station_number) => {
    // setSelectedBusStop2(busStop);
    setBusDetailFormData({
      ...busDetailFormData,
      station2: {
        id: id,
        name: name,
        station_number: station_number,
      },
    });
    setOpenBusStopList2(false);
  };

  const handleSearchBus1 = () => {
    setOpenBusStopList1(true);
    setOpenBusStopList2(false);
    getBusStopList1();
  };

  const handleSearchBus2 = () => {
    setOpenBusStopList1(false);
    setOpenBusStopList2(true);
    getBusStopList2();
  };

  useEffect(() => {
    if (searchInput1.length === 0) {
      setOpenBusStopList1(false);
    }
  }, [searchInput1]);

  useEffect(() => {
    if (searchInput2.length === 0) {
      setOpenBusStopList2(false);
    }
  }, [searchInput2]);

  const getBusStopList1 = () => {
    getBusStop(searchInput1)
      .then((res) => {
        console.log(res.data);
        setBusStops(res.data);
      })
      .catch((error) => {
        console.error(error);
        // 에러 문구 표기 필요
        setBusStops({});
      });
  };

  const getBusStopList2 = () => {
    getBusStop(searchInput2)
      .then((res) => {
        setBusStops(res.data);
      })
      .catch((error) => {
        console.error(error);
        setBusStops({});
      });
  };

  const renderEdit = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={8} className={"d-flex flex-column gap-3"}>
                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {busDetailFormData.local}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 명
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {busDetailFormData.center}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 주소
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {busDetailFormData.address}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        정류장 1 설정
                      </span>
                    </Col>
                    <Col xs={8}>
                      <Row className={"h-100"}>
                        <Col xs={9}>
                          <Form.Control
                            type="text"
                            placeholder="정류장 1명 입력"
                            className={"w-100 h-100"}
                            value={searchInput1}
                            onChange={(e) => setSearchInput1(e.target.value)}
                          />
                        </Col>
                        <Col xs={3}>
                          <Button
                            type={"button"}
                            className={"w-100 h-100 fw-bold "}
                            onClick={handleSearchBus1}
                          >
                            입력
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 p-2 rounded-3 text-center fs-5"
                        }
                      ></span>
                    </Col>
                    <Col xs={8}>
                      <Row className={"h-100"}>
                        <Col xs={12}>
                          <span className={"text-danger"}>
                            *정류장 번호(5자리)는 여러 지도 플랫폼에서 확인할 수
                            있습니다.
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 p-2 rounded-3 text-center fs-5"
                        }
                      >
                        &nbsp;
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {busDetailFormData.station1.station_number}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        정류장 2 설정
                      </span>
                    </Col>
                    <Col xs={8}>
                      <Row className={"h-100"}>
                        <Col xs={9}>
                          <Form.Control
                            type="text"
                            placeholder="정류장 2명 입력"
                            className={"w-100 h-100"}
                            value={searchInput2}
                            onChange={(e) => setSearchInput2(e.target.value)}
                          />
                        </Col>
                        <Col xs={3}>
                          <Button
                            type={"button"}
                            className={"w-100 h-100 fw-bold"}
                            onClick={() => {
                              handleSearchBus2();
                            }}
                          >
                            입력
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 p-2 rounded-3 text-center fs-5"
                        }
                      ></span>
                    </Col>
                    <Col xs={8}>
                      <Row className={"h-100"}>
                        <Col xs={12}>
                          <span className={"text-danger"}>
                            *정류장 번호(5자리)는 여러 지도 플랫폼에서 확인할 수
                            있습니다.
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={3} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 p-2 rounded-3 text-center fs-5"
                        }
                      >
                        &nbsp;
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {busDetailFormData.station2.station_number}
                      </span>
                    </Col>
                  </Row>
                </Col>

                {searchInput1.length !== 0 &&
                  openBusStopList1 &&
                  !openBusStopList2 && (
                    <Col xs={4} className={"border p-2 rounded-3 "}>
                      <BusStopList
                        busStops={busStops}
                        onSelectBusStop={handleSelectBusStop1}
                      />
                    </Col>
                  )}

                {searchInput2.length !== 0 &&
                  openBusStopList2 &&
                  !openBusStopList1 && (
                    <Col xs={4} className={"border p-2 rounded-3 "}>
                      <BusStopList
                        busStops={busStops}
                        onSelectBusStop={handleSelectBusStop2}
                      />
                    </Col>
                  )}
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center mt-4"}
          >
            <Button
              type={"button"}
              variant="primary"
              className={"rounded-5 px-5 fw-bold fs-5 text-white btn-send"}
              onClick={() => {
                console.log(busDetailFormData);
                const result = window.confirm("저장 하시겠습니까?");
                if (result) {
                  // 확인 버튼 클릭 시 실행할 코드
                  alert("확인을 클릭했습니다!");
                }
              }}
            >
              저장
            </Button>
            <Button
              type={"button"}
              variant="danger"
              className={"rounded-5 px-5 fw-bold fs-5"}
              onClick={() => {
                const result = window.confirm("삭제 하시겠습니까?");
                if (result) {
                  // 확인 버튼 클릭 시 실행할 코드
                  alert("확인을 클릭했습니다!");
                }
              }}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"gap-3 justify-content-between"}>
        <Col
          className={"border p-3 rounded-3"}
          style={{
            width: "49%",
            backgroundColor: "#1D222A",
            height: "calc(100vh - 12rem)",
          }}
        >
          <Form className="h-100">
            <Row className={"row-gap-5 h-100"}>
              <Col xs={12} className={"d-flex flex-column gap-3"}>
                <Row>
                  <Col xs={4} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 날씨 정보 안내 관리</span>
                  </Col>
                  <Col xs={6} className="d-flex gap-3">
                    {/*  input radio on/off */}
                    <Form.Check
                      type="radio"
                      label="켜기"
                      name="waetherOnOff"
                      id="waetherOn"
                      className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                    />
                    <Form.Check
                      type="radio"
                      label="끄기"
                      name="waetherOnOff"
                      className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                      id="waetherOff"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={3}>
                    <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5 mb-3"
                      }
                    >
                      미리보기 이미지
                    </span>
                  </Col>
                  <Col xs={12}>
                    <img
                      src={PreviewWeatherImg}
                      alt={"preview"}
                      className={"img-fluid"}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className={"text-center mt-auto pb-3"}>
                <Button
                  type={"button"}
                  variant={"success"}
                  onClick={() => alert("저장 버튼 클릭")}
                  className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                >
                  저장
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col
          className={"border p-3 rounded-3"}
          style={{
            width: "49%",
            backgroundColor: "#1D222A",
            height: "calc(100vh - 12rem)",
          }}
        >
          <Form className="h-100" onSubmit={handleSearch}>
            <Row className={"row-gap-5 h-100 justify-content-between"}>
              <Col xs={12} className={"d-flex flex-column gap-3"}>
                <Row className={"row-gap-3"}>
                  <Col xs={4} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 버스 정보 안내 관리</span>
                  </Col>
                  <Col xs={6} className="d-flex gap-3">
                    {/*  input radio on/off */}
                    <Form.Check
                      type="radio"
                      label="켜기"
                      name="busOnOff"
                      id="busOn"
                      className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                    />
                    <Form.Check
                      type="radio"
                      label="끄기"
                      name="busOnOff"
                      className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                      id="busOff"
                    />
                  </Col>

                  <Col xs={12}>
                    <Row>
                      <Col xs={3} className={"my-auto align-middle"}>
                        <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                        >
                          경로당 검색 필터
                        </span>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          className="h-100 fs-5"
                          aria-label="Default select example"
                          value={searchParam.local}
                          onChange={(e) =>
                            setSearchParam({
                              ...searchParam,
                              local: e.target.value,
                            })
                          }
                        >
                          <option value={""}>구</option>
                          {districtsList.map((district) => (
                            <option key={district.id} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          className="h-100 fs-5"
                          aria-label={"Default select example"}
                          value={searchParam.center}
                          onChange={handleCenterChange}
                        >
                          <option value={""}>경로당</option>
                          {seniorCenterList?.map((center, index) => (
                            <option key={index} value={center.id}>
                              {center.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={3}>
                        <Form.Control
                          type="text"
                          placeholder="검색어 입력"
                          className={"w-100 h-100 fs-5"}
                          value={searchParam.keyword}
                          onChange={handleKeywordChange}
                          disabled={searchParam.center !== ""}
                        />
                      </Col>
                      <Col xs={2}>
                        <Button
                          type={"button"}
                          className={"w-100 h-100 fw-bold fs-5"}
                          onClick={() => {
                            getSeniorCenterList();
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12}>
                    {/*<Button
                          type={"button"}
                          variant={"outline-secondary"}
                          onClick={() => setCenterEditModalShow(true)}
                      >
                        수정하기
                      </Button>*/}
                    {tableData ? (
                      <CustomTable
                        columns={columns}
                        tableData={tableData}
                        handleDetail={handleDetail}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      />
                    ) : (
                      <Col className="w-100 text-center my-5">
                        <span>검색 결과가 없습니다.</span>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className={"text-center mt-auto pb-3"}>
                <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                  onClick={() => handleBusSave()}
                >
                  저장
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <CustomModal
        show={centerEditModalShow}
        onHide={() => {
          setCenterEditModalShow(false);
          setBusDetailFormData({
            id: "",
            local: "",
            center: "",
            address: "",
            station1: {
              id: "",
              name: "",
            },
            station2: {
              id: "",
              name: "",
            },
          });
          setSearchInput1("");
          setSearchInput2("");
        }}
      >
        {renderEdit()}
      </CustomModal>
    </>
  );
};

export default ConvenientFunctionManagement;
