// charts/LineChart.jsx
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col } from "react-bootstrap";

const LineChart = ({
  title,
  xAxisCategories,
  series,
  yAxisTitle,
  backgroundImage,
  height,
}) => {
  const options = {
    chart: {
      type: "line",
      backgroundColor: "transparent", // 배경 투명
      height: height || "37%",
    },
    title: {
      text: title,
      style: { color: "#FFFFFF" }, // 제목 텍스트 색상
    },
    xAxis: {
      categories: xAxisCategories,
      labels: { style: { color: "#FFFFFF" } }, // X축 텍스트 색상
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: { color: "#FFFFFF" }, // Y축 제목 색상
      },
      labels: { style: { color: "#FFFFFF" } }, // Y축 레이블 색상
    },
    credits: {
      enabled: false, // Highcharts 로고 제거
    },
    legend: {
      itemStyle: { color: "#FFFFFF" }, // 범례 텍스트 색상
    },
    plotOptions: {
      series: {
        borderWidth: 0, // 막대 테두리 제거
      },
    },
    series,
  };

  return (
    <Col
      className="p-3 border rounded"
      style={{
        backgroundColor: "#1D222A",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Col>
  );
};

export default LineChart;
