import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";

const CompetitionHistory = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  // 모달창 내용
  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">| 대회 랭킹 히스토리</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} className={"my-auto align-middle"}>
                      관련 데이터
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row className="justify-content-between mb-2">
            <Col xs={3}>
              <span className="fs-4 fw-bold">| 대회 검색</span>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={5} className="">
                  <Form.Select>
                    <option value={""}>대회 회차 선택</option>
                    <option value={""}>1</option>
                    <option value={""}>2</option>
                  </Form.Select>
                </Col>
                <Col xs={2} className="border rounded text-center p-2">
                  대회명
                </Col>
                <Col xs={5}>
                  <Form.Control type="text" placeholder="대회명 입력" />
                </Col>
              </Row>
            </Col>

            <Col xs={7} className="d-flex gap-2">
              <Col className="border rounded text-center p-2">기간 설정</Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>년 리스트</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>월 리스트</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>일 리스트</option>
                </Form.Select>
              </Col>
              <Col
                className="border rounded text-center p-2"
                style={{ maxWidth: "2rem" }}
              >
                ~
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>년 리스트</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>월 리스트</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>일 리스트</option>
                </Form.Select>
              </Col>
            </Col>

            <Col xs={1} className="">
              <Button className="w-100" onClick={() => alert("검색 버튼")}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={2} className="">
              <span className="fs-4 fw-bold">| 현재 진행중인 대회</span>
            </Col>
            <Col xs={10} className="">
              <p>페이지네이션</p>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} className="">
              <span className="fs-4 fw-bold">| 대회 히스토리</span>
            </Col>
            <Col xs={12} className="">
              <p>페이지네이션</p>
              {/* 모달창 버튼 */}
              <Button onClick={() => setShowDetailModal(true)}>상세보기</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default CompetitionHistory;
