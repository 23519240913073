import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import {
  deleteNoticeDetailTemplate,
  getNoticeCategory,
  getNoticeDetailTemplate,
  getNoticeTemplate,
  postNoticeTemplate,
  putNoticeTemplate,
} from "../../api/apiClient";
import PaginatedTable from "../table/PaginatedTable";
import UseSearchFilter from "../../hook/UseSearchFilter";
import CustomTable from "../table/CustomTable";

const TemplateSetting = () => {
  const [noticeCategory, setNoticeCategory] = useState([]); // 템플릿 카테고리 api
  const [selectedTemplate, setSelectedTemplate] = useState(null); // 선택한 템플릿 내용
  const [showTemplateEditor, setShowTemplateEditor] = useState(false); // 템플릿 활성화
  const [showDetailBtn, setShowDetailBtn] = useState(false); // 상세보기 버튼
  const [isEditMode, setIsEditMode] = useState(false); // 수정 모드 활성화

  const [templateFormData, setTemplateFromData] = useState({
    title: "",
    category: "",
    content: "",
  });

  const [searchParam, setSearchParam] = useState({
    category: "",
    title: "",
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    { key: "id", title: "번호" },
    {
      key: "category.name",
      title: "카테고리",
      render: (_, row) => <span>{row.category.name}</span>,
    },
    {
      key: "title",
      title: "제목",
    },
    {
      key: "actions",
      title: "상세보기",
      render: (_, row) => (
        <Button onClick={() => handleDetail(row)}>상세보기</Button>
      ),
    },
  ];

  // 공지사항 카테고리
  const getNoticeCategoryList = () => {
    getNoticeCategory()
      .then((res) => setNoticeCategory(res.data))
      .catch((err) => console.error(err));
  };

  // 공지사항 템플릿 리스트 api
  const getNoticeTemplateList = () => {
    getNoticeTemplate(currentPage, searchParam.title, searchParam.category)
      .then((res) => {
        console.log("getTemplateList", res.data);
        setTableData(res.data);
      })
      .catch((error) => console.error(error));
  };

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNoticeTemplateList(page);
  };

  useEffect(() => {
    getNoticeCategoryList();
    getNoticeTemplateList();
  }, []);

  // 등록하기 버튼
  const handleActiveTemplate = () => {
    setSelectedTemplate(null); // 템플릿 초기화
    setShowTemplateEditor(true);
    setShowDetailBtn(false);
    setIsEditMode(false); // 수정 모드 비활성화

    setTemplateFromData({
      title: "",
      category: "",
      content: "",
    });
  };

  // 상세보기 버튼
  const handleDetail = (template) => {
    getNoticeDetailTemplate(template.id)
      .then((res) => {
        console.log("id", res.data);
        setTemplateFromData(res.data);
      })
      .catch((err) => console.error(err));

    setShowDetailBtn(true);
    setShowTemplateEditor(false);
    setIsEditMode(false); // 수정 모드 비활성화
  };

  // 등록 저장 버튼
  const handleSave = async (e) => {
    e.preventDefault();

    if (
      !templateFormData.title ||
      !templateFormData.content ||
      !templateFormData.category
    ) {
      alert("모든 필드를 입력해주세요.");
      return;
    }

    const userConfirmed = window.confirm("저장 하시겠습니까?");
    if (!userConfirmed) {
      return;
    }

    const formData = new FormData();
    formData.append("title", templateFormData.title);
    formData.append("content", templateFormData.content);
    formData.append("category", templateFormData.category);

    console.log("temForm", templateFormData);

    postNoticeTemplate(templateFormData)
      .then((res) => {
        console.log(res);
        getNoticeTemplateList();
        setShowTemplateEditor(false); // 등록 모드 종료
        setShowDetailBtn(false);
        setIsEditMode(false); // 수정 모드 종료
      })
      .catch((err) => {
        console.error(err);
        alert("발송 실패");
      });
  };

  // 수정 버튼
  const handleEdit = () => {
    setIsEditMode(true); // 수정 모드 활성화
  };

  // 수정 후 저장 버튼
  const handleEditSave = () => {
    const userConfirmed = window.confirm("수정 하시겠습니까?");
    if (!userConfirmed) {
      return;
    }

    const updatedData = {
      title: templateFormData.title,
      category: templateFormData.category?.id,
      content: templateFormData.content,
    };

    console.log("updatedData", updatedData);

    putNoticeTemplate(templateFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료 : ", res.data);
        getNoticeTemplateList();
        setShowTemplateEditor(false); // 등록 모드 종료
        setIsEditMode(false); // 수정 모드 종료
      })
      .catch((err) => console.error(err));

    setIsEditMode(false);
  };

  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteNoticeDetailTemplate(templateFormData.id)
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        getNoticeTemplateList();
        setShowTemplateEditor(false);
        setShowDetailBtn(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 초기화 버튼
  const handleReset = () => {
    setTemplateFromData({
      title: "",
      category: "",
      content: "",
    }); // templateFormData 초기화
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    getNoticeTemplateList();
    // setSearchTitle(pendingSearchTitle);
    // setPendingSearchTitle(""); // 입력 필드 초기화
  };

  return (
    <Form onSubmit={handleSubmit} className="">
      <Row className="gap-3">
        <Col
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 13rem)",
          }}
        >
          {/* 검색 */}
          <Row>
            <Col xs={showTemplateEditor || showDetailBtn ? 4 : 8}>
              <span className="fs-4 fw-bold">| 템플릿 리스트</span>
            </Col>
            <Col
              xs={showTemplateEditor || showDetailBtn ? 8 : 4}
              className="d-flex gap-2"
            >
              <Col xs={4}>
                <Form.Select
                  className="primary-select fw-bold fs-5"
                  value={searchParam.category}
                  onChange={(e) =>
                    setSearchParam({ ...searchParam, category: e.target.value })
                  }
                >
                  <option defaultChecked value={""}>
                    카테고리
                  </option>
                  {noticeCategory?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={6}>
                <Form.Control
                  className="fs-5"
                  type="text"
                  placeholder="템플릿 제목 입력"
                  value={searchParam.title}
                  onChange={(e) =>
                    setSearchParam({ ...searchParam, title: e.target.value })
                  }
                />
              </Col>
              <Col xs={2} className="pe-3">
                <Button className="w-100 fs-5 fw-bold" type="submit">
                  검색
                </Button>
              </Col>
            </Col>
          </Row>
          <br />
          {/* 리스트 */}
          {tableData ? (
            <CustomTable
              columns={columns}
              tableData={tableData}
              handleDetail={handleDetail}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          ) : (
            <Col className="w-100 text-center my-5">
              <span>검색 결과가 없습니다.</span>
            </Col>
          )}

          {/* {tableData ? (
            <PaginatedTable columns={columns} data={tableData} pageSize={10} />
          ) : (
            <Col className="w-100 text-center my-5">
              <span>검색 결과가 없습니다.</span>
            </Col>
          )} */}

          {/* 등록 버튼 */}
          <Col className="text-center mt-4">
            <Button
              className={"rounded-5 px-5 fw-bold fs-5 btn-outline-primary"}
              onClick={handleActiveTemplate}
            >
              등록
            </Button>
          </Col>
        </Col>

        {/* 템플릿 등록/수정 */}
        {(showTemplateEditor || showDetailBtn) && (
          <Col
            className="px-5 py-5 border rounded"
            style={{ backgroundColor: "#1D222A" }}
          >
            <Row>
              <Col xs={9}>
                <Form.Control
                  className="fs-5"
                  type="text"
                  placeholder="템플릿 제목을 입력해주세요"
                  value={templateFormData.title || ""}
                  // onChange={(e) => updateField("title", e.target.value)}
                  onChange={(e) => {
                    setTemplateFromData({
                      ...templateFormData,
                      title: e.target.value,
                    });
                  }}
                  disabled={!isEditMode && !showTemplateEditor} // 수정 모드와 등록 모드에서만 활성화
                />
              </Col>
              <Col xs={3}>
                <Form.Select
                  className="fs-5 fw-bold primary-select"
                  value={
                    !showTemplateEditor
                      ? templateFormData.category?.id
                      : templateFormData.category
                  }
                  // onChange={(e) => updateField("category", e.target.value)}
                  onChange={(e) => {
                    setTemplateFromData({
                      ...templateFormData,
                      category: e.target.value,
                    });
                  }}
                  disabled={!isEditMode && !showTemplateEditor} // 수정 모드와 등록 모드에서만 활성화
                >
                  <option value={""}>카테고리</option>
                  {noticeCategory?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Col className="mt-3">
              <Form.Control
                className="fs-5"
                as="textarea"
                rows={20}
                placeholder="템플릿 내용을 입력해주세요"
                value={templateFormData.content || ""}
                // onChange={(e) => updateField("content", e.target.value)}
                onChange={(e) => {
                  setTemplateFromData({
                    ...templateFormData,
                    content: e.target.value,
                  });
                }}
                disabled={!isEditMode && !showTemplateEditor} // 수정 모드와 등록 모드에서만 활성화
              />
            </Col>
            <br />

            {/* 버튼 */}
            <Col className="d-flex justify-content-center gap-2">
              {showTemplateEditor ? (
                <>
                  <Button
                    className={
                      "rounded-5 px-5 fw-bold fs-5 text-white btn-send"
                    }
                    onClick={handleSave}
                  >
                    저장
                  </Button>
                  <Button
                    className={"rounded-5 px-5 fw-bold fs-5"}
                    variant="secondary"
                    onClick={handleReset}
                  >
                    초기화
                  </Button>
                </>
              ) : isEditMode ? (
                <>
                  <Button
                    className={
                      "rounded-5 px-5 fw-bold fs-5 text-white btn-send"
                    }
                    onClick={handleEditSave}
                  >
                    저장
                  </Button>
                  <Button
                    className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                    variant="danger"
                    onClick={handleDelete}
                  >
                    삭제
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={"rounded-5 px-5 fw-bold fs-5"}
                    variant="secondary"
                    onClick={handleEdit}
                  >
                    수정
                  </Button>
                  <Button
                    className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                    variant="danger"
                    onClick={handleDelete}
                  >
                    삭제
                  </Button>
                </>
              )}
            </Col>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default TemplateSetting;
