import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";
import LineChart from "../chart/LineChart";
import PieChart from "../chart/PieChart";
import Map from "../../assets/test/sample-map.png";

const LifeStyleUsage = () => {
  const columnChartData = {
    xAxisCategories: ["a", "b", "c", "d"],
    series: [
      {
        type: "column",
        name: "기기구분",
        data: [
          { y: 70, color: "#2ECF8F" },
          { y: 85, color: "#FB72F4" },
          { y: 90, color: "#FF8C18" },
          { y: 80, color: "#9E54F3" },
        ],
      },
    ],
    yAxisTitle: "횟수범위숫자",
  };

  const lineChartData = {
    xAxisCategories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    series: [
      {
        type: "line",
        name: "이벤트 A 발생 현황",
        data: [10, 5, 7, 2, 10, 6, 4],
        color: "#2ECF8F",
      },
      {
        type: "line",
        name: "이벤트 B 발생 현황",
        data: [2, 4, 6, 3, 8, 5, 7],
        color: "#FB72F4",
      },
      {
        type: "line",
        name: "이벤트 C 발생 현황",
        data: [2, 3, 1, 2, 2, 1, 0],
        color: "#FF8C18",
      },
      {
        type: "line",
        name: "이벤트 D 발생 현황",
        data: [1, 6, 4, 8, 3, 7, 5],
        color: "#9E54F3",
      },
    ],
    yAxisTitle: "횟수범위숫자",
  };

  const pieChartData = {
    title: "성별",
    series: [
      {
        type: "pie",
        name: "Share",
        data: [
          {
            name: "a",
            y: 70,
            color: "#2ECF8F",
          },
          {
            name: "b",
            y: 10,
            color: "#FB72F4",
          },
          {
            name: "c",
            y: 15,
            color: "#FF8C18",
          },
          {
            name: "d",
            y: 5,
            color: "#9E54F3",
          },
        ],
      },
    ],
    innerSize: "0%",
  };

  return (
    <Row className={"row-gap-4"}>
      {/* 검색 */}
      <Col
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="justify-content-between">
          <Col xs={7}>
            <Row className="row-gap-2">
              <Col xs={2} className="pe-0">
                <span className="fs-4 fw-bold">| 검색 일자</span>
              </Col>
              <Col xs={10} className="d-flex gap-2">
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>년 리스트</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>월 리스트</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>일 리스트</option>
                  </Form.Select>
                </Col>
                <Col
                  className="border rounded text-center p-2"
                  style={{ maxWidth: "2rem" }}
                >
                  ~
                </Col>
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>년 리스트</option>
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>월 리스트</option>
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>일 리스트</option>
                  </Form.Select>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={4}>
            <Row className="row-gap-2">
              <Col xs={2} className="pe-0">
                <Form.Select>
                  <option value={""}>구</option>
                  <option value={""}>구1</option>
                  <option value={""}>구2</option>
                </Form.Select>
              </Col>
              <Col xs={3}>
                <Form.Select>
                  <option value={""}>경로당</option>
                  <option value={""}>경로당1</option>
                  <option value={""}>경로당2</option>
                </Form.Select>
              </Col>
              <Col xs={2} className="border rounded text-center p-2">
                경로당 명
              </Col>
              <Col xs={4}>
                <Form.Control type="text" placeholder="경로당명 입력" />
              </Col>
            </Row>
          </Col>

          <Col xs={1} className="d-flex gap-2">
            <Button className="w-100" onClick={() => alert("검색 버튼")}>
              검색
            </Button>
            <Button className="w-100" onClick={() => alert("PDF 버튼")}>
              PDF
            </Button>
          </Col>
        </Row>
      </Col>

      {/* 테이블 */}
      <Col
        xs={12}
        className="p-3 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 19rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row>
          <Col className="w-100 mb-1">
            <span className="fs-4 fw-bold w-100">| 날짜별 이용시간 추이</span>
          </Col>
          <Col xs={12} className="">
            <LineChart {...lineChartData} />
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 기기별 이용시간</span>
            </Col>
            <Col className="pe-0">
              <BasicColumnChart {...columnChartData} />
            </Col>
          </Col>

          <Col xs={6}>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 이용 시간 남여별 현황
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              <PieChart {...pieChartData} />
              <PieChart {...pieChartData} />
            </Col>
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 워킹머신 이용시간</span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              <img src={Map} alt="sample-map" className="p-5" />
            </Col>
          </Col>

          <Col xs={6}>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 스마트테이블 콘텐츠 이용시간
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              <img src={Map} alt="sample-map" className="p-5" />
            </Col>
          </Col>
        </Row>

        <Row className="">
          <Col xs={6} className="h-100">
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">
                | 스마트테이블 키오스크 체험 이용시간
              </span>
            </Col>
            <Col
              className="border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D222A" }}
            >
              <img src={Map} alt="sample-map" className="p-5" />
            </Col>
          </Col>
        </Row>

        <Row>
          <Col className="w-100 mt-3 mb-1">
            <span className="fs-4 fw-bold w-100">| 이용 시간 현황</span>
          </Col>
          <p>페이지네이션</p>
        </Row>
      </Col>
    </Row>
  );
};

export default LifeStyleUsage;
