// charts/BasicBarChart.jsx
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Col} from "react-bootstrap";

const BasicBarChart = ({
  title,
  xAxisCategories,
  series,
  yAxisTitle,
  backgroundImage,
  height,
}) => {
  const options = {
    chart: {
      type: "bar",
      backgroundColor: "transparent", // 배경 투명
      borderWidth: 0, // 차트 테두리 제거
      height: height || "37%",
    },
    title: {
      text: title,
      style: {color: "#FFFFFF"},
    },
    xAxis: {
      categories: xAxisCategories,
      labels: {
        style: {color: "#FFFFFF"},
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        style: {color: "#FFFFFF"},
      },
      labels: {
        style: {
          color: "#FFFFFF"
        },
        overflow: 'justify',
        gridLineWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    plotOptions: {
      bar: {
        groupPadding: 0.05,
        pointPadding: 0.05,
        pointWidth: 30,
      },
      series: {
        borderWidth: 0,
      },
    },
    series,
  };

  return (
    <Col
      className="p-3 border rounded"
      style={{
        backgroundColor: "#1D222A",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Col>
  );
};

export default BasicBarChart;
