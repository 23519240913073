import React, { useEffect, useState } from "react";
import { Table as BootstrapTable, Button, Col } from "react-bootstrap";
import Pagination from "./Pagination"; // Adjust the import path as necessary
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";

const CustomTable = ({
  columns,
  tableData,
  handleDetail = undefined,
  currentPage,
  handlePageChange,
}) => {
  const [finalColumns, setFinalColumns] = useState([]);
  const [activeRow, setActiveRow] = useState(null);

  const flattenObject = (obj, parentKey = "", res = {}) => {
    for (let key in obj) {
      const propName = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  useEffect(() => {
    setFinalColumns(columns);
  }, [columns]);

  useEffect(() => {
    console.log("tableData", tableData);
  }, [tableData]);

  const handleRowClick = (row) => {
    setActiveRow(row.id === activeRow ? null : row.id);
    if (row.id !== activeRow) {
      handleDetail(row); // 선택된 경우 상세보기 호출
    }
  };

  return (
    <>
      <BootstrapTable striped hover>
        <thead>
          <tr>
            {finalColumns.map((col) => (
              <th
                key={col.key}
                className={`fs-5 ${
                  ["상세보기", "수정하기", "선택하기"].includes(col.title)
                    ? "text-center"
                    : ""
                }`}
                style={{
                  width:
                    ["상세보기", "수정하기", "선택하기"].includes(col.title) &&
                    "8rem",
                  minWidth: col.title === "번호" ? "3.5rem" : "auto",
                }}
              >
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.results?.map((row, index) => {
            const flattenedRow = flattenObject(row);
            return (
              <tr key={index}>
                {finalColumns.map((col) => (
                  <td
                    key={col.key}
                    className="fs-5"
                    style={{
                      maxWidth: "10rem", // 최대 너비를 설정
                      whiteSpace: "nowrap", // 텍스트 줄바꿈 방지
                      overflow: "hidden", // 넘치는 텍스트 숨김
                      textOverflow: "ellipsis", // 말줄임표 처리
                    }}
                  >
                    {["actions", "edit", "select"].includes(col.key) ? (
                      <Col className="text-end" style={{ width: "7rem" }}>
                        <Button
                          className={`rounded-5 px-4 fw-bold fs-5 btn-detail ${
                            activeRow === row.id ? "active" : ""
                          }`}
                          onClick={() => handleRowClick(row)}
                        >
                          {col.key === "actions" && "상세보기"}
                          {col.key === "edit" && "수정하기"}
                          {col.key === "select" && "선택하기"}
                        </Button>
                      </Col>
                    ) : col.key === "is_active" ? (
                      <img
                        src={flattenedRow[col.key] ? On : Off}
                        alt={flattenedRow[col.key] ? "Active" : "Inactive"}
                      />
                    ) : col.key === "recipients" ? (
                      <>
                        {row.recipients.map((recipient, index) => (
                          <span key={index}>
                            {recipient}
                            {index < row.recipients.length - 1 && ", "}
                          </span>
                        ))}
                      </>
                    ) : flattenedRow[col.key] !== undefined ? (
                      typeof flattenedRow[col.key] === "object" ? (
                        JSON.stringify(flattenedRow[col.key])
                      ) : (
                        flattenedRow[col.key]
                      )
                    ) : (
                      ""
                    )}
                  </td>
                ))}
              </tr>
            );
          })}

          {tableData?.results?.length === 0 && (
            <tr>
              <td
                colSpan={finalColumns.length}
                className={"text-center fs-5 py-3"}
              >
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </BootstrapTable>
      <Pagination
        propCurPage={currentPage}
        propTotal={tableData?.count || 0}
        propSetNewPage={handlePageChange}
      />
    </>
  );
};

export default CustomTable;
