import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import MonitoringImg from "../../assets/landing/monitoring.png";
import Landing from "../../pages/Landing";
import { getMainMenu, putMainMenu } from "../../api/apiClient";

const Menu = () => {
  const STATIC_FILE_URL = process.env.REACT_APP_STATIC_FILE_URL;

  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  const [imagePreviews, setImagePreviews] = useState(Array(8).fill(null)); // Assuming 8 menu items
  const fileInputRefs = useRef([]);

  const [menuData, setMenuData] = useState(
    Array(8).fill({
      order: "",
      display_name: "",
      thumbnail: null,
    })
  );
  const [initialMenuList, setInitialMenuList] = useState([]);

  // 메뉴 리스트 가져오기
  const getMainMenuList = () => {
    getMainMenu()
      .then((res) => {
        console.log("mainMenu", res.data);
        setInitialMenuList(res.data); // 초기 데이터 저장
        setMenuData(res.data.map((menu) => ({ ...menu }))); // 상태 업데이트
        setImagePreviews(res.data.map((menu) => menu.thumbnail || null)); // 이미지 초기화
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getMainMenuList();
  }, []);

  // 입력값 변경 핸들러
  const handleInputChange = (index, field, value) => {
    const updatedMenus = [...menuData];

    if (field === "order") {
      const newOrder = parseInt(value, 10); // 선택된 order 값

      // 다른 항목에서 동일한 order 값을 제거
      updatedMenus.forEach((menu, idx) => {
        if (menu.order === newOrder && idx !== index) {
          updatedMenus[idx].order = ""; // 중복된 order 값을 비움
        }
      });

      // 현재 항목의 order 값 업데이트
      updatedMenus[index].order = newOrder || "";
    } else {
      // 일반 필드 업데이트
      updatedMenus[index][field] = value;
    }

    setMenuData(updatedMenus); // 상태 업데이트
  };

  // 이미지 변경
  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    const newImagePreviews = [...imagePreviews];
    const updatedMenus = [...menuData];

    if (file) {
      newImagePreviews[index] = URL.createObjectURL(file); // 미리보기 설정
      updatedMenus[index] = { ...updatedMenus[index], thumbnail: file }; // 파일 저장
    } else {
      newImagePreviews[index] = null;
      updatedMenus[index] = { ...updatedMenus[index], thumbnail: null };
    }

    setImagePreviews(newImagePreviews);
    setMenuData(updatedMenus);
  };

  // 초기화 버튼
  const handleReset = () => {
    setMenuData(initialMenuList.map((menu) => ({ ...menu }))); // 초기 데이터 복원
    setImagePreviews(initialMenuList.map((menu) => menu.thumbnail || null)); // 이미지 복원

    // 모든 파일 입력 필드 초기화
    fileInputRefs.current.forEach((input) => {
      if (input) input.value = null;
    });
  };

  const hasEmptyOrder = menuData.some((menu) => menu.order === "");

  // 저장 버튼 (수정)
  const handleSave = () => {
    if (hasEmptyOrder) {
      alert("모든 순서를 선택해주세요.");
      return;
    }

    if (!window.confirm("저장 하시겠습니까?")) return;

    const updatedData = menuData.map((menu) => ({
      id: menu.id,
      order: menu.order,
      display_name: menu.display_name,
      thumbnail:
        menu.thumbnail instanceof File ? menu.thumbnail.name : menu.thumbnail,
    }));

    console.log("수정 데이터:", updatedData);

    putMainMenu(updatedData)
      .then((res) => {
        console.log(`수정 완료:`, res.data);
      })
      .catch((err) => {
        console.error(`수정 실패:`, err);
      });
  };

  // menuData를 order 기준으로 정렬
  const sortedMenuData = [...menuData].sort((a, b) => a.order - b.order || 0);

  const sortedPreviews = sortedMenuData.map((menu) =>
    menu.thumbnail instanceof File
      ? URL.createObjectURL(menu.thumbnail)
      : `${STATIC_FILE_URL}${menu.thumbnail}`
  );

  const preview = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row className="row-gap-4 gap-4">
              {sortedMenuData.map((menu, idx) => (
                <Col
                  key={idx}
                  className="px-4 border rounded-4 text-center p-3"
                  style={{
                    backgroundColor: "#1D222A",
                  }}
                >
                  <img
                    className="d-block mx-auto"
                    src={sortedPreviews[idx]}
                    alt={menu.order}
                    style={{
                      width: "10rem",
                      height: "10rem",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                  <h2 className="fs-4 card-name mt-4 fw-bold">
                    {menu.display_name}
                  </h2>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {/* <Col
          xs={12}
          style={{
            minWidth: "1920px",
            minHeight: "580px",
            maxHeight: "720px",
            transform: "scale(0.5)", // 원하는 스케일 값
            transformOrigin: "top left", // 스케일 축 기준
            pointerEvents: "none", // 부모 요소의 클릭 이벤트 차단
          }}
        >
          <Landing />
        </Col> */}
      </>
    );
  };

  const categoryPreview = () => {
    return <>header</>;
  };

  return (
    <>
      <Row
        className={"gap-3"}
        style={{
          height: "calc(100vh - 13rem)",
        }}
      >
        {/* 메인화면 수정하기 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form className="h-100">
            <Row className="row-gap-3 h-100">
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 메인화면 수정하기</span>
              </Col>
              <Col xs={12} className="px-5">
                <Row>
                  {menuData.map((menu, index) => (
                    <Col key={index}>
                      <Row>
                        {/* 순서 선택 */}
                        <Col xs={12} className="mb-2">
                          <Form.Select
                            className="fs-5"
                            value={menu.order || ""}
                            onChange={(e) =>
                              handleInputChange(index, "order", e.target.value)
                            }
                          >
                            {/* 기본 "순서" 옵션 */}
                            <option value="">순서</option>
                            {/* 순서 목록 생성 */}
                            {Array.from({ length: menuData.length }).map(
                              (_, idx) => {
                                const optionValue = idx + 1; // 1부터 시작하는 순서 값
                                return (
                                  <option key={optionValue} value={optionValue}>
                                    {optionValue}
                                  </option>
                                );
                              }
                            )}
                          </Form.Select>
                        </Col>

                        {/* 이미지 업로드 */}
                        <Col xs={12} className="mb-2">
                          <Form.Control
                            className="fs-5"
                            type="file"
                            ref={(el) => (fileInputRefs.current[index] = el)}
                            onChange={(e) => handleImageChange(index, e)}
                            accept="image/*"
                          />
                          <img
                            src={
                              menu.thumbnail instanceof File
                                ? imagePreviews[index] // 업로드된 파일 미리보기
                                : `${STATIC_FILE_URL}${menu.thumbnail}` // 기존 URL
                            }
                            alt={`Image Preview ${index}`}
                            className="p-4"
                            style={{
                              width: "10rem",
                              height: "10rem",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Col>

                        {/* 메뉴 이름 수정 */}
                        <Col xs={12}>
                          <Form.Control
                            className="fs-5"
                            type="text"
                            placeholder="메뉴 수정"
                            value={menu.display_name || ""}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "display_name",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>

              {/* 버튼 그룹 */}
              <Col
                xs={12}
                className="d-flex justify-content-center gap-3 mt-auto"
              >
                <Button
                  type="button"
                  variant="secondary"
                  className="rounded-5 px-5 fw-bold fs-5"
                  onClick={() => {
                    if (hasEmptyOrder) {
                      alert("모든 순서를 선택해주세요.");
                      return;
                    }
                    setPreviewModalShow(true);
                  }}
                >
                  미리보기
                </Button>

                <Button
                  type="button"
                  variant="secondary"
                  className="rounded-5 px-5 fw-bold fs-5"
                  onClick={handleReset}
                >
                  초기화
                </Button>

                <Button
                  type="button"
                  className="rounded-5 px-5 fw-bold fs-5 text-white btn-send"
                  onClick={handleSave}
                >
                  저장
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* 세부 카테고리 수정하기 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form className="h-100">
            <Row className={"row-gap-3 h-100"}>
              <Col xs={12}>
                <Row>
                  <Col xs={2}>
                    <span className="fs-4 fw-bold">
                      | 세부 카테고리 수정하기
                    </span>
                  </Col>
                  <Col xs={2}>
                    <Form.Select className={"fs-5"}>
                      <option>메인 카테고리</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col xs={8} className="mx-auto">
                <Row className={"row-gap-3 "}>
                  {Array.from({ length: 8 }).map((_, index) => (
                    <Col xs={6} key={index}>
                      <Row>
                        <Col xs={4}>
                          <Form.Select className="fs-5">
                            <option>순서</option>
                          </Form.Select>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            className="fs-5"
                            type={"text"}
                            placeholder={"세부 카테고리 명"}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col
                xs={12}
                className={"d-flex justify-content-center gap-3 mt-auto"}
                style={{
                  height: "3rem",
                }}
              >
                <Button
                  type={"button"}
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => {
                    setCategoryModalShow(true);
                  }}
                >
                  미리보기
                </Button>

                <Button
                  type={"button"}
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => {}}
                >
                  초기화
                </Button>

                <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5 text-white btn-send"}
                  onClick={() => {}}
                >
                  저장
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <CustomModal
        show={previewModalShow}
        onHide={() => setPreviewModalShow(false)}
        onConfirm={() => alert("미리보기")}
      >
        {preview()}
      </CustomModal>
      <CustomModal
        show={categoryModalShow}
        onHide={() => setCategoryModalShow(false)}
        onConfirm={() => alert("미리보기")}
      >
        {categoryPreview()}
      </CustomModal>
    </>
  );
};

export default Menu;
