import React, { useState } from "react";
import Frame from "../components/common/Frame";
import CompetitionData from "../components/lifestyle/CompetitionData";
import CompetitionHistory from "../components/lifestyle/CompetitionHistory";
import BattleData from "../components/lifestyle/BattleData";
import CourseRanking from "../components/lifestyle/CourseRanking";
import SmartTableContent from "../components/lifestyle/SmartTableContent";
import LifeStyleDeviceStatus from "../components/lifestyle/LifeStyleDeviceStatus";
import LifeStyleUsage from "../components/lifestyle/LifeStyleUsage";

const Lifestyle = () => {
  const subMenu = [
    {
      title: "워킹머신 대회 데이터관리",
      content: <CompetitionData />,
    },
    {
      title: "워킹머신 대회 히스토리 확인",
      content: <CompetitionHistory />,
    },
    {
      title: "워킹머신 대전 데이터관리",
      content: <BattleData />,
    },
    {
      title: "워킹머신 코스별 랭킹",
      content: <CourseRanking />,
    },
    {
      title: "스마트테이블 콘텐츠별 랭킹",
      content: <SmartTableContent />,
    },
    {
      title: "생활케어 기기 동작 현황",
      content: <LifeStyleDeviceStatus />,
    },
    { title: "이용량", content: <LifeStyleUsage /> },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"생활케어 시스템 운영 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Lifestyle;
