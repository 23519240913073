import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";

const HelpDeskStatus = () => {
  const [showDetail01, setShowDetail01] = useState(false);
  const [showDetail02, setShowDetail02] = useState(false);
  const [searchParam, setSearchParam] = useState({
    startDate: { year: "", month: "", day: "" },
    endDate: { year: "", month: "", day: "" },
  });

  const columns = [
    { key: "id", title: "번호" },
    { key: "date", title: "접수일자" },
    { key: "location", title: "구" },
    { key: "center", title: "경로당명" },
    { key: "category", title: "문의카테고리" },
    { key: "title", title: "제목" },
    {
      key: "actions",
      title: "상세보기",
      render: (_, row) => (
        <Button
          className={"rounded-5 px-5 fw-bold fs-5 btn-outline-primary"}
          onClick={() => setShowDetail01(true)}
          style={{ borderColor: "#fff", color: "#fff" }}
        >
          상세보기
        </Button>
      ),
    },
  ];

  const dummyData = [
    {
      id: 1,
      date: "2023-12-01",
      location: "남동구",
      center: "A 경로당",
      category: "기기 문제",
      title: "셋톱박스 연결 불량",
    },
    {
      id: 2,
      date: "2023-12-02",
      location: "연수구",
      center: "B 경로당",
      category: "사용 방법 문의",
      title: "리모컨 작동법",
    },
    {
      id: 3,
      date: "2023-12-03",
      location: "부평구",
      center: "C 경로당",
      category: "기기 문제",
      title: "화면 출력 이상",
    },
    {
      id: 4,
      date: "2023-12-04",
      location: "미추홀구",
      center: "D 경로당",
      category: "기타",
      title: "기타 문의 사항",
    },
  ];

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("search");

    setSearchParam({
      startDate: { year: "", month: "", day: "" },
      endDate: { year: "", month: "", day: "" },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className={"row-gap-3 m-0"}>
        {/* 경로당 검색 */}
        <Col
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={12} className="mb-2">
            <span className="fs-4 fw-bold">| 접수일자 검색</span>
          </Col>
          <Row className="justify-content-between">
            <Col xs={8}>
              <Row className="row-gap-2">
                <Col xs={10} className="d-flex gap-2">
                  <Col>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="Start Year"
                      value={searchParam.startDate.year}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          startDate: {
                            ...searchParam.startDate,
                            year: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={""}>년 리스트</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      className="h-100 fs-5"
                      a
                      aria-label="Start Month"
                      value={searchParam.startDate.month}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          startDate: {
                            ...searchParam.startDate,
                            month: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={""}>월 리스트</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="Start Day"
                      value={searchParam.startDate.day}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          startDate: {
                            ...searchParam.startDate,
                            day: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={""}>일 리스트</option>
                    </Form.Select>
                  </Col>
                  <Col
                    className="border rounded text-center p-2 fs-5"
                    style={{ maxWidth: "2rem" }}
                  >
                    ~
                  </Col>
                  <Col>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="End Year"
                      value={searchParam.endDate.year}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          endDate: {
                            ...searchParam.endDate,
                            year: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={""}>년 리스트</option>
                    </Form.Select>
                  </Col>

                  <Col>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="End Month"
                      value={searchParam.endDate.month}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          endDate: {
                            ...searchParam.endDate,
                            month: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={""}>월 리스트</option>
                    </Form.Select>
                  </Col>

                  <Col>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="End Day"
                      value={searchParam.endDate.day}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          endDate: {
                            ...searchParam.endDate,
                            day: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={""}>일 리스트</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
            </Col>

            <Col xs={1} className="">
              <Button
                type="submit"
                className="w-100 h-100 fs-5 fw-bold"
                // onClick={() => alert("검색 버튼")}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 테이블 */}
        <Row
          className="gap-3"
          style={{
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {/* 1 */}
          <Row className="gap-3 p-0 m-0">
            <Col
              className="p-3 border rounded"
              style={{
                backgroundColor: "#1D222A",
              }}
            >
              <Row className="my-3">
                <Col xs={2} className="d-flex align-items-center">
                  <span className="border-end pe-3 me-3 fs-5">접수 현황</span>
                  <span>1</span>
                </Col>
                <Col xs={2} className="d-flex align-items-center">
                  <span className="border-end pe-3 me-3 fs-5">잔여 현황</span>
                  <span>1</span>
                </Col>
              </Row>
              <PaginatedTable columns={columns} data={dummyData} pageSize={5} />
            </Col>
            {showDetail01 && (
              <Col
                xs={4}
                className="p-3 border rounded d-flex flex-column"
                style={{ backgroundColor: "#1D222A" }}
              >
                <Col xs={12} className="mb-2">
                  <span className="fs-4 fw-bold">| 상세내용</span>
                </Col>
                <Form.Control
                  as="textarea"
                  className="flex-grow-1 mt-2"
                  readOnly
                />
              </Col>
            )}
          </Row>

          {/* 2 */}
          <Row className="gap-3 p-0 m-0">
            <Col
              className="p-3 border rounded"
              style={{
                backgroundColor: "#1D222A",
              }}
            >
              <Row className="my-3">
                <Col xs={4} className="d-flex align-items-center">
                  <span className="border-end pe-3 me-3 fs-5">처리 현황</span>
                  <span>1</span>
                </Col>
              </Row>
              <PaginatedTable columns={columns} data={dummyData} pageSize={5} />
            </Col>
            {showDetail02 && (
              <Col
                xs={4}
                className="p-3 border rounded d-flex flex-column"
                style={{ backgroundColor: "#1D222A" }}
              >
                <Col xs={12} className="mb-2">
                  <span className="fs-4 fw-bold">| 상세내용</span>
                </Col>
                <Form.Control
                  as="textarea"
                  className="flex-grow-1 mt-2"
                  readOnly
                />
              </Col>
            )}
          </Row>
        </Row>
      </Row>
    </Form>
  );
};

export default HelpDeskStatus;
