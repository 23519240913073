import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import { formatPhoneNumber } from "../../hooks/usePhoneNumberFormatter";
import {
  deleteSetTopBox,
  getAccountsCenter,
  getDistrict,
  getSetTopBox,
  getSetTopBoxId,
  putSetTopBox,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";

const DeviceOperationStatus = () => {
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const [searchParam, setSearchParam] = useState({
    district: "",
    center: "",
    search: "",
  });

  const [detailFormData, setDetailFormData] = useState({
    center: "",
    district: "",
    serial_number: "",
    is_active: true,
    manager_phone_number: "",
  });

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "district.name", title: "구" },
    { key: "center.name", title: "경로당명" },
    {
      key: "is_active",
      title: "연결상태",
    },
    { key: "serial_number", title: "기기 시리얼 넘버" },
    { key: "actions", title: "상세보기" },
  ];

  const handleContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setDetailFormData({
      ...detailFormData,
      manager_phone_number: formatted,
    });
  };

  // 행정구 리스트
  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getAccountsCenter(currentPage, searchParam.district, searchParam.center)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 셋톱박스 조회 리스트
  const getSetTopBoxList = (page = 1) => {
    getSetTopBox(
      page,
      searchParam.district,
      searchParam.center,
      searchParam.search
    )
      .then((res) => {
        console.log("settop", res.data);
        setTableData(res.data);
      })
      .catch((err) => console.err(err));
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getSetTopBoxList();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getSetTopBoxList();
  };

  const handleDetail = (data) => {
    // console.log("handleDetail id : ", data);
    setDetailModalShow(true);

    getSetTopBoxId(data?.id)
      .then((res) => {
        console.log("id response data:", res.data);
        setDetailFormData(res.data);
      })
      .catch((err) => console.err(err));
  };

  const handleEdit = () => {
    const updatedData = {
      serial_number: detailFormData.serial_number,
      is_active: detailFormData.is_active,
      manager_phone_number: detailFormData.manager_phone_number,
      center: detailFormData.center.id,
    };

    console.log("수정 요청 데이터:", updatedData);

    putSetTopBox(detailFormData.id, updatedData)
      .then((res) => {
        console.log("수정 완료:", res.data);
        getSetTopBoxList(); // 데이터 갱신
        setDetailModalShow(false); // 모달 닫기
      })
      .catch((error) => {
        console.error("수정 실패:", error);
      });
  };

  // useEffect(() => {
  //   console.log("detailFormData" + JSON.stringify(detailFormData));
  // }, [detailFormData]);

  const handleDelete = () => {
    if (!window.confirm("삭제 하시겠습니까?")) return;

    deleteSetTopBox(detailFormData.id)
      .then((res) => {
        console.log("삭제 완료 : ", res.data);
        getSetTopBoxList(); // 데이터 갱신
        setDetailModalShow(false); // 모달 닫기
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSumit = (e) => {
    e.preventDefault();
    // getAccountsCenterList();
    getSetTopBoxList();
  };

  const renderDetail = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row className={"row-gap-3"}>
                <Col xs={12} className={"d-flex flex-column gap-3"}>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구
                      </span>
                    </Col>
                    <Col xs={3}>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={detailFormData.district?.id || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            district: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>구</option>
                        {districtList?.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당명
                      </span>
                    </Col>
                    <Col xs={3}>
                      <Form.Select
                        className={"w-100 h-100"}
                        aria-label="Default select example"
                        value={detailFormData?.center?.id || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            center: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>경로당</option>
                        {centerList?.results?.map((center) => (
                          <option key={center.id} value={center.id}>
                            {center.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        연결상태
                      </span>
                    </Col>
                    <Col xs={6} className="d-flex gap-3">
                      <Form.Check
                        type={"radio"}
                        label={"연결됨"}
                        name={"connection"}
                        id={"connection"}
                        className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                        value={true} // 연결됨은 true 값
                        checked={detailFormData.is_active === true} // 체크 상태
                        onChange={() => {
                          setDetailFormData({
                            ...detailFormData,
                            is_active: true, // 연결됨으로 설정
                          });
                        }}
                      />
                      <Form.Check
                        type={"radio"}
                        label={"연결 안 됨"}
                        name={"connection"}
                        id={"disconnection"}
                        className="border rounded p-2 m-0 d-flex align-items-center pe-4"
                        value={false} // 연결 안 됨은 false 값
                        checked={detailFormData.is_active === false} // 체크 상태
                        onChange={() => {
                          setDetailFormData({
                            ...detailFormData,
                            is_active: false, // 연결 안 됨으로 설정
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        관리자 연락처
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type={"text"}
                        placeholder="연락처를 입력하세요."
                        className={"w-100 h-100"}
                        value={detailFormData.manager_phone_number || ""}
                        onChange={(e) => {
                          handleContact(e);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기 시리얼넘버
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        placeholder="시리얼넘버를 입력하세요."
                        className={"w-100 h-100"}
                        value={detailFormData.serial_number || ""}
                        onChange={(e) => {
                          setDetailFormData({
                            ...detailFormData,
                            serial_number: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center mt-4"}
          >
            <Button
              type={"button"}
              variant="secondary"
              className={"rounded-5 px-5 fw-bold fs-5"}
              onClick={() => {
                const result = window.confirm("수정 하시겠습니까?");
                if (result) {
                  // 확인 버튼 클릭 시 실행할 코드
                  handleEdit();
                }
              }}
            >
              수정
            </Button>
            <Button
              type={"button"}
              variant="danger"
              className={"rounded-5 px-5 fw-bold fs-5"}
              onClick={() => {
                const result = window.confirm("삭제 하시겠습니까?");
                if (result) {
                  // 확인 버튼 클릭 시 실행할 코드
                  handleDelete();
                }
              }}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-3"}>
        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form onSubmit={handleSumit}>
            <Row>
              <Col xs={12} className={"d-flex flex-column gap-2"}>
                <Col xs={2} className={"my-auto align-middle"}>
                  <span className="fs-4 fw-bold">| 경로당 검색 필터</span>
                </Col>
                <Row className="w-100">
                  <Col xs={1}>
                    <Form.Select
                      className="h-100 fs-5"
                      aria-label="Default select example"
                      value={searchParam.district}
                      onChange={(e) => {
                        setSearchParam({
                          ...searchParam,
                          district: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>구</option>
                      {districtList?.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  {/* <Col xs={1}>
                    <Form.Select
                      aria-label={"Default select example"}
                      value={searchParam.center}
                      onChange={(e) => {
                        setSearchParam({
                          ...searchParam,
                          center: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>경로당</option>
                      {centerList?.results?.map((center) => (
                        <option key={center.id} value={center.id}>
                          {center.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col> */}
                  <Col xs={2}>
                    <Form.Control
                      type="text"
                      placeholder="경로당명 입력"
                      className="h-100 fs-5"
                      value={searchParam.search || ""}
                      onChange={(e) => {
                        setSearchParam({
                          ...searchParam,
                          search: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={1} className={"ms-auto text-end"}>
                    <Button
                      type={"submit"}
                      className="w-100 h-100 fs-5 fw-bold"
                    >
                      검색
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A", height: "calc(100vh - 21rem)" }}
        >
          <Row className={"row-gap-3"}>
            <Col xs={6} className={"my-auto align-middle"}>
              <span className="fs-4 fw-bold">| 셋탑박스 동작 현황</span>
            </Col>
            {/* <Button
                type={"button"}
                variant="outline-secondary"
                onClick={() => setDetailModalShow(true)}
              >
                상세보기
              </Button> */}

            <Col xs={12}>
              {tableData ? (
                <CustomTable
                  columns={columns}
                  tableData={tableData}
                  handleDetail={handleDetail}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <Col className="w-100 text-center my-5">
                  <span>검색 결과가 없습니다.</span>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomModal
        show={detailModalShow}
        onHide={() => {
          setDetailModalShow(false);
          setDetailFormData({
            center: "",
            district: "",
            serial_number: "",
            is_active: true,
            manager_phone_number: "",
          });
        }}
      >
        {renderDetail()}
      </CustomModal>
    </>
  );
};

export default DeviceOperationStatus;
