import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";

const LifeStyleDeviceStatus = () => {
  const [showLifeCareModal, setShowLifeCareModal] = useState(false);

  const columns = [
    { key: "id", title: "번호" },
    { key: "location", title: "구" },
    { key: "center", title: "경로당" },
    { key: "equipment", title: "기기명" }, // 기기명 추가
    {
      key: "settop",
      title: "연결상태",
      render: (value) => (
        <img src={value ? On : Off} alt={value ? "on" : "off"} />
      ),
    },
    {
      key: "serialNumber",
      title: "기기 시리얼넘버",
      render: (value) => <span>{value ? value : "미등록"}</span>,
    },
    {
      key: "actions",
      title: "상세보기",
      render: (_, row) => (
        <Button onClick={() => setShowLifeCareModal(true)}>상세보기</Button>
      ),
    },
  ];

  const dummyData = [
    {
      id: 1,
      location: "남동구",
      center: "A 경로당",
      equipment: "셋톱박스", // 기기명 추가
      settop: true,
      serialNumber: "STB12345",
    },
    {
      id: 2,
      location: "연수구",
      center: "B 경로당",
      equipment: "셋톱박스", // 기기명 추가
      settop: false,
      serialNumber: "STB67890",
    },
    {
      id: 3,
      location: "부평구",
      center: "C 경로당",
      equipment: "셋톱박스", // 기기명 추가
      settop: true,
      serialNumber: "STB11122",
    },
    {
      id: 4,
      location: "미추홀구",
      center: "D 경로당",
      equipment: "셋톱박스", // 기기명 추가
      settop: false,
      serialNumber: null,
    },
  ];

  // 모달창 내용
  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기명
                      </span>
                    </Col>
                    <Col xs={2}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기명
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>구</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 명
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Form.Select className="h-100">
                        <option value={""}>경로당</option>
                        <option value={""}>경로당1</option>
                        <option value={""}>경로당2</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        연결상태
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-3">
                      <Form.Check
                        inline
                        label="연결"
                        name="group1"
                        type={"radio"}
                        id={`inline-radio-1`}
                        defaultChecked
                        className="border rounded p-2  m-0 d-flex align-items-center"
                      />
                      <Form.Check
                        inline
                        label="연결 안됨"
                        name="group1"
                        type={"radio"}
                        id={`inline-radio-2`}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        관리자 연락처
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex align-items-center">
                      <Col xs={2} className="h-100">
                        <Form.Control
                          className="h-100 text-center"
                          type="number"
                          placeholder=""
                        />
                      </Col>
                      <span className="mx-2 ">-</span>
                      <Col xs={2} className="h-100">
                        <Form.Control
                          className="h-100 text-center"
                          type="number"
                          placeholder=""
                        />
                      </Col>
                      <span className="mx-2 ">-</span>
                      <Col xs={2} className="h-100">
                        <Form.Control
                          className="h-100 text-center"
                          type="number"
                          placeholder=""
                        />
                      </Col>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기 시리얼넘버
                      </span>
                    </Col>
                    <Col xs={3}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="기기 시리얼넘버"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button variant="secondary" onClick={onConfirm}>
              수정
            </Button>
            <Button variant="danger" onClick={() => {}}>
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 경로당 검색 */}
        <Col
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row className="justify-content-between">
            <Col xs={6}>
              <Row className="row-gap-2">
                <Col xs={2} className="pe-0">
                  <span className="fs-4 fw-bold">| 경로당 검색</span>
                </Col>
                <Col xs={2}>
                  <Form.Select>
                    <option value={""}>구</option>
                    <option value={""}>구1</option>
                    <option value={""}>구2</option>
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select>
                    <option value={""}>경로당</option>
                    <option value={""}>경로당1</option>
                    <option value={""}>경로당2</option>
                  </Form.Select>
                </Col>
                <Col xs={2} className="border rounded text-center p-2">
                  경로당 명
                </Col>
                <Col xs={4}>
                  <Form.Control type="text" placeholder="경로당명 입력" />
                </Col>
              </Row>
            </Col>
            <Col xs={5}>
              <Row className="row-gap-2 w-100 justify-content-end">
                <Col xs={2} className="border rounded text-center p-2">
                  기기선택
                </Col>
                {["워킹머신", "스마트테이블"].map((category, index) => (
                  <Col xs={3} key={index}>
                    <Button
                      className="w-100 h-100 btn-outline-primary"
                      variant="primary"
                      onClick={() => alert(`${category} 필터링`)}
                    >
                      {category}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={1} className="mt-auto">
              <Button className="w-100" onClick={() => alert("검색 버튼")}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 테이블 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col className="mb-2">
            <span className="fs-4 fw-bold">| 셋톱박스 동작 현황</span>
          </Col>
          <PaginatedTable columns={columns} data={dummyData} pageSize={10} />
        </Col>
      </Row>
      <CustomModal
        show={showLifeCareModal}
        onHide={() => setShowLifeCareModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default LifeStyleDeviceStatus;
