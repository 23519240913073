import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";

const CompetitionData = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // 대회등록 모달창 내용
  const renderEdit1 = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row className="">
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">| 대회 등록하기</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회 회차
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="대회 회차 입력"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회명명
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="대회명 입력"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        코스 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>코스선택</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구간 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>구간 리스트</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기간 설정
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-2">
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>년 리스트</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>월 리스트</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>일 리스트</option>
                        </Form.Select>
                      </Col>
                      <Col
                        className="border rounded text-center p-2"
                        style={{ maxWidth: "2rem" }}
                      >
                        ~
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>년 리스트</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>월 리스트</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>일 리스트</option>
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button className="btn-send" onClick={onConfirm}>
              저장
            </Button>
            <Button variant="secondary" onClick={() => {}}>
              초기화
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  // 수정하기 모달창 내용
  const renderEdit2 = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">| 대회 수정하기</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회 회차
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="대회 회차 입력"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        대회명명
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="대회명 입력"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        코스 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>코스선택</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구간 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>구간 리스트</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기간 설정
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-2">
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>년 리스트</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>월 리스트</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>일 리스트</option>
                        </Form.Select>
                      </Col>
                      <Col
                        className="border rounded text-center p-2"
                        style={{ maxWidth: "2rem" }}
                      >
                        ~
                      </Col>
                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>년 리스트</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>월 리스트</option>
                        </Form.Select>
                      </Col>

                      <Col>
                        <Form.Select
                          className="h-100"
                          aria-label="Default select example"
                        >
                          <option value={""}>일 리스트</option>
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button className="btn-send" onClick={onConfirm}>
              저장
            </Button>
            <Button variant="danger" onClick={() => {}}>
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 대회 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row className="justify-content-between mb-2">
            <Col xs={3}>
              <span className="fs-4 fw-bold">| 대회 검색</span>
            </Col>
            <Col xs={1} className="text-end">
              <Button
                className="w-100"
                onClick={() => setShowRegisterModal(true)}
              >
                대회 등록
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={5} className="">
                  <Form.Select>
                    <option value={""}>대회 회차 선택</option>
                    <option value={""}>1</option>
                    <option value={""}>2</option>
                  </Form.Select>
                </Col>
                <Col xs={2} className="border rounded text-center p-2">
                  대회명
                </Col>
                <Col xs={5}>
                  <Form.Control type="text" placeholder="대회명 입력" />
                </Col>
              </Row>
            </Col>

            <Col xs={7} className="d-flex gap-2">
              <Col className="border rounded text-center p-2">기간 설정</Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>년 리스트</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>월 리스트</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>일 리스트</option>
                </Form.Select>
              </Col>
              <Col
                className="border rounded text-center p-2"
                style={{ maxWidth: "2rem" }}
              >
                ~
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>년 리스트</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>월 리스트</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Select aria-label="Default select example">
                  <option value={""}>일 리스트</option>
                </Form.Select>
              </Col>
            </Col>

            <Col xs={1} className="">
              <Button className="w-100" onClick={() => alert("검색 버튼")}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 차트 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col className="w-100 mb-1">
              <span className="fs-4 fw-bold w-100">| 대회 정보</span>
            </Col>
            <Col xs={12} className="">
              <p>페이지네이션</p>
              {/* 모달창 버튼 */}
              <Button onClick={() => setShowEditModal(true)}>수정하기</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit1({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
      <CustomModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit2({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default CompetitionData;
