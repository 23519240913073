import React, { useState } from "react";
import Frame from "../components/common/Frame";
import Organization from "../components/setting/Organization";
import Menu from "../components/setting/Menu";
import Authority from "../components/setting/Authority";

const Settings = () => {
  const subMenu = [
    { title: "조직 관리", content: <Organization/> },
    { title: "메뉴 관리", content: <Menu/> },
    { title: "권한 관리", content: <Authority/> },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"설정 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default Settings;
