import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import CustomModal from "../../common/CustomModal";
import useDaysInMonth from "../../../hooks/useDaysInMonth";
import {
  deleteTvsSchedule, getTvsScheduleCategory, getTvsScheduleDetail,
  getTvsSchedulesDay,
  getTvsVod,
  getTvsVodCategory,
  getTvsVodDetail, putTvsSchedule,
} from "../../../api/apiClient";
import CustomTable from "../../table/CustomTable";
import {STATIC_FILE_URL} from "../../../config";

const ScheduleSetting = ({selectedSchedule, setSelectedContent}) => {
  // const [selectedSchedule, setSelectedSchedule] = useState({});

  const [editModalShow, setEditModalShow] = React.useState(false);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [vodListModalShow, setVodListModalShow] = useState(false);

  const [searchDate, setSearchDate] = useState({
    year: selectedSchedule.year,
    month: selectedSchedule.month,
    day: selectedSchedule.day,
  });

  const [vodCategoryList, setVodCategoryList] = useState([]);
  const [scheduleCategoryList, setScheduleCategoryList] = useState([]);

  const getCategoryList = () => {
    getTvsVodCategory()
        .then((res) => {
          console.log("getTvsVodCategory res : ", res.data);
          setVodCategoryList(res.data);
        })
        .catch((error) => {
          console.error(error);
        });

    getTvsScheduleCategory()
        .then((res) => {
          console.log("getTvsScheduleCategory res : ", res.data);
          setScheduleCategoryList(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const [formData, setFormData] = useState({
    year: selectedSchedule.year,
    month: selectedSchedule.month,
    day: selectedSchedule.day,
    startHour: "00",
    startMinute: "00",
    endHour: "00",
    endMinute: "00",
    category: "",
    resolution: "",
    contentType: "streaming",
    thumbnail: "",
    title: "",
    content: "",
    vod: {},
  });

  const daysInMonth = useDaysInMonth(searchDate.year, searchDate.month);

  const [schedule, setSchedule] = useState([]);

  const getSchedule = () => {
    getTvsSchedulesDay(
        `${searchDate.year}-${searchDate.month}-${searchDate.day}`
    )
        .then((res) => {
          console.log(res.data);
          setSchedule(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    getCategoryList();
    getSchedule();
  }, []);

  useEffect(() => {
    getCategoryList();
    getSchedule();
  }, [searchDate]);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        thumbnail: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  /*useEffect(() => {
    // formData.contentType 이 변경될 경우 카테고리, 해상도, 썸네일 초기화
    setFormData({
      ...formData,
      vod: "",
      category: "",
      resolution: "",
      thumbnail: "",
    });
  }, [formData.contentType]);*/

  const [vodSearchParam, setVodSearchParam] = useState({
    category: "",
    search: "",
  });

  const [vodTableData, setVodTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {key: "id", title: "번호"},
    {key: "category.name", title: "카테고리"},
    {key: "resolution", title: "해상도"},
    {key: "title", title: "콘텐츠 명"},
    {key: "thumbnail", title: "썸네일 유무"},
    {key: "select", title: "선택하기"},
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDetail = (row) => {
    console.log("handleDetail row : ", row);
    setScheduleID(row.id);

    getTvsVodDetail(row.id)
        .then((res) => {
          console.log("getTvsVodDetail res : ", res.data);
          setFormData({
            ...formData,
            // vod: res.data.title,
            category: res.data.category.id,
            resolution: res.data.resolution,
            thumbnail: res.data.thumbnail,
          });
          setVodListModalShow(false);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const handleVodSearch = (e) => {
    e.preventDefault();
    // VOD 영상 리스트 조회
    getVODList();
  };

  const getVODList = () => {
    getTvsVod(vodSearchParam.category, vodSearchParam.search)
        .then((res) => {
          console.log("getTvsVod res : ", res.data);
          setVodTableData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    getVODList();
  }, [vodListModalShow, currentPage]);

  const [scheduleID, setScheduleID] = useState(null);

  useEffect(() => {
    // formData log
    const logFormData = (formData) => {
      for (let key in formData) {
        if (formData.hasOwnProperty(key)) {
          console.log(`${key}: ${formData[key]}`);
        }
      }
    };

    if (formData) {
      logFormData(formData);
    }
  }, [formData]);


  // 편성 수정
  const updateSchedule = () => {
    if (!formData.resolution || !formData.category || !formData.vod) {
      console.error("Please fill in all required fields.");
      return;
    }

    const putData = new FormData();
    putData.append("start_time", `${formData.startHour}:${formData.startMinute}:00`);
    putData.append("end_time", `${formData.endHour}:${formData.endMinute}:00`);
    putData.append("title", formData.title);
    putData.append("description", formData.content);
    putData.append("resolution", formData.resolution);
    putData.append("content_type", formData.contentType);
    putData.append("schedule_category", formData.category);
    putData.append("vod", formData.vod.id);
    putData.append("date-type", "single");
    putData.append("year-month", `${formData.year}-${formData.month}`);
    putData.append("date", `${formData.day}`);

    if (scheduleID) {
      putTvsSchedule(scheduleID, putData)
          .then((res) => {
            console.log("postTvsSchedule res : ", res.data);
            setEditModalShow(false);
            getSchedule();
          })
          .catch((error) => {
            console.error(error);
          });
    } else {
      console.error("No scheduleID set for update");
    }
  };

  const handleDelete = (id) => {
    setScheduleID(id);
    deleteSchedule();
  };

  // 편성 삭제
  const deleteSchedule = () => {
    console.log("deleteSchedule scheduleID : ", scheduleID);
    if (scheduleID) {
      deleteTvsSchedule(scheduleID)
          .then((res) => {
            console.log("deleteTvsSchedule res : ", res.data);
            setEditModalShow(false);
            getSchedule();
          })
          .catch((error) => {
            console.error(error);
          });
    } else {
      console.error("No scheduleID set for deletion");
    }
  };

  // 상세 정보 조회
  const getDetail = (id) => {
    getTvsScheduleDetail(id)
        .then((res) => {
          console.log("getTvsScheduleDetail res : ", res.data);
          setFormData({
            year: res.data.dates[0].date.split("-")[0],
            month: res.data.dates[0].date.split("-")[1],
            day: res.data.dates[0].date.split("-")[2],
            startHour: res.data.start_time.split(":")[0],
            startMinute: res.data.start_time.split(":")[1],
            endHour: res.data.end_time.split(":")[0],
            endMinute: res.data.end_time.split(":")[1],
            category: res.data.schedule_category.id,
            resolution: res.data.resolution,
            contentType: res.data.content_type,
            thumbnail: res.data.thumbnail,
            title: res.data.title,
            content: res.data.description,
            vod: res.data.vod,
          });
          // setEditModalShow(true);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const renderEdit = () => {
    const handleInputChange = (e) => {
      const {name, value} = e.target;
      setFormData({...formData, [name]: value});
    };

    return (
        <>
          <Row className={"row-gap-3"}>
            <Col xs={12} className={"d-flex flex-column gap-3"}>
              <Row>
                <Col xs={2} className={"my-auto align-middle"}>
                <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                >
                  편성일
                </span>
                </Col>
                <Col xs={10} className="d-flex align-items-center gap-3">
                  <Col xs={2} className="h-100">
                    <Form.Select
                        name="year"
                        value={formData.year}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className="h-100"
                    >
                      <option value={""}>년 리스트</option>
                      <option value={"2024"}>2024년</option>
                      <option value={"2025"}>2025년</option>
                    </Form.Select>
                  </Col>
                  <Col xs={2} className="h-100">
                    <Form.Select
                        name="month"
                        value={formData.month}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className={"h-100"}
                    >
                      <option value={""}>월 리스트</option>
                      {Array.from({length: 12}, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}월
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={2} className="h-100">
                    <Form.Select
                        name="day"
                        value={formData.day}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className={"h-100"}
                    >
                      <option value={""}>일 리스트</option>
                      {daysInMonth.map((day) => (
                          <option key={day} value={day}>
                            {day}일
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col xs={2} className={"my-auto align-middle"}>
                <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                >
                  방송 시간
                </span>
                </Col>
                <Col xs={10} className="d-flex align-items-center gap-3">
                  <Col xs={2} className={"h-100"}>
                    <Form.Select
                        name="startHour"
                        value={formData.startHour}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className={"h-100"}
                    >
                      {Array.from({length: 24}, (_, i) => (
                          <option key={i} value={i}>
                            {i}시
                          </option>
                      ))}
                      {/* Add start time options */}
                    </Form.Select>
                  </Col>
                  <Col xs={2} className={"h-100"}>
                    <Form.Select
                        name="startMinute"
                        value={formData.startMinute}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className={"h-100"}
                    >
                      {Array.from({length: 60}, (_, i) => (
                          <option key={i} value={i}>
                            {i}분
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                  ~
                  <Col xs={2} className={"h-100"}>
                    <Form.Select
                        name="endHour"
                        value={formData.endHour}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className={"h-100"}
                    >
                      {Array.from({length: 24}, (_, i) => (
                          <option key={i} value={i}>
                            {i}시
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={2} className={"h-100"}>
                    <Form.Select
                        name="endMinute"
                        value={formData.endMinute}
                        onChange={handleInputChange}
                        aria-label="Default select example"
                        className={"h-100"}
                    >
                      {Array.from({length: 60}, (_, i) => (
                          <option key={i} value={i}>
                            {i}분
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Col>
              </Row>

              <Row>
                <Col xs={2} className={"my-auto align-middle"}>
                <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                >
                  컨텐츠 종류
                </span>
                </Col>
                <Col xs={10} className="d-flex gap-3">
                  <Form.Check
                      type={"radio"}
                      label={"실시간 스트리밍"}
                      name={"contentType"}
                      id={"streaming"}
                      value={"streaming"}
                      checked={formData.contentType === "streaming"}
                      onChange={handleInputChange}
                      className="border rounded p-2 m-0 d-flex align-items-center"
                  />
                  <Form.Check
                      type={"radio"}
                      label={"VOD 콘텐츠"}
                      name={"contentType"}
                      id={"vod"}
                      value={"vod"}
                      checked={formData.contentType === "vod"}
                      onChange={handleInputChange}
                      className="border rounded p-2 m-0 d-flex align-items-center"
                  />
                  {formData.contentType === "vod" && (
                      <>
                        <Form.Control
                            type={"text"}
                            name="vod"
                            value={formData.vod.title}
                            placeholder={"선택된 콘텐츠 명"}
                            className={"w-25"}
                            // className="d-inline border rounded p-2 m-0 d-flex align-items-center"
                            readOnly={true}
                        />
                        <Button
                            type={"button"}
                            variant={"primary"}
                            className={""}
                            onClick={() => {
                              setVodListModalShow(true);
                            }}
                        >
                          VOD 영상 선택
                        </Button>
                      </>
                  )}
                </Col>
              </Row>

              {formData.contentType === "streaming" && (
                  <>
                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                    <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                    >
                      카테고리
                    </span>
                      </Col>
                      <Col xs={10} className="d-flex align-items-center">
                        <Col xs={2} className="h-100">
                          <Form.Select
                              name="category"
                              value={formData.category}
                              onChange={handleInputChange}
                              aria-label="Default select example"
                              className={"h-100"}
                          >
                            {/*<option value={""}>영상 카테고리</option>
                            {vodCategoryList.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                            ))}*/}
                            <option value={""}>스케줄 카테고리</option>
                            {scheduleCategoryList.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={2} className={"my-auto align-middle"}>
                    <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                    >
                      해상도
                    </span>
                      </Col>
                      <Col xs={10} className="d-flex align-items-center">
                        <Col xs={2} className="h-100">
                          <Form.Select
                              name="resolution"
                              value={formData.resolution}
                              onChange={handleInputChange}
                              aria-label="Default select example"
                              className={"h-100"}
                          >
                            <option value={""}>해상도</option>
                            <option value={"480p"}>480p</option>
                            <option value={"720p"}>720p</option>
                            <option value={"1080p"}>1080p</option>
                            <option value={"4K"}>4K</option>
                          </Form.Select>
                        </Col>
                      </Col>
                    </Row>
                  </>
              )}

              <Row>
                <Col xs={2} className={"my-auto align-middle"}>
                <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                >
                  썸네일 업로드
                </span>
                </Col>
                <Col xs={5} className="h-100">
                  <Form.Control
                      type={"file"}
                      accept={"image/*"}
                      name="thumbnail"
                      // onChange={(e) => setFormData({...formData, thumbnail: e.target.files[0]})}
                      className="h-100 align-content-center"
                      onChange={handleThumbnailChange}
                  />
                </Col>
              </Row>

              {formData.thumbnail && (
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                  <span
                      className={"d-block w-100 p-2 rounded-3 text-center fs-5"}
                  >
                    &nbsp;
                  </span>
                    </Col>
                    <Col xs={2}>
                      {formData.thumbnail && (
                          <img
                              src={formData.thumbnail ? STATIC_FILE_URL + formData.thumbnail : "https://via.placeholder.com/150"}
                              alt={"thumbnail"}
                              className={"img-fluid w-100 mt-3"}
                          />
                      )}
                    </Col>
                  </Row>
              )}

              <Row>
                <Col xs={2} className={"my-auto align-middle"}>
                <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                >
                  타이틀
                </span>
                </Col>
                <Col xs={5} className="h-100">
                  <Form.Control
                      type={"text"}
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      placeholder={"타이틀 입력"}
                      className="h-100"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={2} className={"my-auto align-middle"}>
                <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                >
                  내용
                </span>
                </Col>
                <Col xs={5} className="h-100">
                  <Form.Control
                      type={"text"}
                      name="content"
                      value={formData.content}
                      onChange={handleInputChange}
                      placeholder={"내용 입력"}
                      className="h-100"
                  />
                </Col>
              </Row>
            </Col>
            <Col
                xs={12}
                className={"d-flex justify-content-center gap-3 text-center mt-4"}
            >
              <Button
                  variant="secondary"
                  onClick={() => {
                    setPreviewModalShow(true);
                  }}
                  className={"rounded-5 px-4 fw-bold fs-5"}
              >
                미리보기
              </Button>
              <Button
                  variant="primary"
                  className={"rounded-5 px-4 fw-bold fs-5 btn-send"}
                  onClick={() => {
                    const result = window.confirm("저장하시겠습니까?");
                    if (result) {
                      updateSchedule(schedule.id);
                    }
                  }}
              >
                저장
              </Button>
              <Button
                  variant="danger"
                  className={"rounded-5 px-4 fw-bold fs-5"}
                  onClick={() => {
                    const result = window.confirm("삭제하시겠습니까?");
                    if (result) {
                      // alert("삭제되었습니다.");
                      handleDelete(schedule.id);
                    }
                  }}
              >
                삭제
              </Button>
            </Col>
          </Row>
          <CustomModal
              show={previewModalShow}
              onHide={() => setPreviewModalShow(false)}
              onConfirm={() => alert("미리보기")}
              size={"lg"}
          >
            {renderPreview()}
          </CustomModal>
          <CustomModal
              show={vodListModalShow}
              onHide={() => {
                setVodListModalShow(false);
                setVodSearchParam({
                  category: "",
                  search: "",
                });
              }}
              size={"lg"}
          >
            {renderVodList()}
          </CustomModal>
        </>
    );
  };

  const renderPreview = () => {
    return (
        <>
          <Row className={"row-gap-3"}>
            <Col xs={3}>
            <span
                className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}
            >
              날짜
            </span>
            </Col>
            <Col xs={12}>미리보기 이미지 들어가는 자리</Col>
          </Row>
        </>
    );
  };

  const renderVodList = () => {
    return (
        <>
          <Row className={"row-gap-4"}>
            <Col xs={12}>
              <Row className={"row-gap-3"}>
                <Col xs={12}>
                  <span className="fs-4 fw-bold">| VOD 영상 선택하기</span>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={4}>
                      <Form>
                        <Form.Select
                            aria-label="Default select example"
                            value={vodSearchParam.category}
                            onChange={(e) =>
                                setVodSearchParam({
                                  ...vodSearchParam,
                                  category: e.target.value,
                                })
                            }
                        >
                          {/*<option value={""}>카테고리</option>
                          {vodCategoryList.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                          ))}*/}
                          <option value={""}>카테고리</option>
                          {scheduleCategoryList.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                          ))}
                        </Form.Select>
                      </Form>
                    </Col>
                    <Col xs={6}>
                      <Form.Control
                          type={"text"}
                          value={vodSearchParam.search}
                          onChange={(e) =>
                              setVodSearchParam({
                                ...vodSearchParam,
                                search: e.target.value,
                              })
                          }
                          placeholder={"검색어를 입력하세요."}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                          type={"button"}
                          className={"w-100"}
                          onClick={(e) => {
                            // VOD 영상 리스트 조회
                            handleVodSearch(e);
                          }}
                      >
                        검색
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              {vodTableData ? (
                  <CustomTable
                      columns={columns}
                      tableData={vodTableData}
                      handleDetail={handleDetail}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                  />
              ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
              )}
            </Col>
          </Row>
        </>
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // alert("검색");
  };

  return (
      <>
        <Row className={"row-gap-3"}>
          <Col
              xs={12}
              className={"border p-3 rounded-3"}
              style={{backgroundColor: "#1D222A"}}
          >
            <Form>
              <Row>
                <Col xs={1} className={"my-auto"}>
                  <span className="fs-4 fw-bold">| 편성 캘린더</span>
                </Col>
                <Col xs={5} className="d-flex gap-3">
                  <Col xs={3}>
                    <Form.Select
                        className="h-100 fs-5"
                        aria-label="Default select example"
                        value={searchDate.year}
                        onChange={(e) =>
                            setSearchDate({
                              ...searchDate,
                              year: e.target.value,
                            })
                        }
                    >
                      <option value={""}>연도 선택</option>
                      <option value={"2024"}>2024년</option>
                      <option value={"2025"}>2025년</option>
                    </Form.Select>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                        className="h-100 fs-5"
                        aria-label="Default select example"
                        value={searchDate.month}
                        onChange={(e) =>
                            setSearchDate({
                              ...searchDate,
                              month: e.target.value,
                            })
                        }
                    >
                      {Array.from({length: 12}, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}월
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                        className="h-100 fs-5"
                        value={searchDate.day}
                        onChange={(e) =>
                            setSearchDate({
                              ...searchDate,
                              day: e.target.value,
                            })
                        }
                    >
                      {daysInMonth.map((day) => (
                          <option key={day} value={day}>
                            {day}일
                          </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={2}>
                    <Button
                        type={"button"}
                        variant={"primary"}
                        className="w-100 h-100 fs-5 fw-bold"
                        onClick={(e) => {
                          handleSearch(e);
                        }}
                    >
                      검색
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col
              xs={12}
              className={"border p-2 py-5 rounded-3"}
              style={{backgroundColor: "#1D222A", height: "calc(100vh - 20rem)"}}
          >
            <Row className={"justify-content-center row-gap-4"}>
              <Col xs={9}>
                <Row className={"justify-content-between"}>
                  <Col xs={2}>
                  <span
                      className={
                        "d-block w-100 border p-2 rounded-3 text-center fs-5"
                      }
                  >
                    {searchDate.year}년 {searchDate.month}월 {searchDate.day}일
                  </span>
                    {/*<span className={"border p-2 rounded-3"}>편성일</span>*/}
                  </Col>
                  <Col xs={2} className={"text-end"}>
                    <Button
                        type={"button"}
                        variant={"secondary"}
                        className={"px-4 fw-bold fs-5"}
                        onClick={() => {
                          // alert("이전으로");
                          setSelectedContent("Scheduler");
                        }}
                    >
                      이전으로
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={10}>
                <Row className={"justify-content-around text-center"}>
                  {schedule.map((schedule) => (
                      <Col xs={3} key={schedule.id}>
                        <Card className={"rounded-2 h-100 d-flex flex-column"}
                              style={{maxHeight: "none", height: "55vh", cursor: "auto"}}
                        >
                          <Row className={"justify-content-center p-3 row-gap-3 fs-4"}
                          >
                            <Col xs={10}
                                 className={"border-bottom pb-1 d-flex flex-column align-items-center justify-content-between"}>
      <span>
        {schedule.category && schedule.category.name}
      </span>
                            </Col>
                            <Col xs={10}
                                 className={"border-bottom pb-1 d-flex flex-column align-items-center justify-content-between"}>
                              <span>{schedule.resolution}</span>
                            </Col>
                            <Col xs={10}
                                 className={"border-bottom pb-1 d-flex flex-column align-items-center justify-content-between"}>
      <span>
        {schedule.start_time} - {schedule.end_time}
      </span>
                            </Col>
                            <Col xs={10}
                                 className={"border-bottom pb-1 d-flex flex-column align-items-center justify-content-between"}>
                              <span>{schedule.title}</span>
                            </Col>
                            <Col xs={8}
                                 className={"pb-1 text-center align-content-center d-flex flex-column align-items-center justify-content-between "}
                                 style={{minHeight: "35vh"}}
                            >
                              <img
                                  src={
                                    schedule.thumbnail
                                        ? STATIC_FILE_URL + schedule.thumbnail
                                        : "https://via.placeholder.com/150"
                                  }
                                  alt={"thumbnail"}
                                  className={"w-100"}
                              />
                            </Col>
                            <Col xs={10} className={"text-center d-flex flex-column align-items-center mt-auto"}>
                              <Button
                                  type={"button"}
                                  variant={"secondary"}
                                  className={"px-5 rounded-5 fw-bold fs-5"}
                                  onClick={() => {
                                    getDetail(schedule.id);
                                    setScheduleID(schedule.id);
                                    setEditModalShow(true);
                                  }}
                              >
                                수정
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                  ))}

                  {schedule.length === 0 && (
                      <Col xs={12} className={"text-center fs-4"}>
                        <span>해당 날짜에 방송 스케줄이 없습니다.</span>
                      </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <CustomModal
            show={editModalShow}
            onHide={() => {
              setEditModalShow(false);
              // setFormData 초기화
            }}
        >
          {renderEdit()}
        </CustomModal>
      </>
  );
};

export default ScheduleSetting;
