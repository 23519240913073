import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
// import LineChart from "../chart/LineChart";
// import PieChart from "../chart/PieChart";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import {
  getAccountsCenter,
  getDistrict,
  getSeniorCenter,
  getStatisticsTvs,
  getStatisticsTvsRankingIptv,
  getStatisticsTvsRankingLive,
  getStatisticsTvsRankingVod,
} from "../../api/apiClient";
import StockChart from "../chart/StockChart";
import { transferData, transformData } from "../../hooks/useTransferData";
import DefaultPieChart from "../chart/DefaultPieChart";
import CustomTable from "../table/CustomTable";
// import ReactToPdf from 'react-to-pdf';
import usePrintPDF from "../../hooks/usePrintPDF";
import CenterSearch from "../common/search/CenterSearch";
import DateSearch from "../common/search/DateSearch";

const Usage = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const [searchParam, setSearchParam] = useState({
    year: oneWeekAgo.getFullYear(),
    month: oneWeekAgo.getMonth() + 1,
    day: oneWeekAgo.getDate(),
    year2: today.getFullYear(),
    month2: today.getMonth() + 1,
    day2: today.getDate(),
    local: "",
    center: "",
    keyword: "",
  });

  const handleKeywordChange = (e) => {
    setSearchParam({
      ...searchParam,
      keyword: e.target.value,
      center: e.target.value ? "" : searchParam.center,
    });
  };

  const handleCenterChange = (e) => {
    setSearchParam({
      ...searchParam,
      center: e.target.value,
      keyword: e.target.value ? "" : searchParam.keyword,
    });
  };

  const [districtList, setDistrictList] = useState(null);
  const [centerList, setCenterList] = useState(null);

  const getDistrictList = () => {
    getDistrict()
      .then((res) => {
        console.log("getDistrictList", res.data);
        setDistrictList(res.data);
      })
      .catch((err) => console.err(err));
  };

  // 경로당 리스트
  const getAccountsCenterList = () => {
    getSeniorCenter(searchParam.local)
      .then((res) => {
        // console.log("center", res.data);
        setCenterList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAccountsCenterList();
  }, [searchParam.local]);

  const daysInMonth = useDaysInMonth(searchParam.year, searchParam.month);

  const [statisticsData, setStatisticsData] = useState({});
  const [loading, setLoading] = useState(false);

  const [RankingLiveTableData, setRankingLiveTableData] = useState([]);
  const [RankingLiveCurrentPage, setRankingLiveCurrentPage] = useState(1);
  const RankingLiveColumns = [
    { key: "center_id", title: "번호" },
    { key: "district", title: "구" },
    { key: "center_name", title: "경로당명" },
    { key: "stats.live_sum", title: "기간 별 합계" },
    { key: "stats.live_avg", title: "일 평균" },
  ];

  const handlePageRankingLiveChange = (page) => {
    setRankingLiveCurrentPage(page);
    getRankingLive();
  };

  const [RankingVODTableData, setRankingVODTableData] = useState([]);
  const [RankingVODCurrentPage, setRankingVODCurrentPage] = useState(1);
  const RankingVODColumns = [
    { key: "center_id", title: "번호" },
    { key: "district", title: "구" },
    { key: "center_name", title: "경로당명" },
    { key: "stats.vod_sum", title: "기간 별 합계" },
    { key: "stats.vod_avg", title: "일 평균" },
  ];

  const handlePageRankingVODChange = (page) => {
    setRankingVODCurrentPage(page);
    getRankingVOD();
  };

  const [RankingIPTVTableData, setRankingIPTVTableData] = useState([]);
  const [RankingIPTVCurrentPage, setRankingIPTVCurrentPage] = useState(1);
  const RankingIPTVColumns = [
    { key: "center_id", title: "번호" },
    { key: "district", title: "구" },
    { key: "center_name", title: "경로당명" },
    { key: "stats.iptv_sum", title: "기간 별 합계" },
    { key: "stats.iptv_avg", title: "일 평균" },
  ];

  const handlePageRankingIPTVChange = (page) => {
    setRankingIPTVCurrentPage(page);
    getRankingIPTV();
  };

  const getStatisticsData = () => {
    setLoading(true);

    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getStatisticsTvs(searchParam.local, searchParam.center, startData, endData)
      .then((res) => {
        console.log("getStatisticsTvs res : ", res.data);
        setStatisticsData(res.data);
        setLiveStreamingData(transferData(res.data.live_streaming));
        setVod(transferData(res.data.vod));
        setIptv(transferData(res.data.iptv));
        setMessage(transferData(res.data.message));
        setVideo_call(transferData(res.data.video_call));
        setWeather(transferData(res.data.weather));
        setBusData(transferData(res.data.bus));
        setIptv_usage_sum_by_district(
          transformData(res.data.iptv_usage_sum_by_district)
        );
        setVod_usage_sum_by_district(
          transformData(res.data.vod_usage_sum_by_district)
        );
        setLive_streaming_usage_sum_by_district(
          transformData(res.data.live_streaming_usage_sum_by_district)
        );

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRankingLive = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getStatisticsTvsRankingLive(
      RankingLiveCurrentPage,
      startData,
      endData,
      "desc"
    )
      .then((res) => {
        console.log("getStatisticsTvsRankingLive res : ", res.data);
        setRankingLiveTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRankingVOD = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getStatisticsTvsRankingVod(
      RankingVODCurrentPage,
      startData,
      endData,
      "desc"
    )
      .then((res) => {
        console.log("getStatisticsTvsRankingVod res : ", res.data);
        setRankingVODTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRankingIPTV = () => {
    const startData = `${searchParam.year}-${searchParam.month}-${searchParam.day}`;
    const endData = `${searchParam.year2}-${searchParam.month2}-${searchParam.day2}`;

    getStatisticsTvsRankingIptv(
      RankingIPTVCurrentPage,
      startData,
      endData,
      "desc"
    )
      .then((res) => {
        console.log("getStatisticsTvsRankingVod res : ", res.data);
        setRankingIPTVTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictList();
    getAccountsCenterList();
    getStatisticsData();
    getRankingLive();
    getRankingVOD();
    getRankingIPTV();
  }, []);

  const handleSearch = () => {
    getStatisticsData();
    getRankingLive();
    getRankingVOD();
    getRankingIPTV();
  };

  /* 기간 별 메인기능 이용 시간 */
  const [liveStreamingData, setLiveStreamingData] = useState([]);
  const [vod, setVod] = useState([]);
  const [iptv, setIptv] = useState([]);

  /* 기간 별 메시지 기능 이용 횟수 */
  const [message, setMessage] = useState([]);
  const [video_call, setVideo_call] = useState([]);

  /* 기간 별 편의기능 이용 횟수 */
  const [weather, setWeather] = useState([]);
  const [busData, setBusData] = useState([]);

  /* 실시간방송 이용 시간 합계 */
  const [
    live_streaming_usage_sum_by_district,
    setLive_streaming_usage_sum_by_district,
  ] = useState([]);
  /* VOD 이용 시간 합계 */
  const [iptv_usage_sum_by_district, setIptv_usage_sum_by_district] = useState(
    []
  );
  /* IPTV 이용 시간 합계 */
  const [vod_usage_sum_by_district, setVod_usage_sum_by_district] = useState(
    []
  );

  /* PDF 출력 기능 */
  const { pdfRef, handlePrintPDF } = usePrintPDF();

  return (
    <>
      <Row className={"row-gap-3"}>
        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form>
            <Row className={"row-gap-3"}>
              <DateSearch
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                daysInMonth={daysInMonth}
              />
              {/* <Col xs={8}>
                <Row>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 기간 검색 필터</span>
                  </Col>
                  <Col xs={10} className="d-flex gap-2">
                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchParam.year}
                        onChange={(e) =>
                          setSearchParam({
                            ...searchParam,
                            year: e.target.value,
                          })
                        }
                      >
                        <option value={""}>년 리스트</option>
                        <option value={2024}>2024년</option>
                        <option value={2025}>2025년</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchParam.month}
                        onChange={(e) =>
                          setSearchParam({
                            ...searchParam,
                            month: e.target.value,
                          })
                        }
                      >
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}월
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchParam.day}
                        onChange={(e) =>
                          setSearchParam({
                            ...searchParam,
                            day: e.target.value,
                          })
                        }
                      >
                        <option value={""}>일 리스트</option>
                        {daysInMonth.map((day) => (
                          <option key={day} value={day}>
                            {day}일
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col
                      className="border rounded text-center p-2 my-auto align-middle"
                      style={{ maxWidth: "2rem" }}
                    >
                      ~
                    </Col>
                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchParam.year2}
                        onChange={(e) =>
                          setSearchParam({
                            ...searchParam,
                            year2: e.target.value,
                          })
                        }
                      >
                        <option value={""}>년 리스트</option>
                        <option value={2024}>2024년</option>
                        <option value={2025}>2025년</option>
                      </Form.Select>
                    </Col>

                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchParam.month2}
                        onChange={(e) =>
                          setSearchParam({
                            ...searchParam,
                            month2: e.target.value,
                          })
                        }
                      >
                        <option value={""}>월 리스트</option>
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}월
                          </option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchParam.day2}
                        onChange={(e) =>
                          setSearchParam({
                            ...searchParam,
                            day2: e.target.value,
                          })
                        }
                      >
                        <option value={""}>일 리스트</option>
                        {daysInMonth.map((day) => (
                          <option key={day} value={day}>
                            {day}일
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Col>
                </Row>
              </Col> */}
              <CenterSearch
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                districtList={districtList}
                centerList={centerList}
                handleKeywordChange={handleKeywordChange}
                handleCenterChange={handleCenterChange}
                onSearch={handleSearch}
              />
              {/* <Col xs={8}>
                <Row className={""}>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 경로당 검색 필터</span>
                  </Col>
                  <Col xs={2}>
                    <Form.Select
                      aria-label="Default select example"
                      value={searchParam.local}
                      onChange={(e) =>
                        setSearchParam({
                          ...searchParam,
                          local: e.target.value,
                        })
                      }
                    >
                      <option value={""}>구</option>
                      {districtList &&
                        districtList.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col xs={2}>
                    <Form.Select
                      aria-label="Default select example"
                      value={searchParam.center}
                      onChange={handleCenterChange}
                    >
                      <option value={""}>경로당</option>
                      {centerList &&
                        centerList.map((center) => (
                          <option key={center.id} value={center.id}>
                            {center.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="text"
                      placeholder="검색어를 입력하세요."
                      className={"w-100"}
                      disabled={searchParam.center !== ""}
                      value={searchParam.keyword}
                      onChange={handleKeywordChange}
                    />
                  </Col>
                  <Col xs={2} className={"text-end"}>
                    <Button
                      type={"button"}
                      className={"px-5"}
                      onClick={() => {
                        getStatisticsData();
                        getRankingLive();
                        getRankingVOD();
                        getRankingIPTV();
                      }}
                    >
                      검색
                    </Button>
                  </Col>
                </Row>
              </Col> */}
              <Col
                xs={4}
                className={"d-flex justify-content-end gap-3 text-center"}
              >
                {/*<Button
                      type={"button"}
                      className={"px-4"}`
                      onClick={() => alert("합계 버튼 클릭")}
                  >
                    합계
                  </Button>
                  <Button
                      type={"button"}
                      className={"px-4"}
                      onClick={() => alert("평균 버튼 클릭")}
                  >
                    평균
                  </Button>*/}
                <Button
                  type={"button"}
                  className={"px-4"}
                  // onClick={() => alert("PDF 버튼 클릭")}
                  onClick={handlePrintPDF}
                >
                  PDF
                </Button>
                {/*<ReactToPdf targetRef={pdfRef} filename="page-content.pdf">
                    {({toPdf}) => (
                        <button onClick={toPdf}>Download as PDF</button>
                    )}
                  </ReactToPdf>*/}
              </Col>
            </Row>
          </Form>
        </Col>

        <Col
          xs={12}
          className={"border p-2 rounded-3"}
          style={{ backgroundColor: "#1D222A" }}
          ref={pdfRef}
        >
          <Row>
            <Col xs={12}>
              <span className="fs-4 fw-bold">| 기간 별 메인기능 이용 시간</span>
            </Col>
          </Row>
          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"align-middle my-auto w-100 d-inline-block fs-4"}
              >
                실시간 방송
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={liveStreamingData} />
            </Col>
          </Row>
          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"align-middle my-auto w-100 d-inline-block fs-4"}
              >
                VOD
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={vod} />
            </Col>
          </Row>
          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"fs-4 align-middle my-auto w-100 d-inline-block"}
              >
                IPTV
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={iptv} />
            </Col>
          </Row>

          <Row className={"mt-3"}>
            <Col xs={12}>
              <span className="fs-4 fw-bold">
                | 기간 별 메시지 기능 이용 횟수
              </span>
            </Col>
          </Row>

          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"fs-4 align-middle my-auto w-100 d-inline-block"}
              >
                외부 메시지
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={message} />
            </Col>
          </Row>

          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"fs-4 align-middle my-auto w-100 d-inline-block"}
              >
                경로당간 영상통화
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={video_call} />
            </Col>
          </Row>

          <Row className={"mt-3"}>
            <Col xs={12}>
              <span className="fs-4 fw-bold">| 기간 별 편의기능 이용 횟수</span>
            </Col>
          </Row>

          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"fs-4 align-middle my-auto w-100 d-inline-block"}
              >
                날씨정보
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={weather} />
            </Col>
          </Row>

          <Row className={"justify-content-evenly mt-3"}>
            <Col
              xs={2}
              className={
                "border p-2 rounded-3 text-center align-content-center"
              }
            >
              <span
                className={"fs-4 align-middle my-auto w-100 d-inline-block"}
              >
                버스정보
              </span>
            </Col>
            <Col xs={9}>
              {/* 차트 */}
              <StockChart title="Test Stock Chart" series={busData} />
            </Col>
          </Row>

          <Row className={"justify-content-around mt-3"}>
            <Col xs={5} className={"border p-2 rounded-3"}>
              <Row className={"row-gap-3 mt-3"}>
                <Col xs={12} className={""}>
                  <span className="fs-4 fw-bold">
                    | 실시간방송 이용 시간 합계
                  </span>
                </Col>

                <Col
                  xs={12}
                  className={
                    "text-center d-flex justify-content-center align-items-center"
                  }
                >
                  {/* 차트 */}
                  <DefaultPieChart
                    series={live_streaming_usage_sum_by_district}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={5} className={"border p-2 rounded-3"}>
              <Row className={"row-gap-3 mt-3"}>
                <Col xs={12}>
                  <span className="fs-4 fw-bold">| VOD 이용 시간 합계</span>
                </Col>

                <Col
                  xs={12}
                  className={
                    "text-center d-flex justify-content-center align-items-center"
                  }
                >
                  {/* 차트 */}
                  <DefaultPieChart series={vod_usage_sum_by_district} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"justify-content-around mt-3"}>
            <Col xs={5} className={"border p-2 rounded-3"}>
              <Row className={"row-gap-3 mt-3"}>
                <Col xs={12}>
                  <span className="fs-4 fw-bold">
                    | 실시간방송 이용 시간 순위
                  </span>
                </Col>

                <Col xs={12}>
                  <CustomTable
                    columns={RankingLiveColumns}
                    tableData={RankingLiveTableData}
                    currentPage={RankingLiveCurrentPage}
                    handlePageChange={handlePageRankingLiveChange}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={5} className={"border p-2 rounded-3"}>
              <Row className={"row-gap-3 mt-3"}>
                <Col xs={12}>
                  <span className="fs-4 fw-bold">| VOD 이용 시간 순위</span>
                </Col>

                <Col xs={12}>
                  <CustomTable
                    columns={RankingVODColumns}
                    tableData={RankingVODTableData}
                    currentPage={RankingVODCurrentPage}
                    handlePageChange={handlePageRankingVODChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"mt-3"}>
            <Col xs={12}>
              <span className="fs-4 fw-bold">| IPTV 이용 시간 합계</span>
            </Col>
          </Row>

          <Row className={"justify-content-evenly mt-3"}>
            <Col xs={12}>
              {/* 차트 */}
              <DefaultPieChart series={iptv_usage_sum_by_district} />
            </Col>
          </Row>

          <Row className={"mt-3"}>
            <Col xs={12}>
              <span className="fs-4 fw-bold">| IPTV 이용 시간 순위</span>
            </Col>
          </Row>

          <Row className={"justify-content-evenly mt-3"}>
            <Col xs={12}>
              <CustomTable
                columns={RankingIPTVColumns}
                tableData={RankingIPTVTableData}
                currentPage={RankingIPTVCurrentPage}
                handlePageChange={handlePageRankingIPTVChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Usage;
