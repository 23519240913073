import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import Frame from "../components/common/Frame";
import BackgroundImg from "../assets/landing/bg.png";
import LogoSvg from "../assets/common/logo.svg";
import "./MessageSend.scss";
import ImgIconSvg from "../assets/message/icon-img.svg";
import axios from "axios";
import { getTokenVerify, postMessage } from "../api/apiClient";
import { error } from "highcharts";

const MessageSend = () => {
  const [messageFormData, setMessageFormData] = useState({
    title: "",
    content: "",
    centerId: "",
  });
  const [file, setFile] = useState({});

  const [userInfo, setUserInfo] = useState(null);

  const getUserInfo = () => {
    getTokenVerify().then((res) => {
      console.log("user", res.data);
      setUserInfo(res.data);
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // 파일 업로드
  const fileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const imageType = file.type.includes("image");
    const videoType = file.type.includes("video");

    setFile({
      url: URL.createObjectURL(file),
      image: imageType,
      video: videoType,
    });

    setMessageFormData((prev) => ({
      ...prev,
      media: file,
    }));
  };

  // 입력 필드
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // submit
  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    // console.log("messageFormData :", messageFormData);
    // console.log("file :", file);

    // 입력값 검증
    const { title, content } = messageFormData;
    if (!title || !content) {
      alert("모든 필수 입력란을 채워주세요. (Media 파일 제외)");
      return;
    }
    // FormData 생성
    const formData = new FormData();
    formData.append("title", messageFormData.title);
    formData.append("content", messageFormData.content);

    if (file) {
      formData.append("media", file);
      // formData.append("media", file, file.name);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    console.log("messageFormData", messageFormData);
    /*for (let [key, value] of messageFormData.entries()) {
      console.log(`${key}: ${value}`);
    }*/

    postMessage(messageFormData)
      .then((res) => {
        console.log(res);
        alert("발송 완료");
        //   formdata 초기화
        setMessageFormData({
          title: "",
          content: "",
        });
        setFile({});
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Frame
      title={"스마트경로당 메시지"}
      showTabMenu={false}
      className={"my-auto"}
    >
      <Form
        className="d-flex flex-column justify-content-center gap-3 p-4"
        style={{
          border: "1px solid #868686",
          borderRadius: "12px",
          backgroundColor: "#1D222A",
        }}
        onSubmit={handleMessageSubmit}
      >
        {/* 경로당 / 받는사람 / 보내기 버튼 */}
        <Row className="justify-content-between row-gap-3">
          <Col xs={4} className="d-flex">
            <Col xl={5} sm={12} className="fs-4 fw-bold border-end text-center">
              {/* <Form.Control
              className="w-100"
              name="center"
              value={messageFormData.center}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="경로당 명을 입력하세요"
            /> */}
              {userInfo?.senior_center?.name}
            </Col>
            <Col
              xl={5}
              sm={12}
              className="d-flex justify-content-center text-center"
            >
              {/* <Form.Control
              className="w-100"
              name="receiver"
              value={messageFormData.receiver}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="받는 사람을 입력하세요"
            /> */}
              <p className="fs-4 fw-bold">받는 어르신 : </p>
              <p className="fs-4 fw-bold ms-2"> {userInfo?.senior_name}</p>
            </Col>
          </Col>
          <Col xl={4} sm={12} className="d-flex justify-content-end">
            {/* <Form.Control
              className="w-100"
              name="author"
              value={messageFormData.author}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="보내는 사람을 입력하세요"
            /> */}
            <p className="fs-4 fw-bold">발신자 : </p>
            <p className="fs-4 fw-bold ms-2">{userInfo?.username}</p>
          </Col>
          {/* submit */}
          {/* <Col xs={4} className="text-end pc-btn">
              <Button type="submit">보내기</Button>
            </Col> */}
        </Row>

        {/* 메시지 제목 입력 */}
        <Row>
          <Col>
            <Form.Control
              name="title"
              value={messageFormData.title}
              onChange={handleChange}
              type="text"
              size="lg"
              placeholder="메시지 제목을 입력하세요"
            />
          </Col>
        </Row>

        {/* 파일 업로드 */}
        <Row className="m-0">
          <Col className="btn-upload">
            <label htmlFor="file" className="">
              <img src={ImgIconSvg} alt="img-icon" />
              <span>이미지, 영상 업로드</span>
            </label>
            <input
              type="file"
              name="file"
              id="file"
              className="custom-box"
              onChange={fileUpload}
            />
          </Col>
        </Row>

        {/* 파일 미리보기 및 메시지 텍스트 입력 */}
        <Row>
          <Col
            xs={12}
            xl={6}
            style={{
              backgroundColor: "#fff",
              border: "1px solid #868686",
              borderRadius: "0.5rem",
              padding: "0.7rem 1rem",
            }}
          >
            {file.image ? (
              <img
                src={file.url}
                alt="preview"
                className="d-block w-100"
                style={
                  {
                    // width: "100%",
                    // height: "auto",
                  }
                }
              />
            ) : (
              <span className={"fs-1 text-black"}>Preview</span>
            )}
          </Col>

          {/*{file.video && <video src={file.url} controls width="350px"/>}*/}

          <Col xs={12} xl={6}>
            {/*<input
                    as="textarea"
                    name="content"
                    value={messageFormData.content}
                    onChange={handleChange}
                    className="border-0 fs-5 py-2 img-box w-100"
                    size="lg"
                    rows={10}
                    placeholder="메시지 내용을 입력하세요"
                />*/}
            <textarea
              name="content"
              value={messageFormData.content}
              onChange={handleChange}
              className="border-0 fs-5 py-2 img-box w-100"
              rows={10}
              placeholder="메시지 내용을 입력하세요"
            />
          </Col>
        </Row>

        <Col className="text-center">
          <Button className="w-100" type="submit">
            발송
          </Button>
        </Col>
      </Form>
    </Frame>
  );
};

export default MessageSend;
