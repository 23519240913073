import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import CameraIcon from "../../assets/icons/icon-camera.png";
import MicIcon from "../../assets/icons/icon-mic.png";
import ChatIcon from "../../assets/icons/icon-chat.png";

const VideoSystemManagement = () => {
  const [streamingDetailModalShow, setStreamingDetailModalShow] =
    useState(false);

  const [searchParam, setSearchParam] = useState({
    search: "",
  });

  const renderStreamingDetail = () => {
    return (
      <>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={8} className={"border p-2 rounded-3"}>
                스트리밍 공유 화면
              </Col>
              <Col xs={4} className={"border p-2 rounded-3 text-center"}>
                <Row className={"row-gap-3 justify-content-center"}>
                  {/* 테이블 */}
                  <Col xs={11}>
                    <Row className={"row-gap-3"}>
                      <Col xs={12} className={"border p-2 rounded-3 "}>
                        참석 경로당 수
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col className={"my-auto align-middle"}>
                            <span>경로당명</span>
                          </Col>
                          <Col xs={1} className={"px-0"}>
                            <img
                              src={CameraIcon}
                              alt={"카메라"}
                              className={"w-100 cursor-hover"}
                            />
                          </Col>
                          <Col xs={1} className={"px-0"}>
                            <img
                              src={MicIcon}
                              alt={"마이크"}
                              className={"w-100 cursor-hover"}
                            />
                          </Col>
                          <Col xs={1} className={"px-0"}>
                            <img
                              src={ChatIcon}
                              alt={"채팅"}
                              className={"w-100 cursor-hover"}
                            />
                          </Col>
                          <Col className={"my-auto align-middle"}>
                            <Button
                              type={"button"}
                              size={"sm"}
                              className={"rounded-4"}
                              variant={"outline-secondary"}
                              onClick={() => alert("내보내기 버튼 클릭")}
                            >
                              내보내기
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  {/* 레이아웃 변경*/}
                  <Col xs={11}>
                    <Row className={"row-gap-3"}>
                      <Col xs={12} className={"border p-2 rounded-3 "}>
                        레이아웃 변경
                      </Col>
                      <Col xs={12}>
                        <Form.Select
                          aria-label={"레이아웃 변경"}
                          className={"w-100"}
                        >
                          <option value={""}>레이아웃 선택</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>

                  {/* 온에어 */}
                  <Col xs={11}>
                    <Row className={"row-gap-3"}>
                      <Col xs={12} className={"border p-2 rounded-3 "}>
                        온에어
                      </Col>
                      <Col
                        xs={12}
                        className={"my-auto align-middle text-center"}
                      >
                        <Form.Check
                          type={"switch"}
                          id={"onAirSwitch"}
                          label={""}
                          className={"text-center"}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-3"}>
        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A" }}
        >
          <Form>
            <Row>
              <Col xs={4}>
                <Row>
                  <Col xs={6} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 실시간 스트리밍 방송</span>
                  </Col>
                  <Col xs={6}>
                    <Button
                      type={"button"}
                      className={"w-100 h-100 fs-5 fw-bold"}
                      onClick={() =>
                        alert("실시간 스트리밍 방송 개설 버튼 클릭")
                      }
                    >
                      실시간 스트리밍 방송 개설
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={"mt-3"}>
              <Col xs={12}>
                <Row className={"h-100"}>
                  <Col xs={2} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 h-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      편성시간
                    </span>
                  </Col>
                  <Col xs={8} className={"my-auto align-middle"}>
                    <span
                      className={
                        "d-block w-100 h-100 border p-2 rounded-3 text-center fs-5"
                      }
                    >
                      실시간 스트리밍 방송 제목
                    </span>
                  </Col>
                  <Col xs={2} className={"h-100"}>
                    <Button
                      type={"button"}
                      className={"w-100 h-100 fs-5 fw-bold"}
                      onClick={() => {
                        setStreamingDetailModalShow(true);
                      }}
                    >
                      입장하기
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col
          xs={12}
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A", height: "calc(100vh - 22rem)" }}
        >
          <Row className={"justify-content-between"}>
            <Col xs={6} className={"my-auto align-middle"}>
              <span className="fs-4 fw-bold">| 경로당간 영상통화 리스트</span>
            </Col>
            <Col xs={3} className={"text-end"}>
              {/* 경로당 검색*/}
              <Row className={"justify-content-end"}>
                <Col xs={6}>
                  <Form.Control
                    type={"text"}
                    className={"w-100 h-100 fs-5"}
                    placeholder={"경로당명 검색"}
                    value={searchParam.search}
                    onChange={(e) =>
                      setSearchParam({ ...searchParam, search: e.target.value })
                    }
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    type={"button"}
                    className="w-100 h-100 fs-5 fw-bold"
                    onClick={() => alert("검색 버튼 클릭")}
                  >
                    검색
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              {/*<PaginatedTable
                    // props 체크 필요
                />*/}
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={streamingDetailModalShow}
        onHide={() => setStreamingDetailModalShow(false)}
      >
        {renderStreamingDetail()}
      </CustomModal>
    </>
  );
};

export default VideoSystemManagement;
