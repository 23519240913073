import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaginatedTable from "../table/PaginatedTable";
import CustomModal from "../common/CustomModal";
import On from "../../assets/monitoring/icon-on.svg";
import Off from "../../assets/monitoring/icon-off.svg";
import { formatPhoneNumber } from "../../hooks/usePhoneNumberFormatter";

const SetTopBoxStatus = () => {
  const [showSetTopBoxModal, setShowSetTopBoxModal] = useState(false); // 모달창
  const [isEditable, setIsEditable] = useState(false); // 필드 활성화 상태
  const [searchParam, setSearchParam] = useState({
    local: "",
    center: "",
  });

  const [formData, setFormData] = useState({
    local: "",
    center: "",
    connectionStatus: true, // 연결 여부: true(연결됨), false(연결 안됨)
    manager_phone_number: "",
    serialNumber: "",
  });

  const columns = [
    { key: "id", title: "번호" },
    { key: "location", title: "구" },
    { key: "center", title: "경로당" },
    {
      key: "settop",
      title: "연결상태",
      render: (value) => (
        <img src={value ? On : Off} alt={value ? "on" : "off"} />
      ),
    },
    {
      key: "serialNumber",
      title: "기기 시리얼넘버",
      render: (value) => <span>{value ? value : "미등록"}</span>,
    },
    {
      key: "actions",
      title: "상세보기",
      render: (_, row) => (
        <Button
          className={"rounded-5 px-5 fw-bold fs-5 btn-outline-primary"}
          onClick={() => {
            setShowSetTopBoxModal(true);
            setIsEditable(false);
          }}
          style={{ borderColor: "#fff", color: "#fff" }}
        >
          상세보기
        </Button>
      ),
    },
  ];

  const dummyData = [
    {
      id: 1,
      location: "남동구",
      center: "A 경로당",
      settop: true,
      serialNumber: "STB12345",
    },
    {
      id: 2,
      location: "연수구",
      center: "B 경로당",
      settop: false,
      serialNumber: "STB67890",
    },
    {
      id: 3,
      location: "부평구",
      center: "C 경로당",
      settop: true,
      serialNumber: "STB11122",
    },
    {
      id: 4,
      location: "미추홀구",
      center: "D 경로당",
      settop: false,
      serialNumber: null,
    },
  ];

  const handleContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData({
      ...formData,
      manager_phone_number: formatted,
    });
  };

  // 업데이트 핸들러
  const updateField = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // 폼 유효성 검사
  const validateForm = () => {
    const requiredFields = [
      "local",
      "center",
      "connectionStatus",
      "phoneNumber",
      "serialNumber",
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }

    // phoneNumber 필드 검사
    const { part1, part2, part3 } = formData.phoneNumber;
    if (
      !part1 ||
      !part2 ||
      !part3 ||
      part1.length !== 3 ||
      part2.length !== 4 ||
      part3.length !== 4
    ) {
      return false;
    }

    return true;
  };

  // 저장 버튼
  const handleSend = () => {
    const userConfirmed = window.confirm("저장 하시겠습니까?");
    if (!userConfirmed) {
      return;
    }
    // if (!validateForm()) {
    //   alert("모든 필드를 채워주세요.");
    //   return;
    // }

    // FormData 객체
    const formObject = {
      local: formData.local,
      center: formData.center,
      connectionStatus: formData.connectionStatus,
      phoneNumber: {
        part1: formData.phoneNumber.part1,
        part2: formData.phoneNumber.part2,
        part3: formData.phoneNumber.part3,
      },
      serialNumber: formData.serialNumber,
    };

    console.log("formObject:", formObject);

    // reset
    setFormData({
      local: "",
      center: "",
      connectionStatus: true,
      phoneNumber: {
        part1: "",
        part2: "",
        part3: "",
      },
      serialNumber: "",
    });
  };

  // 삭제 버튼
  const handleDelete = () => {
    const userConfirmed = window.confirm("삭제 하시겠습니까?");

    if (!userConfirmed) {
      return;
    }
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("search");

    setSearchParam({
      local: "",
      center: "",
    });
  };

  // 모달창 내용
  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        구
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Form.Select
                        className="h-100"
                        value={formData.local}
                        onChange={(e) => updateField("local", e.target.value)}
                        disabled={!isEditable}
                      >
                        <option value={"1"}>구</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 명
                      </span>
                    </Col>
                    <Col xs={2}>
                      <Form.Select
                        className="h-100"
                        value={formData.center}
                        onChange={(e) => updateField("center", e.target.value)}
                        disabled={!isEditable}
                      >
                        <option value={""}>경로당</option>
                        <option value={""}>경로당1</option>
                        <option value={""}>경로당2</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        연결상태
                      </span>
                    </Col>
                    <Col xs={10} className="d-flex gap-3">
                      <Form.Check
                        inline
                        label="연결"
                        name="group1"
                        type={"radio"}
                        id={`inline-radio-1`}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                        checked={formData.connectionStatus === true}
                        onChange={() => updateField("connectionStatus", true)}
                        disabled={!isEditable}
                      />
                      <Form.Check
                        inline
                        label="연결 안됨"
                        name="group1"
                        type={"radio"}
                        id={`inline-radio-2`}
                        className="border rounded p-2 m-0 d-flex align-items-center"
                        checked={formData.connectionStatus === false}
                        onChange={() => updateField("connectionStatus", false)}
                        disabled={!isEditable}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        관리자 연락처
                      </span>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Control
                        type={"text"}
                        placeholder="연락처를 입력하세요."
                        className={"w-100 h-100"}
                        value={formData.manager_phone_number || ""}
                        onChange={(e) => {
                          handleContact(e);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        기기 시리얼넘버
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="기기 시리얼넘버"
                        value={formData.serialNumber}
                        onChange={(e) =>
                          updateField("serialNumber", e.target.value)
                        }
                        disabled={!isEditable}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>

          {/* 버튼 */}
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            {isEditable ? (
              <Button
                className={"rounded-5 px-5 fw-bold fs-5 text-white btn-send"}
                onClick={handleSend}
              >
                저장
              </Button>
            ) : (
              <Button
                variant="secondary"
                className={"rounded-5 px-5 fw-bold fs-5"}
                onClick={() => setIsEditable(true)}
              >
                수정
              </Button>
            )}
            <Button
              variant="danger"
              className={"rounded-5 px-5 fw-bold fs-5"}
              onClick={handleDelete}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="row-gap-3 m-0">
        {/* 경로당 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Col xs={12} className="mb-2">
            <span className="fs-4 fw-bold">| 경로당 검색</span>
          </Col>
          <Row className="justify-content-between">
            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={3}>
                  <Form.Select
                    className="h-100 fs-5"
                    value={searchParam.local}
                    onChange={(e) => {
                      setSearchParam({ ...searchParam, local: e.target.value });
                    }}
                  >
                    <option value={""}>구</option>
                    <option value={""}>구1</option>
                    <option value={""}>구2</option>
                  </Form.Select>
                </Col>
                {/* <Col xs={3}>
                         <Form.Select>
                           <option value={""}>경로당</option>
                           <option value={""}>경로당1</option>
                           <option value={""}>경로당2</option>
                         </Form.Select>
                       </Col> */}
                <Col xs={2} className="border rounded text-center p-2 fs-5">
                  경로당 명
                </Col>
                <Col xs={5}>
                  <Form.Control
                    type="text"
                    placeholder="경로당명 입력"
                    className="h-100 fs-5"
                    value={searchParam.center}
                    onChange={(e) => {
                      setSearchParam({
                        ...searchParam,
                        center: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={1} className="">
              <Button
                type="submit"
                className="w-100 h-100 fs-5 fw-bold"
                // onClick={() => alert("검색 버튼")}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 테이블 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <PaginatedTable columns={columns} data={dummyData} pageSize={10} />
        </Col>
      </Row>
      <CustomModal
        show={showSetTopBoxModal}
        onHide={() => setShowSetTopBoxModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </Form>
  );
};

export default SetTopBoxStatus;
