import React from "react";
import {Modal, Button} from "react-bootstrap";
import "./CustomModal.scss";

const CustomModal = ({show, onHide, onConfirm, children, size = "xl"}) => {
  return (
      <Modal size={size} show={show} onHide={onHide} centered>
        <Modal.Header closeButton className={"border-bottom-0"}>
          {/*<Modal.Title>Custom Modal Title</Modal.Title>*/}
        </Modal.Header>
        <Modal.Body className="px-5">{children}</Modal.Body>
        {/* <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>
                    Save Changes
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer> */}
      </Modal>
  );
};

export default CustomModal;
