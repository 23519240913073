import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import BasicColumnChart from "../chart/BasicColumnChart";

const BMI = () => {
  const columnChartData = {
    xAxisCategories: ["a", "b", "c", "d"],
    series: [
      {
        type: "column",
        name: "등급구분",
        data: [
          { y: 70, color: "#2ECF8F" },
          { y: 85, color: "#FB72F4" },
          { y: 90, color: "#FF8C18" },
          { y: 80, color: "#9E54F3" },
        ],
      },
    ],
    yAxisTitle: "횟수범위숫자",
  };

  return (
    <Row className={"row-gap-4"}>
      {/* 경로당 검색 */}
      <Col
        className="p-3 border rounded"
        style={{ backgroundColor: "#1D222A" }}
      >
        <Row className="justify-content-between">
          <Col xs={7}>
            <Row className="row-gap-2">
              <Col xs={2} className="pe-0">
                <span className="fs-4 fw-bold">| 검색 일자</span>
              </Col>
              <Col xs={10} className="d-flex gap-2">
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>년 리스트</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>월 리스트</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>일 리스트</option>
                  </Form.Select>
                </Col>
                <Col
                  className="border rounded text-center p-2"
                  style={{ maxWidth: "2rem" }}
                >
                  ~
                </Col>
                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>년 리스트</option>
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>월 리스트</option>
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select aria-label="Default select example">
                    <option value={""}>일 리스트</option>
                  </Form.Select>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={4}>
            <Row className="row-gap-2">
              <Col xs={2} className="pe-0">
                <Form.Select>
                  <option value={""}>구</option>
                  <option value={""}>구1</option>
                  <option value={""}>구2</option>
                </Form.Select>
              </Col>
              <Col xs={3}>
                <Form.Select>
                  <option value={""}>경로당</option>
                  <option value={""}>경로당1</option>
                  <option value={""}>경로당2</option>
                </Form.Select>
              </Col>
              <Col xs={2} className="border rounded text-center p-2">
                경로당 명
              </Col>
              <Col xs={4}>
                <Form.Control type="text" placeholder="경로당명 입력" />
              </Col>
            </Row>
          </Col>

          <Col xs={1} className="">
            <Button className="w-100" onClick={() => alert("검색 버튼")}>
              검색
            </Button>
          </Col>
        </Row>
      </Col>

      {/* 테이블 */}

      <Col
        xs={12}
        className="p-3 border rounded"
        style={{
          backgroundColor: "#1D222A",
          height: "calc(100vh - 19rem)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row>
          <Col className="w-100 mb-1">
            <span className="fs-4 fw-bold w-100">| 등급별 측정 현황</span>
          </Col>
          <Col xs={12} className="">
            <BasicColumnChart {...columnChartData} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 등급별 측정 남자 수</span>
            </Col>
            <Col xs={12} className="pe-0">
              <BasicColumnChart {...columnChartData} />
            </Col>
          </Col>
          <Col>
            <Col className="w-100 mt-3 mb-1">
              <span className="fs-4 fw-bold w-100">| 등급별 측정 여자 수</span>
            </Col>
            <Col xs={12} className="pe-0">
              <BasicColumnChart {...columnChartData} />
            </Col>
          </Col>
        </Row>

        <Row>
          <Col className="w-100 mt-3 mb-1">
            <span className="fs-4 fw-bold w-100">| 등급별 측정 현황</span>
          </Col>
          <p>페이지네이션</p>
        </Row>
      </Col>
    </Row>
  );
};

export default BMI;
