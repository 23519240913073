import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { getNotice, getNoticeCategory } from "../../api/apiClient";
import PaginatedTable from "../table/PaginatedTable";
import UseSearchFilter from "../../hook/UseSearchFilter";
import CustomTable from "../table/CustomTable";

const NoticeHistory = () => {
  const [showNoticeHistory, setShowNoticeHistory] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  const [searchParam, setSearchParam] = useState({
    category: "",
    title: "",
  });

  const [noticeCategory, setNoticeCategory] = useState([]); // 템플릿 카테고리 api

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    { key: "id", title: "번호" },
    { key: "category.name", title: "카테고리" },
    { key: "title", title: "제목" },
    { key: "recipients", title: "수신처" },
    { key: "author", title: "관리자ID" },
    { key: "created_at", title: "발송일시" },
    { key: "actions", title: "상세보기" },
  ];

  // 공지사항 카테고리
  const getNoticeCategoryList = () => {
    getNoticeCategory()
      .then((res) => setNoticeCategory(res.data))
      .catch((err) => console.error(err));
  };

  // 공지사항 api
  const getNoticeList = (page = 1) => {
    getNotice(page, searchParam.category, searchParam.title)
      .then((res) => {
        console.log("=======getNoticeList", res.data);
        setTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNoticeList(page);
  };

  useEffect(() => {
    getNoticeCategoryList();
    getNoticeList();
  }, []);

  // 상세보기 버튼
  const handleDetail = (notice) => {
    setShowNoticeHistory(true);
    setSelectedNotice(notice);
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    getNoticeList();
    // setSearchTitle(pendingSearchTitle);
    // setPendingSearchTitle(""); // 입력 필드 초기화
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="gap-3">
        <Col
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 13rem)",
          }}
        >
          {/* 검색 */}
          <Row>
            <Col xs={showNoticeHistory ? 4 : 8}>
              <span className="fs-4 fw-bold">| 공지사항 발송 히스토리</span>
            </Col>
            <Col xs={showNoticeHistory ? 8 : 4} className="d-flex gap-2">
              <Col xs={4}>
                <Form.Select
                  className="primary-select fw-bold fs-5"
                  value={searchParam.category}
                  onChange={(e) =>
                    setSearchParam({ ...searchParam, category: e.target.value })
                  }
                >
                  <option defaultChecked value={""}>
                    카테고리
                  </option>
                  {noticeCategory?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={6}>
                <Form.Control
                  className="fs-5"
                  type="text"
                  placeholder="공지사항 제목 입력"
                  value={searchParam.title}
                  onChange={(e) =>
                    setSearchParam({ ...searchParam, title: e.target.value })
                  }
                />
              </Col>
              <Col xs={2} className="pe-3">
                <Button
                  className="w-100 fs-5 fw-bold"
                  type="submit"
                  // onClick={() => getNoticeList()}
                >
                  검색
                </Button>
              </Col>
            </Col>
          </Row>
          <br />
          {/* 리스트 */}
          {tableData ? (
            <CustomTable
              columns={columns}
              tableData={tableData}
              handleDetail={handleDetail}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          ) : (
            <Col className="w-100 text-center my-5">
              <span>검색 결과가 없습니다.</span>
            </Col>
          )}

          {/*{filteredData.length > 0 ? (
                <PaginatedTable
                    columns={columns}
                    data={filteredData}
                    pageSize={10}
                />
            ) : (

            )}*/}
        </Col>

        {/* 공지사항 상세 */}
        {showNoticeHistory && (
          <Col
            className="py-5 px-5 border rounded"
            style={{
              backgroundColor: "#1D222A",
              height: "calc(100vh - 13rem)",
            }}
          >
            {/* 제목 */}
            <Col>
              <Form.Control
                className="fs-5"
                type="text"
                placeholder="제목"
                value={selectedNotice.title || ""}
                readOnly
              />
            </Col>
            <Row className="mt-2">
              {/* 카테고리와 발송일시 */}
              <Col>
                <Form.Control
                  type="text"
                  placeholder="카테고리"
                  value={selectedNotice.category.name || ""}
                  readOnly
                  className="mb-2 fs-5 h-50"
                />
                <Form.Control
                  type="text"
                  placeholder="발송일시"
                  value={
                    new Date(selectedNotice.created_at)
                      .toISOString()
                      .slice(0, 10) || ""
                  }
                  readOnly
                  className="fs-5"
                />
              </Col>

              {/* 수신처 */}
              <Col className="ps-0">
                <Form.Control
                  className="fs-5"
                  as="textarea"
                  rows={3}
                  placeholder="수신처"
                  value={selectedNotice.recipients || ""}
                  readOnly
                />
              </Col>
            </Row>
            <Col className="mt-2">
              <Form.Control
                className="fs-5"
                as="textarea"
                rows={18}
                placeholder="내용"
                value={selectedNotice.content || ""}
                readOnly
              />
            </Col>
            <Col className="mt-3 text-center">
              <Button
                variant="secondary"
                className={"rounded-5 px-5 fw-bold fs-5"}
                onClick={() => setShowNoticeHistory(false)}
              >
                닫기
              </Button>
            </Col>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default NoticeHistory;
