import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";

const BattleData = () => {
  const [showEditModal, setShowEditModal] = useState(false);

  // 수정하기 모달창 내용
  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <Col xs={3}>
                      <span className="fs-4 fw-bold">
                        | 고스트 데이터 수정하기
                      </span>
                    </Col>
                  </Row>

                  {/* 경로당 구분 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        경로당 구분
                      </span>
                    </Col>
                    <Col xs={3}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>구 리스트</option>
                      </Form.Select>
                    </Col>
                    <Col xs={3}>
                      <Form.Select
                        className="h-100"
                        aria-label="Default select example"
                      >
                        <option value={"1"}>경로당 리스트</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  {/* ID */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        ID
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="ID"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                      />
                    </Col>
                  </Row>

                  {/* 코스/구간 선택 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        코스 / 구간 선택
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="코스 리스트"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="구간 리스트"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                      />
                    </Col>
                  </Row>

                  {/* 점수 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        점수
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="점수 입력"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                      />
                    </Col>
                    <Col xs={4} className="my-auto">
                      <span>*시간에 따라 자동 계산됩니다.</span>
                    </Col>
                  </Row>

                  {/* 거리 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        거리
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="거리 입력"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                      />
                    </Col>
                  </Row>

                  {/* 걸음 수 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        걸음 수
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 placeholder-white"
                        }
                        type="text"
                        placeholder="걸음 수 입력"
                        disabled
                        style={{ backgroundColor: "#1D222A", color: "#fff" }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        시간
                      </span>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        className="h-100"
                        type="text"
                        placeholder="시간 입력"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            className={"d-flex justify-content-center gap-3 text-center"}
          >
            <Button className="btn-send" onClick={onConfirm}>
              저장
            </Button>
            <Button variant="danger" onClick={() => {}}>
              삭제
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row className="mb-2">
            <Col xs={2}>
              <span className="fs-4 fw-bold">| 대전 데이터 검색</span>
            </Col>
            <Col xs={2} className="">
              <Form.Select>
                <option value={""}>코스 선택</option>
                <option value={""}>1</option>
                <option value={""}>2</option>
              </Form.Select>
            </Col>
            <Col xs={2} className="">
              <Form.Select>
                <option value={""}>구간 선택</option>
                <option value={""}>1</option>
                <option value={""}>2</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs={8}>
              <Row className="row-gap-2">
                <Col xs={2} className="pe-0">
                  <span className="fs-4 fw-bold">| 경로당 검색</span>
                </Col>
                <Col xs={2}>
                  <Form.Select>
                    <option value={""}>구</option>
                    <option value={""}>구1</option>
                    <option value={""}>구2</option>
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select>
                    <option value={""}>경로당</option>
                    <option value={""}>경로당1</option>
                    <option value={""}>경로당2</option>
                  </Form.Select>
                </Col>
                <Col xs={1} className="border rounded text-center p-2">
                  경로당 명
                </Col>
                <Col xs={2}>
                  <Form.Control type="text" placeholder="경로당명 입력" />
                </Col>
                <Col xs={1} className="border rounded text-center p-2">
                  ID
                </Col>
                <Col xs={2}>
                  <Form.Control type="text" placeholder="ID 입력" />
                </Col>
              </Row>
            </Col>

            <Col xs={1} className="">
              <Button className="w-100" onClick={() => alert("검색 버튼")}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 22rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col xs={2} className="">
              <span className="fs-4 fw-bold">| 기록된 대전 데이터</span>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} className="">
              <span className="fs-4 fw-bold">| 고스트 데이터</span>
            </Col>
            <Col xs={12} className="">
              <p>페이지네이션</p>
              {/* 모달창 버튼 */}
              <Button onClick={() => setShowEditModal(true)}>수정하기</Button>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} className="">
              <span className="fs-4 fw-bold">| 사용자 데이터</span>
            </Col>
            <Col xs={12} className="">
              <p>페이지네이션</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default BattleData;
