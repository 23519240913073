import React from "react";
import ReactPaginate from "react-js-pagination";
import { Col, Row } from "react-bootstrap";

const Pagination = ({
  propCurPage = 1,
  propTotal = 0,
  propSetNewPage = null,
  propSetIsSearch,
}) => {
  const handlePageChange = (page) => {
    if (propSetIsSearch) {
      propSetIsSearch(true);
    }
    propSetNewPage(page);
  };

  return (
    <Row className={"text-center "}>
      <Col md={12} className="d-flex justify-content-center">
        <ReactPaginate
          activePage={propCurPage}
          itemsCountPerPage={10}
          totalItemsCount={propTotal}
          pageRangeDisplayed={5}
          hideFirstLastPages={true}
          /*prevPageText={
                <div className={"previous fs-6 ms-1"}>
                  <FontAwesomeIcon icon={faAngleLeft} className={""}/>
                </div>
              }*/
          /*nextPageText={
                <div className={"next fs-6 me-1"}>
                  <FontAwesomeIcon icon={faAngleRight} className={"ps-1"}/>
                </div>
              }*/
          onChange={handlePageChange}
          activeClass={"active"}
          innerClass={"pagination justify-content-end"}
          itemClass={"page-item ms-2"}
          linkClass={"page-link"}
          itemClassPrev={"prev"}
          itemClassNext={"next"}
          itemClassFirst={"first"}
          itemClassLast={"last"}
        />
      </Col>
    </Row>
  );
};

export default Pagination;
