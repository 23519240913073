import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// import HeaderImg from "../assets/landing/header.png";
// import MonitoringImg from "../assets/landing/monitoring.png";
// import ConferencingManagementImg from "../assets/landing/conferencingManagement.png";
// import HealthcareManagementImg from "../assets/landing/healthcareManagement.png";
// import LivingCareManagementImg from "../assets/landing/livingCareManagement.png";
// import EventImg from "../assets/landing/event.png";
// import NoticeImg from "../assets/landing/notice.png";
// import UserImg from "../assets/landing/user.png";
// import SettingImg from "../assets/landing/setting.png";
import "./landing.scss";
import { useNavigate } from "react-router-dom";
import Frame from "../components/common/Frame";
import { getMainMenu } from "../api/apiClient";

const Landing = () => {
  const STATIC_FILE_URL = process.env.REACT_APP_STATIC_FILE_URL;

  const [initialMenuList, setInitialMenuList] = useState([]);
  const navigate = useNavigate();

  const routePathArr = [
    { id: 1, route: "monitoring" },
    { id: 2, route: "video-conference" },
    { id: 3, route: "healthcare" },
    { id: 4, route: "lifestyle" },
    { id: 5, route: "issue" },
    { id: 6, route: "notice" },
    { id: 7, route: "user" },
    { id: 8, route: "settings" },
  ];

  const getMainMenuList = () => {
    getMainMenu()
      .then((res) => {
        console.log("mainMenu", res.data);
        setInitialMenuList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getMainMenuList();
  }, []);

  // menuData를 order 기준으로 정렬
  const sortedMenuData = [...initialMenuList].sort(
    (a, b) => a.order - b.order || 0
  );

  const sortedPreviews = sortedMenuData.map((menu) =>
    menu.thumbnail instanceof File
      ? URL.createObjectURL(menu.thumbnail)
      : `${STATIC_FILE_URL}${menu.thumbnail}`
  );

  return (
    <Frame
      title={"스마트경로당 통합관리시스템"}
      showTabMenu={false}
      className={"my-auto"}
    >
      {/* <Row className="header">
        <Col md={12}>
          <Row>
            <Col className="p-3 px-5">
              <img src={HeaderImg} alt="AdminLandingLogo" className="w-100" />
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row className="content justify-content-center align-items-center">
        {/* 카드 목록 */}
        <Col xl={11} md={11} className="card-list-container">
          <Row className={"row-gap-5"}>
            {sortedMenuData?.map((menu, idx) => {
              // routePathArr에서 menu.id와 일치하는 route
              const matchedRoute = routePathArr.find(
                (route) => route.id === menu.id
              )?.route;

              return (
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  xxl={3}
                  className={"contents-main-item px-4 px-4"}
                  key={menu.id}
                >
                  <Card
                    className={"card p-0 text-center m-auto"}
                    onClick={() => {
                      if (matchedRoute) {
                        navigate(`/${matchedRoute}`);
                      } else {
                        console.warn(`Route not found : ${menu.id}`);
                      }
                    }}
                  >
                    <Card.Body className={"card-body p-0"}>
                      <div className={"card-wrap"}>
                        <img
                          className={"card-icon item-icon"}
                          src={sortedPreviews[idx]}
                          alt={menu.order}
                        />
                        <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                          {menu.display_name}
                        </h2>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4 px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/monitoring");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={MonitoringImg}
                      alt={"실시간 모니터링"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      실시간 모니터링
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/video-conference");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={ConferencingManagementImg}
                      alt={"양방향 화상회의 관리"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      양방향 화상
                      <br />
                      시스템 운영관리
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/healthcare");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={HealthcareManagementImg}
                      alt={"헬스케어 관리"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      헬스케어 시스템
                      <br />
                      운영 관리
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/lifestyle");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={LivingCareManagementImg}
                      alt={"생활케어 관리"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      생활케어 시스템
                      <br />
                      운영 관리
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/issue");
                  // navigate("/send");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={EventImg}
                      alt={"이슈사항 관리"}
                      // alt={"메시지 보내기"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      이슈사항 관리 */}
            {/* 메시지 보내기 */}
            {/* </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/notice");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={NoticeImg}
                      alt={"공지사항 관리"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      공지사항 관리
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            {/* 
            <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/user");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={UserImg}
                      alt={"사용자 관리"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>
                      사용자 관리
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              xxl={3}
              className={"contents-main-item px-4"}
            >
              <Card
                className={"card p-0 text-center m-auto"}
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <Card.Body className={"card-body p-0"}>
                  <div className={"card-wrap"}>
                    <img
                      className={"card-icon item-icon"}
                      src={SettingImg}
                      alt={"설정 관리"}
                    />
                    <h2 className={"fs-4 card-name mt-5 fw-bold"}>설정 관리</h2>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Frame>
  );
};

export default Landing;
