import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import CustomTable from "../table/CustomTable";
import {
  getAccountsAuthority,
  getAccountsUserStaff,
  getAccountsUserStaffDetail,
  getAuthorities,
  postAccountsUserStaff
} from "../../api/apiClient";
import {formatPhoneNumber} from "../../hooks/usePhoneNumberFormatter";
import usePermissionLevel from "../../hooks/usePermissionLevel";
import getPermissionLevelText from "../../hooks/usePermissionLevel";

const ManageAccount = () => {

  const [searchParam, setSearchParam] = useState({
    authority: '',
    search: '',
  });

  const [accountsAuthorityList, setAccountsAuthorityList] = useState([]); // 템플릿 카테고리 api
  const [accountsAuthorityContent, setAccountsAuthorityContent] = useState([]); // 템플릿 카테고리 api

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {key: "id", title: "순번"},
    {key: "authority.name", title: "직책"},
    {key: "username", title: "관리자 ID"},
    {key: "name", title: "이름"},
    {key: "phone", title: "연락처"},
    {key: "actions", title: "상세보기"},
  ];

  const getAuthorityList = () => {
    getAuthorities()
        .then((res) => {
          console.log(res);
          setAccountsAuthorityList(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getAccountsAuthorityContent = (authorityId) => {
    console.log(authorityId);
    getAccountsAuthority(authorityId)
        .then((res) => {
          console.log(res);
          setAccountsAuthorityContent(res.data);
        })
        .catch((error) => {
          console.error(error);
          setAccountsAuthorityContent({});
        });
  };

  const getStaffList = () => {
    getAccountsUserStaff(
        currentPage,
        searchParam.search,
        searchParam.authority
    )
        .then((res) => {
          console.log(res);
          setTableData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const [detailStaffInfo, setDetailStaffInfo] = useState({});

  const getStaff = (id, authorityId) => {
    if (id) {
      getAccountsUserStaffDetail(id)
          .then((res) => {
            console.log(res);
            setDetailStaffInfo(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
    }

    if (authorityId) {
      getAccountsAuthorityContent(authorityId);
    }
  }


  useEffect(() => {
    getAuthorityList();
    getStaffList();
  }, []);


  const handleSearch = (e) => {
    e.preventDefault();
    console.log(searchParam);
    getStaffList();
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getStaffList();
  };

  const [detailModalShow, setDetailModalShow] = useState(false);
  const [detailRegisterModalShow, setDetailRegisterModalShow] = useState(false);

  const handleContact = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setDetailStaffInfo({...detailStaffInfo, phone: formatted});
  }

  const handlePutStaff = () => {
    console.log(detailStaffInfo);
    postAccountsUserStaff(detailStaffInfo)
        .then((res) => {
          console.log(res);
          // alert("등록되었습니다.");
          // getStaffList();
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const renderDetailEdit = () => {
    return (
        <>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} className={"d-flex flex-column gap-3"}>
                  {/* 사용자명 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>사용자명</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"text"}
                          placeholder={"사용자명을 입력하세요."}
                          className={"h-100"}
                          value={detailStaffInfo.name}
                          onChange={(e) => setDetailStaffInfo({...detailStaffInfo, name: e.target.value})}
                      />
                    </Col>
                  </Row>

                  {/* 연락처 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>사용자 연락처</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"text"}
                          placeholder={"사용자 연락처를 입력하세요."}
                          className={"h-100"}
                          value={detailStaffInfo.phone}
                          onChange={handleContact}
                      />
                    </Col>
                  </Row>

                  {/* 아이디 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>아이디</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"text"}
                          placeholder={"아이디를 입력하세요."}
                          className={"h-100"}
                          value={detailStaffInfo.username}
                          onChange={(e) => setDetailStaffInfo({...detailStaffInfo, username: e.target.value})}
                      />
                    </Col>
                  </Row>

                  {/* 비밀번호 */}
                  {/*<Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>비밀번호</span>
                    </Col>
                    <Col xs={10}>
                      <Form.Control
                          type={"password"}
                          placeholder={"비밀번호를 입력하세요."}
                          value={detailStaffInfo.password}
                          onChange={(e) => setDetailStaffInfo({...detailStaffInfo, password: e.target.value})}
                      />
                    </Col>
                  </Row>*/}

                  {/* 직책 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>직책</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Select
                          aria-label="Default select example"
                          value={detailStaffInfo.authority && detailStaffInfo.authority.id}
                          className={"h-100"}
                          onChange={(e) => setDetailStaffInfo({...detailStaffInfo, authority: e.target.value})}
                      >
                        <option value={""}>직책 리스트</option>
                        {accountsAuthorityList.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  {/* 권한 내용 확인 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>권한 내용 확인</span>
                    </Col>
                    <Col xs={10}>
                      {/*accountsAuthorityContent*/}
                      <Table bordered hover responsive>
                        <thead>
                        <tr>
                          <th>순번</th>
                          <th>메뉴</th>
                          <th>접근 권한</th>
                        </tr>
                        </thead>
                        <tbody>
                        {accountsAuthorityContent.menu_permissions && accountsAuthorityContent.menu_permissions.map((item, index) => (
                            <tr key={index}>
                              <td>{item.main_menu.id}</td>
                              <td>{item.main_menu.display_name}</td>
                              <td>{getPermissionLevelText(item.permission_level)}</td>
                            </tr>
                        ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className={"d-flex justify-content-center gap-3 text-center mt-4"}>
              {/* 버튼 */}
              <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  variant={"secondary"}
              >
                수정
              </Button>
              <Button
                  type={"button"}
                  variant={"success"}
                  className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                  onClick={(e) => {
                    // console.log(detailStaffInfo);
                    e.preventDefault();
                    handlePutStaff();
                  }}
              >
                저장
              </Button>
              <Button
                  type={"button"}
                  variant={"danger"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
              >
                삭제
              </Button>
            </Col>
          </Row>
        </>
    )
  }

  const [registerInfo, setRegisterInfo] = useState({
    name: '',
    phone: '',
    username: '',
    password: '',
    authority: '',
  });

  const renderDetailRegister = () => {
    return (
        <>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} className={"d-flex flex-column gap-3"}>
                  {/* 사용자명 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>사용자명</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"text"}
                          placeholder={"사용자명을 입력하세요."}
                          className={"h-100"}
                          value={registerInfo.name}
                          onChange={(e) => setRegisterInfo({...registerInfo, name: e.target.value})}
                      />
                    </Col>
                  </Row>

                  {/* 연락처 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>사용자 연락처</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"text"}
                          placeholder={"사용자 연락처를 입력하세요."}
                          className={"h-100"}
                          value={registerInfo.phone}
                          onChange={handleContact}
                      />
                    </Col>
                  </Row>

                  {/* 아이디 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>아이디</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"text"}
                          placeholder={"아이디를 입력하세요."}
                          className={"h-100"}
                          value={registerInfo.username}
                          onChange={(e) => setRegisterInfo({...registerInfo, username: e.target.value})}
                      />
                    </Col>
                  </Row>

                  {/* 비밀번호 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>비밀번호</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Control
                          type={"password"}
                          placeholder={"비밀번호를 입력하세요."}
                          className={"h-100"}
                          value={registerInfo.password}
                          onChange={(e) => setRegisterInfo({...registerInfo, password: e.target.value})}
                      />
                    </Col>
                  </Row>

                  {/* 직책 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>직책</span>
                    </Col>
                    <Col xs={5}>
                      <Form.Select
                          aria-label="Default select example"
                          value={registerInfo.authority}
                          className={"h-100"}
                          onChange={(e) => setRegisterInfo({...registerInfo, authority: e.target.value})}
                      >
                        <option value={""}>직책 리스트</option>
                        {accountsAuthorityList.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  {/* 권한 내용 확인 */}
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>권한 내용 확인</span>
                    </Col>
                    <Col xs={10}>
                      <Table bordered hover responsive>
                        <thead>
                        <tr>
                          <th>순번</th>
                          <th>메뉴</th>
                          <th>접근 권한</th>
                        </tr>
                        </thead>
                        <tbody>
                        {accountsAuthorityContent.menu_permissions && accountsAuthorityContent.menu_permissions.map((item, index) => (
                            <tr key={index}>
                              <td>{item.main_menu.id}</td>
                              <td>{item.main_menu.display_name}</td>
                              <td>{getPermissionLevelText(item.permission_level)}</td>
                            </tr>
                        ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className={"d-flex justify-content-center gap-3 text-center mt-4"}>
              {/* 버튼 */}
              <Button
                  type={"button"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  variant={"secondary"}
              >
                수정
              </Button>
              <Button
                  type={"button"}
                  variant={"success"}
                  className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                  onChange={(e) => {
                    console.log(registerInfo);
                  }}
              >
                저장
              </Button>
              <Button
                  type={"button"}
                  variant={"danger"}
                  className={"rounded-5 px-5 fw-bold fs-5"}
              >
                삭제
              </Button>
            </Col>
          </Row>
        </>
    )
  }

  const handleDetail = (info) => {
    console.log("info", info);
    getStaff(info.id, info.authority.id);
    setDetailModalShow(true);
  }


  useEffect(() => {
    getAccountsAuthorityContent(registerInfo.authority);
  }, [registerInfo.authority]);

  useEffect(() => {
    // console.log(detailStaffInfo.authority);
    if (detailStaffInfo.authority) {
      getAccountsAuthorityContent(detailStaffInfo.authority);
    }
  }, [detailStaffInfo.authority]);

  return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12} className={"border p-2 rounded-3"} style={{backgroundColor: "#1D222A"}}>
            <Form>
              <Row className={"row-gap-3"}>
                <Col xs={12}>
                  <span className="fs-4 fw-bold">| 관리자 검색</span>
                </Col>
                <Col xs={8}>
                  <Row>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center"}>직책</span>
                    </Col>
                    <Col>
                      <Form.Select
                          aria-label="Default select example"
                          value={searchParam.authority}
                          onChange={(e) => setSearchParam({...searchParam, authority: e.target.value})}
                      >
                        <option value={""}>직책 리스트</option>
                        {accountsAuthorityList.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={2} className={"my-auto align-middle"}>
                      <span className={"d-block w-100 border p-2 rounded-3 text-center"}>검색어</span>
                    </Col>
                    <Col>
                      <Form.Control
                          type={"text"}
                          placeholder={"검색어를 입력하세요."}
                          value={searchParam.search}
                          onChange={(e) => setSearchParam({...searchParam, search: e.target.value})}
                      />
                    </Col>
                    <Col>
                      <Button
                          variant={"primary"}
                          type={"submit"}
                          className={"px-5"}
                          onClick={(e) => {
                            handleSearch(e);
                          }}
                      >
                        검색
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col className={"text-end"}>
                  <Button
                      variant={"primary"}
                      type={"button"}
                      className={"px-5"}
                      onClick={() => setDetailRegisterModalShow(true)}
                  >
                    생성
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col xs={12} className={"border p-2 rounded-3"} style={{backgroundColor: "#1D222A"}}>
            <Row className={"row-gap-3"}>
              <Col xs={12}>
                <span className="fs-4 fw-bold">| 사용자 리스트</span>
              </Col>
              <Col xs={12}>
                {/*<Button
                    type={"button"}
                    className={""}
                    onClick={() => setDetailModalShow(true)}
                >
                  상세보기
                </Button>*/}
                {/*<PaginatedTable
                    // props 체크 필요
                />*/}
                {tableData ? (
                    <CustomTable
                        columns={columns}
                        tableData={tableData}
                        handleDetail={handleDetail}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                ) : (
                    <Col className="w-100 text-center my-5">
                      <span>검색 결과가 없습니다.</span>
                    </Col>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <CustomModal
            show={detailModalShow}
            onHide={() => {
              setDetailModalShow(false);
              setDetailStaffInfo({});
            }}
        >
          {renderDetailEdit()}
        </CustomModal>
        <CustomModal
            show={detailRegisterModalShow}
            onHide={() => {
              setDetailRegisterModalShow(false);
              setRegisterInfo({
                name: '',
                phone: '',
                username: '',
                password: '',
                authority: '',
              });
            }}
        >
          {renderDetailRegister()}
        </CustomModal>
      </>
  );
};

export default ManageAccount;
