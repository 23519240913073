import React, {useEffect, useState, useRef} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const StockChart = ({series}) => {
  const chartRef = useRef(null);

  const [options, setOptions] = useState({
    chart: {
      type: "line",
      backgroundColor: "transparent",
      height: 535,
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%Y-%m-%d}",
      },
    },
    tooltip: {
      formatter: function () {
        return `${Highcharts.dateFormat('%Y-%m-%d', this.x)}<br/>이용시간: <strong>${this.y}</strong>`;
      },
      valueDecimals: 0,
    },
    series: [{name: "Series 1", data: []}],
    credits: {
      enabled: false, // 로고 제거
    },
    rangeSelector: {
      inputDateFormat: '%Y-%m-%d',
      inputEditDateFormat: '%Y-%m-%d',
    }
  });

  useEffect(() => {
    if (series && series.length > 0) {
      const minDate = series[0][0];
      let maxDate = series[series.length - 1][0];

      // If minDate and maxDate are the same or very close, adjust maxDate to ensure a proper range
      if (minDate >= maxDate) {
        maxDate = minDate + 24 * 60 * 60 * 1000; // Add one day in milliseconds
      }

      setOptions((prevOptions) => ({
        ...prevOptions,
        series: [{name: "Series 1", data: series}],
        xAxis: {
          ...prevOptions.xAxis,
          min: minDate,
          max: maxDate,
        },
      }));

      // Update the chart extremes
      if (chartRef.current) {
        chartRef.current.chart.xAxis[0].setExtremes(minDate, maxDate);
      }
    }
  }, [series]);

  Highcharts.setOptions({
    lang: {
      months: [
        "1월", "2월", "3월", "4월", "5월", "6월",
        "7월", "8월", "9월", "10월", "11월", "12월"
      ],
      weekdays: [
        "일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"
      ],
      shortMonths: [
        "1월", "2월", "3월", "4월", "5월", "6월",
        "7월", "8월", "9월", "10월", "11월", "12월"
      ],
      rangeSelectorZoom: "기간 선택"
    },
  });

  return (
      <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
          ref={chartRef}
      />
  );
};

export default StockChart;