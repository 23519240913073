export const formatPhoneNumber = (input) => {
  const numericValue = input.replace(/[^0-9]/g, ''); // 숫자만 남기기

  // 하이픈 추가 로직
  if (numericValue.length <= 3) {
    return numericValue; // 3자리 이하일 경우 그대로 반환
  } else if (numericValue.length <= 7) {
    return numericValue.slice(0, 3) + '-' + numericValue.slice(3); // 3~7자리
  } else {
    return (
        numericValue.slice(0, 3) +
        '-' +
        numericValue.slice(3, 7) +
        '-' +
        numericValue.slice(7, 11) // 최대 11자리 (010-0000-0000 형태)
    );
  }
}
