import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DefaultPieChart = ({series}) => {
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent', // 배경 투명
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false, // 데이터 레이블 비활성화
        },
        borderColor: null, // 경계선 비활성화
        innerSize: "0%", // 도넛 차트를 원하지 않으면 0%
      },
    },
    series: [
      {
        name: '시간',
        colorByPoint: true,
        data: series, // props로 받은 데이터 사용
      },
    ],
    credits: {
      enabled: false, // 로고 제거
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options}/>;
};

export default DefaultPieChart;
