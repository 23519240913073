import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import CustomModal from "../common/CustomModal";

const IssueManagement = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  // 모달창 내용
  const renderEdit = ({ onConfirm }) => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={12} className="d-flex flex-column gap-3">
                  <Row>
                    <BootstrapTable striped hover responsive>
                      <thead>
                        <tr>
                          {[
                            "발생일시",
                            "카테고리",
                            "위험도",
                            "구",
                            "경로당명",
                            "내용",
                            "담당자",
                            "연락처",
                            "처리상태",
                          ].map((col, idx) => (
                            <th key={idx}>{col}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {[
                            "발생일시",
                            "카테고리",
                            "위험도",
                            "구",
                            "경로당명",
                            "내용",
                            "담당자",
                            "연락처",
                            "처리상태",
                          ].map((col, idx) => (
                            <td key={idx}>{col}</td>
                          ))}
                        </tr>
                      </tbody>
                    </BootstrapTable>
                  </Row>

                  <Row
                    className="p-3 border rounded"
                    style={{ backgroundColor: "#1D222A" }}
                  >
                    <Col>
                      <span className="fs-4 fw-bold">| 메세지 보내기</span>
                    </Col>
                    <Row>
                      <Col xs={10}>
                        <Form.Control type="text" />
                      </Col>
                      <Col xs={2}>
                        <Button>보내기</Button>
                      </Col>
                    </Row>
                  </Row>

                  <Row
                    className="p-3 border rounded justify-content-center"
                    style={{ backgroundColor: "#1D222A" }}
                  >
                    <Col className="mb-3">
                      <span className="fs-4 fw-bold">| 메세지 히스토리</span>
                    </Col>
                    <Row
                      style={{
                        height: "calc(100vh - 50rem)",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <Col
                        xs={12}
                        className="border-top border-bottom text-center"
                      >
                        <span>수/발신 시간</span>
                      </Col>

                      <Col>
                        <Col>
                          {/* 담당자 */}
                          <Row>
                            <Col xs={6} className="mt-2">
                              <span>담당자 ID</span>
                              <Form.Control className="w-75" as="textarea" />
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="mt-2">
                              <span>담당자 ID</span>
                              <Form.Control className="w-75" as="textarea" />
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                        </Col>

                        <Col>
                          {/* 발신자 */}
                          <Row>
                            <Col xs={6}></Col>
                            <Col xs={6} className="text-end mt-2">
                              <span>발신자 ID</span>
                              <Form.Control
                                className="w-75 ms-auto"
                                as="textarea"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}></Col>
                            <Col xs={6} className="text-end mt-2">
                              <span>발신자 ID</span>
                              <Form.Control
                                className="w-75 ms-auto"
                                as="textarea"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row className={"row-gap-4"}>
        {/* 검색 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{ backgroundColor: "#1D222A" }}
        >
          <Row className="justify-content-between mb-2">
            <Col xs={3}>
              <span className="fs-4 fw-bold">| 이슈 사항 필터 검색</span>
            </Col>
            <Col xs={1} className="text-end">
              <Button
                variant="secondary"
                className="w-100"
                onClick={() => alert("초기화 버튼")}
              >
                초기화
              </Button>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={7} className="d-flex gap-2">
              <Col xs={2} className="border rounded text-center p-2">
                발생 일자
              </Col>
              <Col>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>년 리스트</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>월 리스트</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>일 리스트</option>
                </Form.Select>
              </Col>
              <Col
                className="border rounded text-center p-2"
                style={{ maxWidth: "2rem" }}
              >
                ~
              </Col>
              <Col>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>년 리스트</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>월 리스트</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>일 리스트</option>
                </Form.Select>
              </Col>
            </Col>

            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={3} className="border rounded text-center p-2">
                  카테고리
                </Col>
                <Col xs={8}>
                  <Form.Select className="h-100">
                    <option value={""}>카테고리 리스트</option>
                    <option value={""}>1</option>
                    <option value={""}>2</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className=" mb-2">
            <Col xs={7} className="d-flex gap-2">
              <Col xs={2} className="border rounded text-center p-2">
                경로당 구분
              </Col>
              <Col xs={2} className="pe-0">
                <Form.Select className="h-100">
                  <option value={""}>구</option>
                  <option value={""}>구1</option>
                  <option value={""}>구2</option>
                </Form.Select>
              </Col>
              <Col xs={2}>
                <Form.Select className="h-100">
                  <option value={""}>경로당</option>
                  <option value={""}>경로당1</option>
                  <option value={""}>경로당2</option>
                </Form.Select>
              </Col>
              <Col xs={1} className="border rounded text-center p-2">
                경로당명
              </Col>
              <Col xs={3}>
                <Form.Control
                  className="h-100"
                  type="text"
                  placeholder="경로당명 입력"
                />
              </Col>
            </Col>

            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={3} className="border rounded text-center p-2">
                  검색어
                </Col>
                <Col xs={6}>
                  <Form.Control
                    className="h-100"
                    type="text"
                    placeholder="아이디 또는 이름"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="">
            <Col xs={7} className="d-flex gap-2">
              <Col xs={2} className="border rounded text-center p-2">
                위험도
              </Col>
              <Col xs={3}>
                <Form.Select
                  className="h-100"
                  aria-label="Default select example"
                >
                  <option value={""}>위험도 선택</option>
                </Form.Select>
              </Col>
            </Col>

            <Col xs={4}>
              <Row className="row-gap-2">
                <Col xs={3} className="border rounded text-center p-2">
                  처리 상태
                </Col>
                <Col xs={8}>
                  <Form.Select className="h-100">
                    <option value={""}>카테고리 리스트</option>
                    <option value={""}>1</option>
                    <option value={""}>2</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>

            <Col xs={1} className="">
              <Button className="w-100" onClick={() => alert("검색 버튼")}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {/* 내용 */}
        <Col
          xs={12}
          className="p-3 border rounded"
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 28rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col className="w-100 mb-1">
              <span className="fs-4 fw-bold w-100">| 이슈 사항 발생 내역</span>
            </Col>
            <Col xs={12} className="">
              <p>페이지네이션</p>
              {/* 모달창 버튼 */}
              <Button onClick={() => setShowDetailModal(true)}>상세보기</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        onConfirm={() => alert("수정 완료")}
      >
        {renderEdit({ onConfirm: () => alert("수정 완료") })}
      </CustomModal>
    </>
  );
};

export default IssueManagement;
