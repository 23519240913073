import { useState, useEffect } from 'react';

const useDaysInMonth = (year, month) => {
  const [daysInMonth, setDaysInMonth] = useState([]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  useEffect(() => {
    const days = getDaysInMonth(year, month);
    setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
  }, [year, month]);

  return daysInMonth;
};

export default useDaysInMonth;