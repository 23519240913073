import {SWAGGER_BASE_URL} from "../config";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

/*const getToken = () => {
  return window.localStorage.getItem("access");
};

const token = getToken();*/

const axiosClient = axios.create({
  baseURL: SWAGGER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
    // 'Access-Control-Allow-Origin': '*', // CORS 허용
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' // CORS 허용
  },
});

axiosClient.interceptors.request.use(
    (config) => {
      const token = window.localStorage.getItem("access");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

export const axiosClientFormData = axios.create({
  baseURL: `${SWAGGER_BASE_URL}`,
  timeout: 10000,
});

axiosClientFormData.interceptors.request.use(
    (config) => {
      const token = window.localStorage.getItem("access");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// 로그인 (JWT 토큰 발급)
export const postLogin = (data) => {
  try {
    return axiosClient.post("/jwt/token/", data);
  } catch (e) {
    console.error(e);
  }
};

// JWT 토큰 검증
export const getTokenVerify = () => {
  try {
    return axiosClient.get("/jwt/verify/");
  } catch (e) {
    console.error(e);
  }
};

const axiosClientPostFormData = (url, formData) => {
  const token = window.localStorage.getItem("access");
  let ret;
  ret = axiosClientFormData.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

// 공통
// 행정구 리스트 조회
export const getDistricts = () => {
  try {
    return axiosClient.get("/district/");
  } catch (e) {
    console.error(e);
  }
};

// 경로당 단순 리스트 조회
export const getCenters = (district_id) => {
  try {
    return axiosClient.get(`/senior-center/?district_id=${district_id}`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 등록
export const postNotice = (formData) => {
  try {
    return axiosClient.post("/notice/", formData);
  } catch (e) {
    console.error(e);
  }
};

// 메시지 - 회원가입
export const postAccountsUserRequest = (data) => {
  try {
    return axiosClientPostFormData("/accounts/register/external/", data);
  } catch (e) {
    console.error(e);
  }
};

// 메시지 - 아이디 중복확인
export const getCheckDuplicate = (username) => {
  try {
    return axiosClient.get(
        `/accounts/external/users/check-duplicate?username=${username}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 메시지 - 메시지 보내기
export const postMessage = (formData) => {
  try {
    return axiosClientPostFormData("/message/send/", formData);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 리스트 조회
export const getNotice = (page, category, search) => {
  try {
    return axiosClient.get(
        `/notice/?page=${page}&category=${category}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 카테고리
export const getNoticeCategory = () => {
  try {
    return axiosClient.get(`/notice/category/`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 리스트 조회
export const getTemplate = () => {
  try {
    return axiosClient.get(`/notice/template/`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 리스트 조회
export const getNoticeTemplate = (page, search, category) => {
  try {
    return axiosClient.get(
        `/notice/template/?page=${page}&search=${search}&category=${category}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 등록
export const postNoticeTemplate = (formData) => {
  try {
    return axiosClient.post("/notice/template/", formData);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 상세 조회
export const getNoticeDetailTemplate = (id) => {
  try {
    return axiosClient.get(`/notice/template/${id}`);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 수정
export const putNoticeTemplate = (id, formData) => {
  try {
    return axiosClient.put(`/notice/template/${id}/`, formData);
  } catch (e) {
    console.error(e);
  }
};

// 공지사항 템플릿 삭제
export const deleteNoticeDetailTemplate = (id) => {
  try {
    return axiosClient.delete(`/notice/template/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상시스템 운영관리

// 편성 카테고리 조회 (편성표 카테고리 목록 조회)
export const getTvsScheduleCategory = () => {
  try {
    return axiosClient.get("/tvs/schedule-categories/");
  } catch (e) {
    console.error(e);
  }
};

// 영상 카테고리 조회 (VOD 카테고리 조회)
export const getTvsVodCategory = () => {
  try {
    return axiosClient.get(`/tvs/vod-categories/`);
  } catch (e) {
    console.error(e);
  }
};

// 실시간 모니터링
// 종합 대시보드
export const getStatisticsDashboard = () => {
  try {
    return axiosClient.get(`/statistics/dashboard/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 현황
export const getStatisticsCenters = (page) => {
  try {
    return axiosClient.get(`/statistics/centers/?page=${page}`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당별 현황 - 상세보기
export const getStatisticsCenterDetail = (center_id) => {
  try {
    return axiosClient.get(`/statistics/center/${center_id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 시스템별 이용현황
export const getStatisticsTvsCenter = (district, center_name) => {
  try {
    return axiosClient.get(
        `/statistics/tvs/center/?district=${district}&center_name=${center_name}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 이슈 사항 발생 현황
export const getStatisticsIssue = (district, center_name) => {
  try {
    return axiosClient.get(
        `/statistics/issue/center/?district=${district}&center_name=${center_name}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 조회 (월)
export const getTvsSchedulesCalendar = (year, month, category, resolution) => {
  try {
    return axiosClient.get(
        `/tvs/schedules/calendar/?year=${year}&month=${month}&category=${category}&resolution=${resolution}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 조회 (일)
export const getTvsSchedulesDay = (date) => {
  try {
    return axiosClient.get(`/tvs/schedules/?date=${date}`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 조회 (상세)
export const getTvsScheduleDetail = (id) => {
  try {
    return axiosClient.get(`/tvs/schedules/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 방송 카테고리 목록 조회
export const getTvsBroadcastCategory = () => {
  try {
    return axiosClient.get(`/tvs/schedule-categories/`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 등록
export const postTvsSchedule = (data) => {
  try {
    // return axiosClient.post("/tvs/schedules/", data);
    return axiosClientFormData.post("/tvs/schedules/", data);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 관리 - 스케줄 수정
export const putTvsSchedule = (id, data) => {

// formdata 로그
  for (let [key, value] of data.entries()) {
    console.log(`${key}: ${value}`);
  }

  try {
    return axiosClientFormData.put(`/tvs/schedules/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};
// 편성표 관리 - 스케줄 삭제
export const deleteTvsSchedule = (id) => {
  try {
    return axiosClient.delete(`/tvs/schedules/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 리스트 조회
export const getTvsVod = (category, search) => {
  try {
    return axiosClient.get(`/tvs/vod/?category=${category}&search=${search}`);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 등록
export const postTvsVod = (data) => {
  try {
    return axiosClientFormData.post("/tvs/vod/", data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 수정
export const putTvsVod = (id, data) => {
  try {
    return axiosClientFormData.put(`/tvs/vod/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 삭제
export const deleteTvsVod = (id) => {
  try {
    return axiosClient.delete(`/tvs/vod/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// CMS - VOD 영상 상세 조회
export const getTvsVodDetail = (id) => {
  try {
    return axiosClient.get(`/tvs/vod/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 편의 기능 관리
// 날씨 정보 안내 관리

// 버스 정보 안내 관리 - 경로당 목록 조회
export const getCenterList = (page, district_id, search) => {
  try {
    return axiosClient.get(
        `/accounts/senior-center/?page=${page}&district_id=${district_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 버스 정보 안내 관리 - 경로당 상세 조회
export const getCenterDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/senior-center/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 버스 정류장 검색
export const getBusStop = (search) => {
  try {
    return axiosClient.get(`/tvs/bus-stations/?search=${search}`);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 승인 요청 리스트
export const getAccountsUserRequest = (
    page,
    district_id,
    center_id,
    search
) => {
  try {
    return axiosClient.get(
        `/accounts/external/users/requests/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 수락 리스트
export const getAccountsUserAccept = (page, district_id, center_id, search) => {
  try {
    return axiosClient.get(
        `/accounts/external/users/approved/?page=${page}&district_id=${district_id}&center_id=${center_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 유저 상세 정보 조회
export const getAccountsUserDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/external/users/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 승인
export const putAccountsUserAccept = (id, data) => {
  try {
    return axiosClient.put(`/accounts/external/users/${id}/status/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 거절
export const deleteAccountsUserReject = (id) => {
  try {
    return axiosClient.delete(`/accounts/external/users/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 회원가입 승인 취소
export const putAccountsUserCancel = (id, data) => {
  try {
    return axiosClient.put(`/accounts/external/users/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 이용량
// 양방향 화상 시스템 - 이용량 - 양방향 화상 시스템 사용 통계
export const getStatisticsTvs = (
    district,
    center_name,
    start_date,
    end_date
) => {
  try {
    return axiosClient.get(
        `/statistics/tvs/?district=${district}&center_name=${center_name}&start_date=${start_date}&end_date=${end_date}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상 시스템 - 이용량 - 실시간 방송 이용 랭킹
export const getStatisticsTvsRankingLive = (
    page,
    start_date,
    end_date,
    order_by
) => {
  try {
    return axiosClient.get(
        `/statistics/tvs/ranking/live/?page=${page}&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상 시스템 - 이용량 - VOD 이용시간 랭킹
export const getStatisticsTvsRankingVod = (
    page,
    start_date,
    end_date,
    order_by
) => {
  try {
    return axiosClient.get(
        `/statistics/tvs/ranking/vod/?page=${page}&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 화상 시스템 - 이용량 - IPTV 이용시간 랭킹
export const getStatisticsTvsRankingIptv = (
    page,
    start_date,
    end_date,
    order_by
) => {
  try {
    return axiosClient.get(
        `/statistics/tvs/ranking/iptv/?page=${page}&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 사용자 관리 (관리자 관리)
// 관리자 로그 관리 - 직책 리스트 조회
export const getAuthorities = () => {
  try {
    return axiosClient.get(`/authorities/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 로그 관리 - 카테고리 리스트 조회
export const getAccountsLogCategory = () => {
  try {
    return axiosClient.get(`/accounts/log/category/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 로그 관리 - 리스트 조회
export const getAccountsLog = (
    page,
    search,
    start_date,
    end_date,
    category_id,
    authority_id
) => {
  try {
    return axiosClient.get(
        `/accounts/log/?page=${page}&search=${search}&start_date=${start_date}&end_date=${end_date}&category_id=${category_id}&authority_id=${authority_id}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 리스트 조회
export const getAccountsUserStaff = (page, search, authority) => {
  try {
    return axiosClient.get(
        `/accounts/user/staff/?page=${page}&search=${search}&authority=${authority}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 상세 조회
export const getAccountsUserStaffDetail = (id) => {
  try {
    return axiosClient.get(`/accounts/user/staff/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 등록
export const postAccountsUserStaff = (data) => {
  try {
    return axiosClient.post("/accounts/user/staff/", data);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 계정 수정
export const putAccountsUserStaff = (id, data) => {
  try {
    return axiosClient.put(`/accounts/user/staff/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 관리자 계정 관리 - 직책 상세 조회
export const getAccountsAuthority = (authorityId) => {
  try {
    return axiosClient.get(`/accounts/authority/${authorityId}/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 리스트 조회
export const getAccountsCenter = (page, district_id, search) => {
  try {
    return axiosClient.get(
        `/accounts/senior-center/?page=${page}&district_id=${district_id}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 생성
export const postAccountsCenter = (data) => {
  try {
    return axiosClient.post(`/accounts/senior-center/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 수정
export const putAccountsCenter = (id, data) => {
  try {
    return axiosClient.put(`/accounts/senior-center/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 계정 관리 - 경로당 삭제
export const deleteAccountsCenter = (id, delete_type) => {
  try {
    return axiosClient.delete(
        `/accounts/senior-center/${id}/?delete_type=${delete_type}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 메인 메뉴 리스트 조회
export const getMainMenu = () => {
  try {
    return axiosClient.get(`/accounts/menu/main/`);
  } catch (e) {
    console.error(e);
  }
};

// 메인 메뉴 수정
export const putMainMenu = (data) => {
  try {
    return axiosClient.put(`/accounts/menu/main/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 행정구 리스트 조회
export const getDistrict = () => {
  try {
    return axiosClient.get(`/district/`);
  } catch (e) {
    console.error(e);
  }
};

// 경로당 단순 리스트 조회
export const getSeniorCenter = (district_id) => {
  try {
    return axiosClient.get(`/senior-center/?district_id=${district_id}`);
  } catch (e) {
    console.error(e);
  }
};

// 양방향 - 셋톱박스 조회
// 실시간 모니터링 - 셋톱박스 동작 현황
export const getSetTopBox = (page, district, center, search) => {
  try {
    return axiosClient.get(
        `/tvs/mini-pcs/?page=${page}&district=${district}&center=${center}&search=${search}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 양방향 - 셋톱박스 상세 조회
// 실시간 모니터링 - 셋톱박스 동작 현황 - 상세보기
export const getSetTopBoxId = (id) => {
  try {
    return axiosClient.get(`/tvs/mini-pcs/${id}`);
  } catch (e) {
    console.error(e);
  }
};

// 셋톱박스 수정
export const putSetTopBox = (id, data) => {
  try {
    return axiosClient.put(`/tvs/mini-pcs/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 셋톱박스 삭제
export const deleteSetTopBox = (id) => {
  try {
    return axiosClient.delete(`/tvs/mini-pcs/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 직책 리스트 조회
export const getAuthority = (page) => {
  try {
    return axiosClient.get(`/accounts/authority/?page=${page}`);
  } catch (e) {
    console.error(e);
  }
};

// 직책 상세 조회
export const getDetailAuthority = (id) => {
  try {
    return axiosClient.get(`/accounts/authority/${id}/`);
  } catch (e) {
    console.error(e);
  }
};

// 직책 추가
export const postAuthority = (data) => {
  try {
    return axiosClient.post(`/accounts/authority/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 직책 수정
export const putAuthority = (id, data) => {
  try {
    return axiosClient.put(`/accounts/authority/${id}/`, data);
  } catch (e) {
    console.error(e);
  }
};

// 직책 삭제
export const deleteAuthority = (id) => {
  try {
    return axiosClient.delete(`/accounts/authority/${id}/`);
  } catch (e) {
    console.error(e);
  }
};
