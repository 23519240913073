import React, {useEffect, useState} from "react";
import Frame from "../components/common/Frame";
import ManageLogs from "../components/user/ManageLogs";
import ManageAccount from "../components/user/ManageAccount";
import ManageCenter from "../components/user/ManageCenter";
import {useNavigate} from "react-router-dom";
import {getTokenVerify, postTokenVerify} from "../api/apiClient";

const User = () => {

  /* 토큰 검증 */
  const navigate = useNavigate();

  useEffect(() => {
    getTokenVerify()
        .then((res) => {
          console.log("Token verify response : ", res);
        })
        .catch((error) => {
          console.error("Token verify error : ", error);
          navigate("/login");
        });
  }, []);

  const subMenu = [
    {title: "관리자 로그 관리", content: <ManageLogs/>},
    {title: "관리자 계정 관리", content: <ManageAccount/>},
    {title: "경로당 계정 관리", content: <ManageCenter/>},
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
      <Frame
          title={"사용자 관리"}
          showTabMenu={true}
          subMenu={subMenu}
          selectedTab={selectedContent}
          onTabSelect={setSelectedContent}
      >
        {renderContent()}
      </Frame>
  );
};

export default User;
