import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import {
  deleteAccountsUserReject,
  getAccountsUserAccept,
  getAccountsUserDetail,
  getAccountsUserRequest,
  getCenters,
  getDistricts,
  putAccountsUserAccept,
  putAccountsUserCancel,
} from "../../api/apiClient";
import CustomTable from "../table/CustomTable";
import { STATIC_FILE_URL } from "../../config";
import PdfViewer from "../../hooks/usePDF";
import { saveAs } from "file-saver";

const MessageMemberManagement = () => {
  /* 회원가입 승인 요청 */
  const [requestSearchParam, setRequestSearchParam] = useState({
    local: "",
    center: "",
    search: "",
  });

  const [districtsList, setDistrictsList] = useState([]);
  const [seniorCenterList, setSeniorCenterList] = useState([]);

  const getDistrictsList = () => {
    getDistricts()
      .then((res) => {
        setDistrictsList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSeniorCenterList = () => {
    getCenters(requestSearchParam.local)
      .then((res) => {
        setSeniorCenterList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSeniorCenterList();
  }, [requestSearchParam.local]);

  const [requestTableData, setRequestTableData] = useState([]);
  const [requestCurrentPage, setRequestCurrentPage] = useState(1);
  const requestColumns = [
    { key: "id", title: "번호" },
    { key: "created_at", title: "요청일시" },
    { key: "senior_center", title: "신청 경로당명" },
    { key: "senior_name", title: "어르신 성함" },
    { key: "actions", title: "상세보기" },
  ];

  const getRequestList = () => {
    getAccountsUserRequest(
      requestCurrentPage,
      requestSearchParam.local,
      requestSearchParam.center,
      requestSearchParam.search
    )
      .then((res) => {
        setRequestTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDistrictsList();
    getSeniorCenterList();
    getRequestList();
    getAcceptList();
  }, []);

  const handleRequestSearch = (e) => {
    e.preventDefault();
    getRequestList();
  };

  const handleRequestPageChange = (page) => {
    setRequestCurrentPage(page);
    getRequestList();
  };

  /* 회원가입 수락 리스트 */
  const [acceptSearchParam, setAcceptSearchParam] = useState({
    local: "",
    center: "",
    search: "",
  });

  const [acceptTableData, setAcceptTableData] = useState([]);
  const [acceptCurrentPage, setAcceptCurrentPage] = useState(1);
  const acceptColumns = [
    { key: "id", title: "번호" },
    { key: "approved_at", title: "수락일시" },
    { key: "senior_center", title: "신청 경로당명" },
    { key: "senior_name", title: "어르신 성함" },
    { key: "actions", title: "상세보기" },
  ];

  const getAcceptList = () => {
    getAccountsUserAccept(
      acceptCurrentPage,
      acceptSearchParam.local,
      acceptSearchParam.center,
      acceptSearchParam.search
    )
      .then((res) => {
        setAcceptTableData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAcceptSearch = (e) => {
    e.preventDefault();
    getAcceptList();
  };

  const handleAcceptPageChange = (page) => {
    setAcceptCurrentPage(page);
    getAcceptList();
  };

  /* 상세조회 */
  const [detailModalShow, setDetailModalShow] = useState(false);

  const [messageUserInfo, setMessageUserInfo] = useState({
    name: "",
    contact: "",
    email: "",
    center: "",
    elderName: "",
    elderContact: "",
    relation: "",
    file: "",
  });

  /* 회원가입 승인 */
  const acceptRegister = (id) => {
    putAccountsUserAccept(id)
      .then((res) => {
        console.log(res);
        alert("승인되었습니다.");
        getRequestList();
        getAcceptList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* 회원가입 거절 */
  const rejectRegister = (id) => {
    deleteAccountsUserReject(id)
      .then((res) => {
        console.log(res);
        alert("거절되었습니다.");
        getRequestList();
        getAcceptList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* 회원가입 승인 취소 */
  const cancelRegister = (id) => {
    const formData = new FormData();
    formData.append("is_approved", false);

    putAccountsUserCancel(id, formData)
      .then((res) => {
        console.log(res);
        alert("승인이 취소되었습니다.");
        getRequestList();
        getAcceptList();
        setDetailModalShow(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderDetail = () => {
    return (
      <>
        <Row className={"row-gap-3"}>
          <Col xs={6}>
            <Form>
              <Row className={"row-gap-3"}>
                <Col xs={12}>
                  <span
                    className={
                      "d-block w-100 border p-2 rounded-3 text-center fs-5"
                    }
                  >
                    신청자 정보 확인
                  </span>
                </Col>
                <Col xs={12} className={"d-flex flex-column gap-3"}>
                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        신청자명
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {messageUserInfo.name || "\u00A0 "}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        신청자 연락처
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {messageUserInfo.contact || "\u00A0 "}
                      </span>
                    </Col>
                  </Row>

                  {/*<Row>
                      <Col xs={4} className={"my-auto align-middle"}>
                        <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>신청자 이메일</span>
                      </Col>
                      <Col xs={8}>
                        <span className={"d-block w-100 border p-2 rounded-3 text-center fs-5"}>
                          {messageUserInfo.email}
                        </span>
                      </Col>
                    </Row>*/}

                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        신청자 경로당명
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {messageUserInfo.center || "\u00A0 "}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        어르신 성함
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {messageUserInfo.elderName || "\u00A0 "}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        어르신 연락처
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {messageUserInfo.elderContact || "\u00A0 "}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        관계
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        {messageUserInfo.relation || "\u00A0 "}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} className={"my-auto align-middle"}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5"
                        }
                      >
                        첨부파일
                      </span>
                    </Col>
                    <Col xs={5}>
                      <span
                        className={
                          "d-block w-100 border p-2 rounded-3 text-center fs-5 text-overflow"
                        }
                      >
                        {messageUserInfo.file || "\u00A0"}
                      </span>
                    </Col>
                    {messageUserInfo.file && (
                      <Col xs={3}>
                        <Button
                          type={"button"}
                          className={"w-100 h-100"}
                          onClick={() => {
                            const decodedFilename = decodeURIComponent(
                              messageUserInfo.file.split("/").pop()
                            );
                            saveAs(
                              STATIC_FILE_URL + messageUserInfo.file,
                              decodedFilename
                            );
                          }}
                        >
                          다운로드
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col xs={6}>
            {/*가족관계증명서 미리보기*/}
            {messageUserInfo.file && (
              <>
                {["jpg", "jpeg", "png", "gif"].includes(
                  messageUserInfo.file.split(".").pop().toLowerCase()
                ) ? (
                  <img
                    src={STATIC_FILE_URL + messageUserInfo.file}
                    alt={"가족관계증명서 미리보기"}
                    className={"img-fluid"}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PdfViewer file={STATIC_FILE_URL + messageUserInfo.file} />
                )}
              </>
            )}
          </Col>

          {/* 버튼 */}
          {messageUserInfo.approved_at ? (
            <>
              <Col
                xs={12}
                className={
                  "d-flex justify-content-center gap-3 text-center mt-4"
                }
              >
                <Button
                  type={"button"}
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                  onClick={() => {
                    const result = window.confirm("저장 하시겠습니까?");
                    if (result) {
                      // 확인 버튼 클릭 시 실행할 코드
                      cancelRegister(messageUserInfo.id);
                    }
                  }}
                >
                  승인 취소
                </Button>
                <Button
                  type={"button"}
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                  onClick={() => {
                    // modal 닫기
                    setDetailModalShow(false);
                  }}
                >
                  닫기
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col
                xs={12}
                className={
                  "d-flex justify-content-center gap-3 text-center mt-4"
                }
              >
                <Button
                  type={"button"}
                  variant="secondary"
                  className={"rounded-5 px-5 fw-bold fs-5 text-white"}
                  onClick={() => {
                    const result = window.confirm("저장 하시겠습니까?");
                    if (result) {
                      // 확인 버튼 클릭 시 실행할 코드
                      alert("보류를 클릭했습니다!");
                    }
                  }}
                >
                  보류
                </Button>
                <Button
                  type={"button"}
                  variant="success"
                  className={"rounded-5 px-5 fw-bold fs-5 btn-send"}
                  onClick={() => {
                    const result = window.confirm("저장 하시겠습니까?");
                    if (result) {
                      // 확인 버튼 클릭 시 실행할 코드
                      // alert('확인을 클릭했습니다!');
                      acceptRegister(messageUserInfo.id);
                    }
                  }}
                >
                  승인
                </Button>
                <Button
                  type={"button"}
                  variant="danger"
                  className={"rounded-5 px-5 fw-bold fs-5"}
                  onClick={() => {
                    const result = window.confirm("저장 하시겠습니까?");
                    if (result) {
                      // 확인 버튼 클릭 시 실행할 코드
                      // alert('확인을 클릭했습니다!');
                      rejectRegister(messageUserInfo.id);
                    }
                  }}
                >
                  거절
                </Button>
              </Col>
            </>
          )}
        </Row>
      </>
    );
  };

  const handleDetail = (data) => {
    getAccountsUserDetail(data.id)
      .then((res) => {
        console.log(res);
        setMessageUserInfo({
          name: res.data.name,
          contact: res.data.phone,
          // email: res.data.email,
          center: res.data.senior_center,
          elderName: res.data.senior_name,
          elderContact: res.data.senior_phone,
          relation: res.data.senior_relationship,
          file: res.data.attachment,
          id: res.data.id,
          created_at: res.data.created_at,
          approved_at: res.data.approved_at,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    setDetailModalShow(true);
  };

  return (
    <>
      <Row className={"gap-3 justify-content-center"}>
        <Col
          className={"border p-3 rounded-3"}
          style={{
            backgroundColor: "#1D222A",
            height: "calc(100vh - 12rem)",
          }}
        >
          <Form>
            <Row className={"row-gap-5"}>
              <Col xs={12} className={"d-flex flex-column gap-3"}>
                <Row className={"row-gap-3"}>
                  <Col xs={6} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">
                      | 회원가입 승인 요청 리스트
                    </span>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={3} className={"my-auto align-middle"}>
                        <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                        >
                          경로당 검색 필터
                        </span>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          className="h-100 fs-5"
                          aria-label="Default select example"
                          value={requestSearchParam.local}
                          onChange={(e) => {
                            setRequestSearchParam({
                              ...requestSearchParam,
                              local: e.target.value,
                            });
                          }}
                        >
                          <option value={""}>구</option>
                          {districtsList.map((district, index) => (
                            <option key={index} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          className="h-100 fs-5"
                          aria-label={"Default select example"}
                          value={requestSearchParam.center}
                          onChange={(e) => {
                            setRequestSearchParam({
                              ...requestSearchParam,
                              center: e.target.value,
                            });
                          }}
                        >
                          <option value={""}>경로당</option>
                          {seniorCenterList.map((center, index) => (
                            <option key={index} value={center.id}>
                              {center.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={3}>
                        <Form.Control
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="h-100 fs-5"
                          value={requestSearchParam.search}
                          onChange={(e) => {
                            setRequestSearchParam({
                              ...requestSearchParam,
                              search: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={2}>
                        <Button
                          type={"button"}
                          className={"w-100 h-100 fw-bold fs-5"}
                          onClick={(e) => {
                            handleRequestSearch(e);
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                {/*<Button
                      type={"button"}
                      variant={"outline-secondary"}
                      onClick={() => {
                        setDetailModalShow(true);
                      }}
                  >
                    상세보기
                  </Button>
                  <PaginatedTable
                    // props 체크 필요
                />*/}
                {requestTableData ? (
                  <CustomTable
                    columns={requestColumns}
                    tableData={requestTableData}
                    handleDetail={handleDetail}
                    currentPage={requestCurrentPage}
                    handlePageChange={handleRequestPageChange}
                  />
                ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
                )}
              </Col>
            </Row>
          </Form>
        </Col>

        <Col
          className={"border p-3 rounded-3"}
          style={{ backgroundColor: "#1D222A", height: "calc(100vh - 12rem)" }}
        >
          <Form>
            <Row className={"row-gap-5"}>
              <Col xs={12} className={"d-flex flex-column gap-3"}>
                <Row className={"row-gap-3"}>
                  <Col xs={6} className={"my-auto align-middle"}>
                    <span className="fs-4 fw-bold">| 회원가입 수락 리스트</span>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={3} className={"my-auto align-middle"}>
                        <span
                          className={
                            "d-block w-100 border p-2 rounded-3 text-center fs-5"
                          }
                        >
                          경로당 검색 필터
                        </span>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          className={"h-100 fs-5"}
                          aria-label="Default select example"
                          value={acceptSearchParam.local}
                          onChange={(e) => {
                            setAcceptSearchParam({
                              ...acceptSearchParam,
                              local: e.target.value,
                            });
                          }}
                        >
                          <option value={""}>구</option>
                          {districtsList.map((district, index) => (
                            <option key={index} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          className={"h-100 fs-5"}
                          aria-label={"Default select example"}
                          value={acceptSearchParam.center}
                          onChange={(e) => {
                            setAcceptSearchParam({
                              ...acceptSearchParam,
                              center: e.target.value,
                            });
                          }}
                        >
                          <option value={""}>경로당</option>
                          {seniorCenterList.map((center, index) => (
                            <option key={index} value={center.id}>
                              {center.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col xs={3}>
                        <Form.Control
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className={"h-100 fs-5"}
                          value={acceptSearchParam.search}
                          onChange={(e) => {
                            setAcceptSearchParam({
                              ...acceptSearchParam,
                              search: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={2}>
                        <Button
                          type={"button"}
                          className={"w-100 h-100 fw-bold fs-5"}
                          onClick={(e) => {
                            handleAcceptSearch(e);
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                {acceptTableData ? (
                  <CustomTable
                    columns={acceptColumns}
                    tableData={acceptTableData}
                    handleDetail={handleDetail}
                    currentPage={acceptCurrentPage}
                    handlePageChange={handleAcceptPageChange}
                  />
                ) : (
                  <Col className="w-100 text-center my-5">
                    <span>검색 결과가 없습니다.</span>
                  </Col>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <CustomModal
        show={detailModalShow}
        onHide={() => setDetailModalShow(false)}
      >
        {renderDetail()}
      </CustomModal>
    </>
  );
};

export default MessageMemberManagement;
