import React, { useEffect, useState } from "react";
import Frame from "../components/common/Frame";
import TemplateSetting from "../components/notice/TemplateSetting";
import NoticeHistory from "../components/notice/NoticeHistory";
import RegisterNotice from "../components/notice/RegisterNotice";

const TemplateForm = () => {
  const subMenu = [
    {
      title: "공지사항 발송",
      content: <RegisterNotice />,
    },
    {
      title: "공지사항 템플릿 설정",
      content: <TemplateSetting />,
    },
    {
      title: "공지사항 발송 히스토리",
      content: <NoticeHistory />,
    },
  ];

  const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

  const renderContent = () => {
    const selectedMenu = subMenu.find((menu) => menu.title === selectedContent);
    return selectedMenu ? selectedMenu.content : <div>콘텐츠가 없습니다.</div>;
  };

  return (
    <Frame
      title={"공지사항 관리"}
      showTabMenu={true}
      subMenu={subMenu}
      selectedTab={selectedContent}
      onTabSelect={setSelectedContent}
    >
      {renderContent()}
    </Frame>
  );
};

export default TemplateForm;
