export const transferData = (data) => {
  return Object.entries(data).map(([date, value]) => {
    return [new Date(date).getTime(), value];
  });
}

export const transformData = (data) => {
  const colors = [
    "#1f4e5f", "#225f7a", "#237699", "#2988b5", "#2e9cd4",
    "#5aa8da", "#74b8e1", "#96c8e9", "#b9d8ef", "#d7e7f4"
  ];

  return Object.entries(data).map(([name, value], index) => ({
    name,
    y: value,
    color: colors[index % colors.length],
  }));
};