const getPermissionLevelText = (level) => {
  switch (level) {
    case 1:
      return "읽기";
    case 2:
      return "읽기/등록";
    case 3:
      return "읽기/등록/수정/삭제";
    default:
      return "권한 없음";
  }
};

export default getPermissionLevelText;