import React, {useEffect, useState} from "react";
import Frame from "../components/common/Frame";
import {Button, Col, Form, Row} from "react-bootstrap";
import {getTokenVerify, postLogin} from "../api/apiClient";
import {useNavigate} from "react-router-dom";
import LogoSVG from "../assets/common/logo.svg";

const Login = () => {

  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useState({
    "username": "",
    "password": ""
  });

  useEffect(() => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("name");
    localStorage.removeItem("type");
  }, []);


  const handleLogin = () => {
    postLogin(loginInfo)
        .then(async (res) => {
          console.log("login res : ", res.data);
          if (res.data.access) {
            window.localStorage.setItem('access', res.data.access)
            window.localStorage.setItem('refresh', res.data.refresh);

            checkUserType();
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            alert(error.response.data.detail);
          }
          console.error(error);
        });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    navigate('/message/register');
  }


  const checkUserType = () => {
    getTokenVerify()
        .then((res) => {
          console.log("Token verify response : ", res);
          if (res.data.type === "external") {
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('type', res.data.type);
            navigate('/send');
          } else {
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('type', res.data.type);
            navigate('/landing');
          }
        })
        .catch((error) => {
          console.error("Token verify error : ", error);
          // navigate("/login");
          alert("토큰 검증에 실패했습니다.");
        });
  }

  return (
      <Frame title={"로그인"} showTabMenu={false} className={"my-auto"}>
        <Row
            className="w-50 border rounded px-3 py-5 justify-content-center row-gap-5"
            style={{backgroundColor: "#1D222A"}}
        >
          <Col xs={7} className={"py-5"}>
            <Row className={"gap-3"}>
              <Col xs={12} className={"text-center"}>
                <img src={LogoSVG} alt="logo" className="w-50"/>
              </Col>
              <Col xs={12}>
                <h3 className="text-center" style={{color: "#3FB5EC"}}>스마트 경로당 로그인</h3>
              </Col>
            </Row>

            <Form onSubmit={handleLogin} className={"mt-3"}>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="3" className={"border rounded-3 text-center fw-bold"}>
                  아이디
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                      type="text"
                      placeholder="email@example.com"
                      value={loginInfo.username}
                      onChange={(e) => setLoginInfo({...loginInfo, username: e.target.value})}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
              >
                <Form.Label column sm="3" className={"border rounded-3 text-center fw-bold"}>
                  비밀번호
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                      type="password"
                      placeholder="Password"
                      value={loginInfo.password}
                      onChange={(e) => setLoginInfo({...loginInfo, password: e.target.value})}
                  />
                </Col>
              </Form.Group>
            </Form>

            <Row>
              <Col xs={6} className="mt-4">
                <Button
                    type="submit"
                    className="btn btn-primary btn-lg w-100 fw-bold"
                    variant={"outline-primary"}
                    onClick={handleLogin}
                >
                  로그인
                </Button>
              </Col>
              <Col xs={6} className="mt-4">
                <Button
                    type="button"
                    className="btn btn-lg w-100 rounded-5 fw-bold"
                    variant={"outline-secondary"}
                    onClick={handleRegister}
                    style={{
                      backgroundColor: "none",

                    }}
                >
                  회원가입
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Frame>
  );
};

export default Login;
