import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const DateSearch = ({ title, searchParam, setSearchParam, daysInMonth }) => {
  return (
    <Col xs={8}>
      <Row>
        <Col xs={2} className={"my-auto align-middle"}>
          <span className="fs-4 fw-bold">| {title || "기간 검색 필터"}</span>
        </Col>
        <Col xs={10} className="d-flex gap-2">
          <Col>
            <Form.Select
              className="h-100 fs-5"
              aria-label="Default select example"
              value={searchParam.year}
              onChange={(e) =>
                setSearchParam({
                  ...searchParam,
                  year: e.target.value,
                })
              }
            >
              <option value={""}>년 리스트</option>
              <option value={2024}>2024년</option>
              <option value={2025}>2025년</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Select
              className="h-100 fs-5"
              aria-label="Default select example"
              value={searchParam.month}
              onChange={(e) =>
                setSearchParam({
                  ...searchParam,
                  month: e.target.value,
                })
              }
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}월
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Form.Select
              className="h-100 fs-5"
              aria-label="Default select example"
              value={searchParam.day}
              onChange={(e) =>
                setSearchParam({
                  ...searchParam,
                  day: e.target.value,
                })
              }
            >
              <option value={""}>일 리스트</option>
              {daysInMonth.map((day) => (
                <option key={day} value={day}>
                  {day}일
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col
            className="border rounded text-center p-2 my-auto align-middle"
            style={{ maxWidth: "2rem" }}
          >
            ~
          </Col>
          <Col>
            <Form.Select
              className="h-100 fs-5"
              aria-label="Default select example"
              value={searchParam.year2}
              onChange={(e) =>
                setSearchParam({
                  ...searchParam,
                  year2: e.target.value,
                })
              }
            >
              <option value={""}>년 리스트</option>
              <option value={2024}>2024년</option>
              <option value={2025}>2025년</option>
            </Form.Select>
          </Col>

          <Col>
            <Form.Select
              className="h-100 fs-5"
              aria-label="Default select example"
              value={searchParam.month2}
              onChange={(e) =>
                setSearchParam({
                  ...searchParam,
                  month2: e.target.value,
                })
              }
            >
              <option value={""}>월 리스트</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}월
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col>
            <Form.Select
              className="h-100 fs-5"
              aria-label="Default select example"
              value={searchParam.day2}
              onChange={(e) =>
                setSearchParam({
                  ...searchParam,
                  day2: e.target.value,
                })
              }
            >
              <option value={""}>일 리스트</option>
              {daysInMonth.map((day) => (
                <option key={day} value={day}>
                  {day}일
                </option>
              ))}
            </Form.Select>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default DateSearch;
