import React, { useState } from "react";
// import PaginatedTable from "../components/table/PaginatedTable";
import Frame from "../components/common/Frame";
import Dashboard from "../components/monitoring/Dashboard";
import SeniorCenterUsageStatus from "../components/monitoring/SeniorCenterUsageStatus";
import SystemUsageStatus from "../components/monitoring/SystemUsageStatus";
import IssueOccurrenceStatus from "../components/monitoring/IssueOccurrenceStatus";
import SetTopBoxStatus from "../components/monitoring/SetTopBoxStatus";
import HealthCareStatus from "../components/monitoring/HealthCareStatus";
import LifeCareStatus from "../components/monitoring/LifeCareStatus";
import HelpDeskStatus from "../components/monitoring/HelpDeskStatus";

const Monitoring = () => {
    const subMenu = [
        {
            title: "종합 대시보드",
            content: <Dashboard />,
        },
        {
            title: "경로당별 이용현황",
            content: <SeniorCenterUsageStatus />,
        },
        {
            title: "시스템별 이용현황",
            content: <SystemUsageStatus />,
        },
        {
            title: "이슈 사항 발생 현황",
            content: <IssueOccurrenceStatus />,
        },
        {
            title: "셋톱박스 동작 현황",
            content: <SetTopBoxStatus />,
        },
        {
            title: "헬스케어 동작 현황",
            content: <HealthCareStatus />,
        },
        {
            title: "생활케어 동작 현황",
            content: <LifeCareStatus />,
        },
        {
            title: "헬프데스크 처리 현황",
            content: <HelpDeskStatus />,
        },
    ];

    const [selectedContent, setSelectedContent] = useState(subMenu[0].title);

    const renderContent = () => {
        const selectedMenu = subMenu.find(
            (menu) => menu.title === selectedContent
        );
        return selectedMenu ? (
            selectedMenu.content
        ) : (
            <div>콘텐츠가 없습니다.</div>
        );
    };

    return (
        <Frame
            title={"실시간 모니터링"}
            showTabMenu={true}
            subMenu={subMenu}
            selectedTab={selectedContent}
            onTabSelect={setSelectedContent}
        >
            {renderContent()}
        </Frame>
    );
};

export default Monitoring;
